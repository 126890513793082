import { useEffect } from 'react';
import { useUiStore } from 'reducers/uiStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { MODALS } from 'views/Modals';

const useHandleSessionReconnection = () => {
  const { openModal, closeModal } = useUiStore((state) => ({
    openModal: state.openModal,
    closeModal: state.closeModal
  }));
  const { patientConnected, enabled } = useLiveConfiguratorStore((state) => ({
    enabled: state.enabled,
    patientConnected: state.patientConnected
  }));

  useEffect(() => {
    if (!enabled) {
      closeModal(MODALS.sessionReconnection);
      return;
    }

    if (patientConnected === false) {
      openModal(MODALS.sessionReconnection);
    } else {
      closeModal(MODALS.sessionReconnection);
    }
  }, [patientConnected, enabled]);
};

export default useHandleSessionReconnection;
