import { BLUETOOTH_EVENTS } from 'bluetooth/Bluetooth/BluetoothEvents';
import { Commands } from 'bluetooth/Bluetooth/Defines';

const BluetoothEventsHandler = {
  eventsListening: false,
  eventListenerController: new AbortController(),
  initiateBluetoothEventsListening() {
    this.eventListenerController = new AbortController();
    if (!this.eventsListening) {
      window.addEventListener(`received${Commands.kEvent}`, this.handleBluetoothEvent.bind(this), {
        signal: this.eventListenerController.signal
      });
      this.eventsListening = true;
    }
  },
  abortBluetoothEventsListening() {
    this.eventListenerController.abort();
    this.eventsListening = false;
  },
  handleBluetoothEvent(event) {
    const bluetoothEventType: BLUETOOTH_EVENTS = event.detail[0].payload[0];

    switch (bluetoothEventType) {
      case BLUETOOTH_EVENTS.kExternalEventTypeEnteredEmergencyMode:
        console.log('DO EMERGENNCY MODE STUFF');
        break;
      default:
        break;
    }
  }
};

export default BluetoothEventsHandler;
