export enum ViewModes {
  standard,
  standardEMG,
  minimal,
  minimalEMG
}

// View modes not requiring minimal view
export const isViewModeStandard = (viewMode: ViewModes) =>
  viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG;
