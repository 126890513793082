import styled from 'styled-components';
import { HeaderM, HeaderXL } from 'components/atoms/Typography/Typography';
import { DisableFunctionality } from 'utils/disableFunctionalityModifiier';

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

export const Header2 = styled.h3`
  ${HeaderM};
  color: ${({ theme }) => theme.typography.colors.secondary};
`;

export const SettingsContainer = styled.div`
  display: grid;
  max-width: 800px;

  > div:last-child {
    padding-bottom: 10px;
    margin-bottom: 50px;
  }

  > * {
    padding: 20px 0 40px 0;
    border-top: 2px solid ${({ theme }) => theme.colorFill};
  }
`;

export const ConfigurationSection = styled.div`
  ${({ disabled }) => (disabled ? DisableFunctionality : null)}
`;
