import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { useConfigStore } from 'reducers/configStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { MODALS } from 'views/Modals';
import { useReplayStore } from 'reducers/replayStore';
import { getModesConfigForDevice } from 'api/modes/modes';
import { CHOOSE_GRIPS } from 'consts/routes';
import useBluetooth from 'hooks/bluetooth/useConnect';
import useUserData from 'hooks/useUserData';
import useRemoteSession from 'hooks/useRemoteSession';
import { useUiStore } from 'reducers/uiStore';
import ConfirmationLoader from '../../layouts/ConfirmationLoader/ConfirmationLoader';

export interface SessionStartEvent {
  clinicianId: number;
  amputeeId: number;
  sessionToken: string;
  sessionId: number;
}

export interface TypedCustomEvent<T> extends CustomEvent {
  detail: T;
}

const DeviceInfo = () => {
  const query = useQuery();
  const bluetoothId = query.get('bluetoothId');
  const serial = query.get('serial');
  const deviceId = query.get('deviceId');
  const amputeeId = query.get('amputeeId');
  const sessionToken = query.get('token');
  const sessionId = query.get('sessionId');
  const modeId = query.get('modeId');
  const isRepair = query.get('repair');
  const connect = query.get('connect');
  const { push } = useHistory();
  const openModal = useUiStore((state) => state.openModal);
  const setDeviceInfo = useDeviceInfoStore((state) => state.setDeviceInfo);
  const resetToDefault = useReplayStore((state) => state.resetToDefault);
  const setItemConfigStore = useConfigStore((state) => state.setItemConfigStore);
  const { getInitialConfigAPI } = useConfigStore((state) => ({
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const { bluetoothConnect } = useBluetooth();
  const { data: userData } = useUserData();
  const { enableLiveSession } = useRemoteSession();
  const [deviceDataReady, setDeviceDataReady] = useState(false);
  const [sessionReady, setSessionReady] = useState(false);
  const [repairReady, setRepairReady] = useState(false);

  const isStartSession = sessionToken;
  const sessionDataReady = sessionId && amputeeId && deviceId && userData && sessionToken;

  useEffect(() => {
    const initiateDevice = async ({
      deviceId,
      amputeeId,
      bluetoothId,
      serial
    }: {
      deviceId: number;
      amputeeId?: number;
      bluetoothId?: string | null;
      serial?: string | null;
    }) => {
      const modesData = await getModesConfigForDevice({ deviceId });
      setDeviceInfo({
        serial: serial || null,
        bluetoothId: bluetoothId || null,
        deviceId: Number(deviceId),
        amputeeId: Number(amputeeId) || null
      });
      const slotSet = modeId
        ? Number(modeId)
        : modesData.find((mode) => mode.active === 1)?.slot || 0;
      setItemConfigStore('slotSelected', slotSet);
      setItemConfigStore('slotSelected', modesData.find((mode) => mode.active === 1)?.slot || 0);
      resetToDefault();
    };

    if (deviceId) {
      initiateDevice({
        deviceId: Number(deviceId),
        amputeeId: Number(amputeeId),
        bluetoothId,
        serial
      });
    }

    setDeviceDataReady(true);
  }, [bluetoothId, serial, deviceId, amputeeId, modeId]);

  useEffect(() => {
    const handlePrepareSession = async (sessionToken, sessionId, amputeeId, clinicianId) => {
      await enableLiveSession(sessionToken, sessionId, amputeeId, clinicianId);
      await getInitialConfigAPI();
      resetToDefault();
      setSessionReady(true);
    };

    if (sessionDataReady) {
      handlePrepareSession(sessionToken, sessionId, amputeeId, userData.id);
    }
  }, [sessionDataReady]);

  useEffect(() => {
    if (isRepair) {
      openModal(MODALS.repair);
      setRepairReady(true);
    }
  }, [isRepair]);

  useEffect(() => {
    if (deviceDataReady) {
      if (isStartSession && !sessionReady) return;
      if (isRepair && !repairReady) return;

      push(CHOOSE_GRIPS);
      if (connect) bluetoothConnect();
    }
  }, [connect, deviceDataReady, sessionReady, isStartSession, isRepair, repairReady]);

  return <ConfirmationLoader />;
};

export default DeviceInfo;
