/* eslint-disable prefer-const */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Card from 'components/atoms/Card/Card';
import VerticalGraphSlider from 'components/molecules/VerticalGraphSlider/VerticalGraphSlider';
import HorizontalGraphSlider from 'components/molecules/HorizontalGraphSlider/HorizontalGraphSlider';
import PositionsAdjuster from 'components/molecules/PositionsAdjuster/PositionsAdjuster';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { emgColors } from 'theme/emgColors/emgColors';
import { emgThresholdsEntry, DeviceConfigTemplate } from 'consts/deviceConfig/deviceConfig.types';
import useTelemetry from 'hooks/bluetooth/useTelemetry';
import { useGraph } from 'hooks/useGraph';
import { useReplayStore } from 'reducers/replayStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useConfigStore } from 'reducers/configStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { HISTORY_EVENTS } from 'consts/consts';
import { useMeetingStore } from 'reducers/meetingStore';
import { getCurrentConfigSelector } from 'reducers/helpers/selectors';
import { checkEmgValidity } from 'reducers/helpers/modifierFunctions';
import { ChartCombined, ChartProportional, ChartSeparate } from './Charts';
import {
  ActivationSeparate1Wrapper,
  ActivationSeparate2Wrapper,
  ActivationWrapper,
  AdjusterWrapper,
  ButtonsnWrapper,
  ContractionSeparate1Wrapper,
  ContractionSeparate2Wrapper,
  ContractionWrapper,
  ControlsWrapper,
  GainsSlidersWrapper,
  graphBreakpoints,
  ReplayWrapper,
  Spacer,
  Wrapper
} from './styled';
import { useReplay } from '../../hooks/useReplay';
import { InputDevices, SpeedControlStrategies } from '../../bluetooth/Bluetooth/Control';
import useCanAccess from '../../hooks/useCanAccess';
import { parseTelemetryData, updateAllGraphs } from './utils';

const GraphComponent = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const graphContainer = useRef(null);
  const graphContainerSeparate1 = useRef(null);
  const graphContainerSeparate2 = useRef(null);
  const graphContainerProportional = useRef(null);
  const { setConfigProperty, addConfigHistory } = useConfigStore((state) => ({
    setConfigProperty: state.setConfigProperty,
    addConfigHistory: state.addConfigHistory
  }));
  const config = useConfigStore(getCurrentConfigSelector);
  const { emgThresholds, emgGains, speedControlStrategy, inputDevice } = config;
  const { replayData, enabled: replayIsEnabled } = useReplayStore((state) => ({
    replayData: state.replayData,
    enabled: state.enabled
  }));
  const viewMode = useMeetingStore((state) => state.viewMode);
  const { canAccess: canAccessOpenCloseCs } = useCanAccess({
    action: 'openCloseCs',
    resource: 'emg'
  });
  const { canAccess: canAccessOpenCloseActivation } = useCanAccess({
    action: 'openCloseActivation',
    resource: 'emg'
  });
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const liveConfiguratorEnabled = useLiveConfiguratorStore((state) => state.enabled);
  const [emgGainExtension, setEmgGainExtension] = useState(0);
  const [emgGainFlexion, setEmgGainFlexion] = useState(0);
  const [graphMode, setGraphMode] = useState('Combined');
  const [showThreshold, setShowThreshold] = useState([true, true, true, true]);
  const [slidersStateSingle, setSlidersStateSingle] = useState([
    {
      name: 'Time',
      value: 500
    },
    {
      name: 'Interval',
      value: 400
    }
  ]);

  const [slidersStateDual, setSlidersStateDual] = useState([
    {
      name: 'Activation',
      valueFirst: 0,
      valueSecond: 0
    },
    {
      name: 'Contraction',
      valueFirst: 0,
      valueSecond: 0
    }
  ]);
  const [valuesProportional, setValuesProportional] = useState({
    opening: [0, 0, 0, 0, 0],
    closing: [0, 0, 0, 0, 0]
  });
  const { updateData: updateDataCombined } = useGraph(
    graphContainer,
    {
      time: slidersStateSingle[0].value,
      interval: slidersStateSingle[1].value,
      thresholds: [
        {
          values: slidersStateDual[0].valueFirst,
          label: 'CS Open',
          color: emgColors.csOpen
        },
        {
          values: slidersStateDual[0].valueSecond,
          label: 'CS Close',
          color: emgColors.csClose
        },
        {
          values: slidersStateDual[1].valueFirst,
          label: 'SCS Open',
          color: emgColors.activationOpen
        },
        {
          values: slidersStateDual[1].valueSecond,
          label: 'SCS Close',
          color: emgColors.activationClose
        }
      ],
      showThreshold
    },
    slidersStateSingle[0].value,
    [
      slidersStateDual[0].valueFirst,
      slidersStateDual[0].valueSecond,
      slidersStateDual[1].valueFirst,
      slidersStateDual[1].valueSecond,
      showThreshold,
      graphMode
    ],
    [graphMode, slidersStateSingle[0].value, viewMode]
  );
  const { updateData: updateDataSeparate1 } = useGraph(
    graphContainerSeparate1,
    {
      time: slidersStateSingle[0].value,
      interval: slidersStateSingle[1].value,
      thresholds: [
        {
          values: slidersStateDual[0].valueFirst,
          label: 'CS Open',
          color: emgColors.csOpen
        },
        {
          values: slidersStateDual[1].valueFirst,
          label: 'SCS Open',
          color: emgColors.activationOpen
        }
      ],
      showThreshold: [showThreshold[0], showThreshold[2]]
    },
    slidersStateSingle[0].value,
    [
      slidersStateDual[0].valueFirst,
      slidersStateDual[0].valueSecond,
      slidersStateDual[1].valueFirst,
      slidersStateDual[1].valueSecond,
      showThreshold,
      graphMode
    ],
    [graphMode, slidersStateSingle[0].value, viewMode]
  );
  const { updateData: updateDataSeparate2 } = useGraph(
    graphContainerSeparate2,
    {
      time: slidersStateSingle[0].value,
      interval: slidersStateSingle[1].value,
      thresholds: [
        {
          values: slidersStateDual[0].valueSecond,
          label: 'CS Close',
          color: emgColors.csClose
        },
        {
          values: slidersStateDual[1].valueSecond,
          label: 'SCS Close',
          color: emgColors.activationClose
        }
      ],
      showThreshold: [showThreshold[1], showThreshold[3]]
    },
    slidersStateSingle[0].value,
    [
      slidersStateDual[0].valueFirst,
      slidersStateDual[0].valueSecond,
      slidersStateDual[1].valueFirst,
      slidersStateDual[1].valueSecond,
      showThreshold,
      graphMode
    ],
    [graphMode, slidersStateSingle[0].value, viewMode]
  );
  const { updateData: updateDataProportional } = useGraph(
    graphContainerProportional,
    {
      time: slidersStateSingle[0].value,
      interval: slidersStateSingle[1].value,
      thresholds: [
        {
          values: valuesProportional.closing[0],
          label: 'Power Closing',
          color: emgColors.powerClose
        },
        {
          values: valuesProportional.opening[1],
          label: 'Speed 3 Opening',
          color: emgColors.speed3Open
        },
        {
          values: valuesProportional.closing[1],
          label: 'Speed 3 Closing',
          color: emgColors.speed3Close
        },
        {
          values: valuesProportional.opening[2],
          label: 'Speed 2 Opening',
          color: emgColors.speed2Open
        },
        {
          values: valuesProportional.closing[2],
          label: 'Speed 2 Closing',
          color: emgColors.speed2Close
        },
        {
          values: valuesProportional.opening[3],
          label: 'Speed 1 Opening',
          color: emgColors.activationOpen
        },
        {
          values: valuesProportional.closing[3],
          label: 'Speed 1 Closing',
          color: emgColors.activationClose
        },
        {
          values: valuesProportional.closing[4],
          label: 'SCS Closing',
          color: emgColors.csClose
        },
        {
          values: valuesProportional.opening[4],
          label: 'SCS Opening',
          color: emgColors.csOpen
        }
      ]
    },
    slidersStateSingle[0].value,
    [
      valuesProportional.closing[0],
      valuesProportional.closing[1],
      valuesProportional.closing[2],
      valuesProportional.closing[3],
      valuesProportional.closing[4],
      valuesProportional.opening[0],
      valuesProportional.opening[1],
      valuesProportional.opening[2],
      valuesProportional.opening[3],
      valuesProportional.opening[4],
      showThreshold,
      graphMode
    ],
    [graphMode, slidersStateSingle[0].value, viewMode]
  );

  useEffect(() => {
    let graphInterval;
    if (deviceConnected) {
      graphInterval = setInterval(updateGraphs, 50);
    }

    if (liveConfiguratorEnabled) {
      graphInterval = setInterval(updateGraphs, 50);
    }

    if (replayIsEnabled) {
      graphInterval = setInterval(() => updateGraphs(false), 50);
    }
    return function clear() {
      if (graphInterval) {
        clearInterval(graphInterval);
      }
    };
  }, [deviceConnected, liveConfiguratorEnabled, replayIsEnabled]);

  const updateGraphs = (fixedUpdate = true) => {
    const emgData = parseTelemetryData(fixedUpdate);
    if (!emgData) return;
    const { emgExtensionOptions, emgFlexionOptions } = emgData;
    updateAllGraphs([
      {
        container: graphContainer,
        updateFunc: () => updateDataCombined([emgExtensionOptions, emgFlexionOptions])
      },
      {
        container: graphContainerSeparate2,
        updateFunc: () => updateDataSeparate1([emgExtensionOptions])
      },
      {
        container: graphContainerSeparate2,
        updateFunc: () => updateDataSeparate2([emgFlexionOptions])
      },
      {
        container: graphContainerProportional,
        updateFunc: () => updateDataProportional([emgExtensionOptions, emgFlexionOptions])
      }
    ]);
  };

  useEffect(() => {
    if (emgThresholds) {
      setSlidersStateDual([
        {
          name: 'Activation',
          valueFirst: emgThresholds[0],
          valueSecond: emgThresholds[1]
        },
        {
          name: 'Contraction',
          valueFirst: emgThresholds[2],
          valueSecond: emgThresholds[5]
        }
      ]);
      const [
        csOpening,
        csClosing,
        speed1Opening,
        speed2Opening,
        speed3Opening,
        Speed1Closing,
        Speed2Closing,
        Speed3Closing,
        powerOpening,
        powerClosing
      ] = emgThresholds;
      setValuesProportional({
        opening: [powerOpening, speed3Opening, speed2Opening, speed1Opening, csOpening],
        closing: [powerClosing, Speed3Closing, Speed2Closing, Speed1Closing, csClosing]
      });
    }
  }, [emgThresholds]);

  useEffect(() => {
    if (emgGains) {
      setEmgGainExtension(emgGains[0]);
      setEmgGainFlexion(emgGains[1]);
    }
  }, [emgGains]);

  const handleTime = (value) => {
    const newArray = slidersStateSingle.map((slider) => {
      if (slider.name === 'Time') {
        slider.value = value;
      }
      return slider;
    });

    setSlidersStateSingle(newArray);
  };

  const updateHistory = (prevState) => {
    addConfigHistory(HISTORY_EVENTS.emgSettings, prevState);
  };

  const handleSliderDualChange = (value, index, { name, sliderIndex }) => {
    const newArray = slidersStateDual.map((slider) => {
      if (slider.name === name) {
        if (sliderIndex === 0) {
          slider.valueFirst = value;
        }
        if (sliderIndex === 1) {
          slider.valueSecond = value;
        }
      }
      return slider;
    });

    setSlidersStateDual(newArray);
  };

  const handleSliderDualOnAfterChange = () => {
    const CSOpen = slidersStateDual[0].valueFirst;
    const CSClose = slidersStateDual[0].valueSecond;
    const SCSOpen = slidersStateDual[1].valueFirst;
    const SCSClose = slidersStateDual[1].valueSecond;
    const {
      opening: [powerOpening, speed3Opening, speed2Opening],
      closing: [powerClosing, speed3Closing, speed2Closing]
    } = valuesProportional;
    const newEMG = [
      CSOpen,
      CSClose,
      SCSOpen,
      speed2Opening,
      speed3Opening,
      SCSClose,
      speed2Closing,
      speed3Closing,
      powerOpening,
      powerClosing
    ] as emgThresholdsEntry;
    const newValidatedEMG = checkEmgValidity(config as DeviceConfigTemplate, newEMG);
    if (!newValidatedEMG) return;
    updateHistory(setConfigProperty('emgThresholds', newValidatedEMG));
  };

  const setEmgGainsStore = () => {
    updateHistory(setConfigProperty('emgGains', [emgGainExtension, emgGainFlexion]));
  };

  const handleSliderChangeProportional = (
    value,
    index,
    { index: fingerIndex, sliderType, min, max }
  ) => {
    const sanitizeInput = () => {
      let sanitizedInputValue = value;
      if (typeof sanitizedInputValue === 'string') {
        sanitizedInputValue = parseInt(value, 10);
        if (Number.isNaN(sanitizedInputValue)) sanitizedInputValue = min;
      }
      if (value > max) {
        sanitizedInputValue = max;
      }
      if (value < min) {
        sanitizedInputValue = min;
      }
      return sanitizedInputValue;
    };

    const valueSanitized = sanitizeInput();
    let newOpening = valuesProportional.opening;
    let newClosing = valuesProportional.closing;
    if (sliderType === 'primary') {
      newOpening = newOpening.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    } else {
      newClosing = newClosing.map((v, i) => {
        if (i === fingerIndex) v = valueSanitized;
        return v;
      });
    }
    const newGripValues = {
      opening: newOpening,
      closing: newClosing
    };
    setValuesProportional(newGripValues);
  };

  const handleSliderOnAfterChangeProportional = () => {
    let {
      opening: [powerOpening, speed3Opening, speed2Opening, speed1Opening, csOpening],
      closing: [powerClosing, speed3Closing, speed2Closing, speed1Closing, csClosing]
    } = valuesProportional;
    const newEMG = [
      csOpening,
      csClosing,
      speed1Opening,
      speed2Opening,
      speed3Opening,
      speed1Closing,
      speed2Closing,
      speed3Closing,
      powerOpening,
      powerClosing
    ] as emgThresholdsEntry;
    const newValidatedEMG = checkEmgValidity(config as DeviceConfigTemplate, newEMG);
    if (!newValidatedEMG) return;
    updateHistory(setConfigProperty('emgThresholds', newValidatedEMG));
  };

  const handleInputChange = (value, index, { index: fingerSlider, sliderType, min, max }) => {
    handleSliderChangeProportional(value, index, {
      index: fingerSlider,
      sliderType,
      min,
      max
    });
  };

  const handleCheckbox = (checkboxIndex) => {
    const newShowThreshold = showThreshold.map((item, index) => {
      if (index === checkboxIndex) {
        item = !item;
      }
      return item;
    });
    setShowThreshold(newShowThreshold);
  };

  const ButtonsChild = useMemo(
    () => (
      <ButtonsnWrapper data-tour-graph='buttons'>
        <Button
          color={graphMode === 'Combined' ? 'secondary' : 'primary'}
          onClick={() => setGraphMode('Combined')}>
          {t('emg.combined')}
        </Button>
        <Button
          color={graphMode === 'Separate' ? 'secondary' : 'primary'}
          onClick={() => setGraphMode('Separate')}>
          {t('emg.separate')}
        </Button>
        <Button
          color={graphMode === 'Proportional' ? 'secondary' : 'primary'}
          onClick={() => setGraphMode('Proportional')}>
          {t('emg.proportional')}
        </Button>
      </ButtonsnWrapper>
    ),
    [graphMode, t]
  );

  const HorizontalSliders = useMemo(
    () => (
      <>
        <HorizontalGraphSlider
          // @ts-ignore
          data={{ name: 'Time' }}
          handleChange={(e, value) => handleTime(value)}
          label={`Time: last ${Math.round(slidersStateSingle[0].value / 20)} seconds`}
          value={slidersStateSingle[0].value}
          limits={{ min: 200, max: 500 }}
        />
      </>
    ),
    [slidersStateSingle[0].value]
  );

  const { isPlay, play, pause, index, handleSlider } = useReplay(replayData ?? [], replayIsEnabled);

  const ReplaySliderActions = useMemo(() => {
    const dataLength = replayData?.length || 1;
    return (
      <ReplayWrapper>
        <Button onClick={() => (isPlay ? pause() : play())}>{isPlay ? 'Pause' : 'Play'}</Button>
        <HorizontalGraphSlider
          // @ts-ignore
          data={{ name: 'Test 123' }}
          handleChange={(e, value) => handleSlider(value)}
          label={`Progress: ${Math.floor((index / dataLength) * 100)} %`}
          value={index}
          limits={{ min: 0, max: replayData?.length || 1 }}
        />
      </ReplayWrapper>
    );
  }, [replayData, index, isPlay]);

  const coaptSelected = inputDevice?.[0] === InputDevices.kInputOptionPatRec;

  const GainsSliders = useMemo(
    () => (
      <GainsSlidersWrapper emgGains={emgGains}>
        <HorizontalGraphSlider
          // @ts-ignore
          data={{ name: 'Extension' }}
          handleChange={(e, value) => setEmgGainExtension(value)}
          handleOnAfterChange={setEmgGainsStore}
          label={`Open signal gain: ${emgGainExtension}%`}
          value={emgGainExtension}
          limits={{ min: 50, max: 250 }}
          disableSliders={!emgGains || coaptSelected}
        />
        <HorizontalGraphSlider
          // @ts-ignore
          data={{ name: 'Flexion' }}
          handleChange={(e, value) => setEmgGainFlexion(value)}
          handleOnAfterChange={setEmgGainsStore}
          label={`Close signal gain: ${emgGainFlexion}%`}
          value={emgGainFlexion}
          limits={{ min: 50, max: 250 }}
          disableSliders={!emgGains || coaptSelected}
        />
      </GainsSlidersWrapper>
    ),
    [emgGainExtension, emgGainFlexion, emgGains, coaptSelected]
  );

  return (
    <Card>
      {replayIsEnabled && ReplaySliderActions}
      <Wrapper graphMode={graphMode}>
        {graphMode === 'Combined' && <ChartCombined graphContainer={graphContainer} />}
        {graphMode === 'Separate' && (
          <ChartSeparate
            graphContainer1={graphContainerSeparate1}
            graphContainer2={graphContainerSeparate2}
          />
        )}
        {graphMode === 'Proportional' && (
          <ChartProportional graphContainer={graphContainerProportional} />
        )}
        <ControlsWrapper data-tour-graph='time'>
          {HorizontalSliders}
          {GainsSliders}
        </ControlsWrapper>
        {graphMode === 'Combined' && (
          <>
            <ActivationWrapper>
              {' '}
              <VerticalGraphSlider
                // @ts-ignore
                data={{ name: 'Activation' }}
                handleChange={handleSliderDualChange}
                handleOnAfterChange={handleSliderDualOnAfterChange}
                label='CS'
                tooltipDirection='left'
                disableSliders={!canAccessOpenCloseCs}
                sliders={[
                  {
                    value: slidersStateDual[0].valueFirst,
                    label: 'Open',
                    index: 0
                  },
                  {
                    value: slidersStateDual[0].valueSecond,
                    label: 'Close',
                    index: 1
                  }
                ]}
                handleCheckbox={handleCheckbox}
                checked={[showThreshold[0], showThreshold[1]]}
                checkboxIndexes={[0, 1]}
                limits={{ min: 2, max: 100 }}
                trackClasses={['cs-open-slider', 'cs-close-slider']}
              />
            </ActivationWrapper>
            <ContractionWrapper>
              <VerticalGraphSlider
                // @ts-ignore
                data={{ name: 'Contraction' }}
                handleChange={handleSliderDualChange}
                handleOnAfterChange={handleSliderDualOnAfterChange}
                disableSliders={!canAccessOpenCloseActivation}
                label='Activation'
                tooltipDirection='right'
                sliders={[
                  {
                    value: slidersStateDual[1].valueFirst,
                    label: 'Open',
                    index: 0
                  },
                  {
                    value: slidersStateDual[1].valueSecond,
                    label: 'Close',
                    index: 1
                  }
                ]}
                handleCheckbox={handleCheckbox}
                checked={[showThreshold[2], showThreshold[3]]}
                checkboxIndexes={[2, 3]}
                limits={{ min: 2, max: 100 }}
                trackClasses={['activation-open-slider', 'activation-close-slider']}
              />
            </ContractionWrapper>
          </>
        )}
        {graphMode === 'Separate' && (
          <>
            <ActivationSeparate1Wrapper>
              <VerticalGraphSlider
                // @ts-ignore
                data={{ name: 'Activation' }}
                handleChange={handleSliderDualChange}
                handleOnAfterChange={handleSliderDualOnAfterChange}
                disableSliders={!canAccessOpenCloseCs}
                label='CS'
                tooltipDirection='left'
                sliders={[
                  {
                    value: slidersStateDual[0].valueFirst,
                    label: 'Open',
                    index: 0
                  }
                ]}
                handleCheckbox={handleCheckbox}
                checked={[showThreshold[0]]}
                checkboxIndexes={[0]}
                limits={{ min: 2, max: 100 }}
                trackClasses={['cs-open-slider']}
              />
            </ActivationSeparate1Wrapper>
            <ActivationSeparate2Wrapper>
              <VerticalGraphSlider
                // @ts-ignore
                data={{ name: 'Activation' }}
                handleChange={handleSliderDualChange}
                handleOnAfterChange={handleSliderDualOnAfterChange}
                label={graphBreakpoints.hdSmallNum > width ? 'CS' : ''}
                tooltipDirection='left'
                disableSliders={!canAccessOpenCloseCs}
                sliders={[
                  {
                    value: slidersStateDual[0].valueSecond,
                    label: 'Close',
                    index: 1
                  }
                ]}
                handleCheckbox={handleCheckbox}
                checked={[showThreshold[1]]}
                checkboxIndexes={[1]}
                limits={{ min: 2, max: 100 }}
                trackClasses={['cs-close-slider']}
              />
            </ActivationSeparate2Wrapper>
            <ContractionSeparate1Wrapper>
              <VerticalGraphSlider
                // @ts-ignore
                data={{ name: 'Contraction' }}
                handleChange={handleSliderDualChange}
                handleOnAfterChange={handleSliderDualOnAfterChange}
                label='Activation'
                tooltipDirection='right'
                disableSliders={!canAccessOpenCloseActivation}
                sliders={[
                  {
                    value: slidersStateDual[1].valueFirst,
                    label: 'Open',
                    index: 0
                  }
                ]}
                handleCheckbox={handleCheckbox}
                checked={[showThreshold[2]]}
                limits={{ min: 2, max: 100 }}
                checkboxIndexes={[2]}
                trackClasses={['activation-open-slider']}
              />
            </ContractionSeparate1Wrapper>
            <ContractionSeparate2Wrapper>
              <VerticalGraphSlider
                // @ts-ignore
                data={{ name: 'Contraction' }}
                handleChange={handleSliderDualChange}
                handleOnAfterChange={handleSliderDualOnAfterChange}
                label={graphBreakpoints.hdSmallNum > width ? 'Activation' : ''}
                disableSliders={!canAccessOpenCloseActivation}
                tooltipDirection='right'
                sliders={[
                  {
                    value: slidersStateDual[1].valueSecond,
                    label: 'Close',
                    index: 1
                  }
                ]}
                handleCheckbox={handleCheckbox}
                checked={[showThreshold[3]]}
                checkboxIndexes={[3]}
                limits={{ min: 2, max: 100 }}
                trackClasses={['activation-close-slider']}
              />
            </ContractionSeparate2Wrapper>
          </>
        )}
        {graphMode === 'Proportional' && (
          <>
            <AdjusterWrapper>
              <PositionsAdjuster
                values={{
                  columnPrimary: valuesProportional.opening,
                  columnSecondary: valuesProportional.closing
                }}
                handleSliderChange={handleSliderChangeProportional}
                handleOnAfterChange={handleSliderOnAfterChangeProportional}
                handleOnAfterChangeInput={handleSliderOnAfterChangeProportional}
                handleInputChange={handleInputChange}
                columns={['Opening', 'Closing']}
                rows={['Full power', 'Speed 3', 'Speed 2', 'Speed 1', 'CS']}
                graphVersion
                limits={[
                  { min: 2, max: 100 },
                  { min: 2, max: 100 },
                  { min: 2, max: 100 },
                  { min: 2, max: 100 },
                  { min: 2, max: 100 }
                ]}
                disableInput={
                  speedControlStrategy?.[0] === SpeedControlStrategies.kThreshold
                    ? [false, true, true, false, false]
                    : false
                }
                trackClasses={[
                  ['', 'power-slider'],
                  ['speed3-open-slider', 'speed3-close-slider'],
                  ['speed2-open-slider', 'speed2-close-slider'],
                  ['activation-open-proportional-slider', 'activation-close-proportional-slider'],
                  ['cs-open-slider', 'cs-close-slider']
                ]}
              />
            </AdjusterWrapper>
          </>
        )}
        <Spacer />
        {ButtonsChild}
      </Wrapper>
    </Card>
  );
};

export default GraphComponent;
