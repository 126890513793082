import React from 'react';
import styled from 'styled-components';
import { Header2, OptionsCSS, OptionsSliderDescription } from '../Sliders/styled';

const ChildrenWrapper = styled.div`
  ${OptionsCSS}
`;

const OptionElement = ({ children, name, description, ...props }) => (
  <div {...props}>
    <Header2>{name}</Header2>
    <OptionsSliderDescription>{description}</OptionsSliderDescription>
    <ChildrenWrapper>{children}</ChildrenWrapper>
  </div>
);

export default OptionElement;
