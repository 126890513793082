export class Blinker {
  static #instance: any;
  #originalTitle: string = document.title;
  #newTitle: string = '';
  #isBlinking: boolean = false;
  #blinkInterval: any = null;
  #boundHandleFocus: any = null;
  #boundHandleBlur: any = null;
  #audioId: string | null = null;

  constructor(audioId: string) {
    if (Blinker.#instance) {
      return Blinker.#instance;
    }
    Blinker.#instance = this;
    this.#audioId = audioId;
  }

  start(newTitle = 'Firmware update in progress, return to page!') {
    if (this.#isBlinking || this.#boundHandleFocus) return;

    this.#newTitle = newTitle;
    this.#boundHandleFocus = this.#handleFocus.bind(this) as any;
    this.#boundHandleBlur = this.#handleBlur.bind(this) as any;

    this.#addListeners();
    this.#playSound();
  }
  stop() {
    this.#reset();
    this.#removeListeners();
    this.#boundHandleFocus = null;
    this.#boundHandleBlur = null;
    this.#pauseSound();
  }
  #reset() {
    if (!this.#isBlinking) return;

    clearInterval(this.#blinkInterval);
    document.title = this.#originalTitle;
    this.#isBlinking = false;
  }
  #addListeners() {
    window.addEventListener('focus', this.#boundHandleFocus!);
    window.addEventListener('blur', this.#boundHandleBlur!);
  }
  #removeListeners() {
    window.removeEventListener('focus', this.#boundHandleFocus!);
    window.removeEventListener('blur', this.#boundHandleBlur!);
  }
  #handleFocus() {
    this.#reset();
  }
  #handleBlur() {
    if (this.#isBlinking) return;

    this.#isBlinking = true;
    this.#blinkInterval = setInterval(() => {
      document.title =
        document.title === this.#originalTitle ? this.#newTitle : this.#originalTitle;
    }, 1000) as any;
  }
  #playSound() {
    if (!this.#audioId) return;
    const audioFile: HTMLAudioElement | null = document.querySelector(this.#audioId);
    if (audioFile) {
      audioFile.volume = 0.07;
      audioFile.currentTime = 0;
      audioFile.play();
    }
  }
  #pauseSound() {
    if (!this.#audioId) return;
    const audioFile: HTMLAudioElement | null = document.querySelector(this.#audioId);
    if (audioFile) audioFile.pause();
  }
}
