import styled, { css } from 'styled-components';
import { HeaderM, TextXS } from 'components/atoms/Typography/Typography';
import { DisableFunctionality } from 'utils/disableFunctionalityModifiier';

export const Header2 = styled.h3`
  ${HeaderM};
  color: ${({ theme }) => theme.typography.colors.secondary};
`;

export const OptionsCSS = css`
  margin: 15px 10px 0 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 15px 20px 0 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 15px 30px 0 30px;
  }
`;

export const OptionsWrapper = styled.div`
  display: grid;
  gap: 0 20px;
  grid-template-columns: auto 1fr;
  align-items: center;
  ${OptionsCSS}
`;

export const OptionsList = styled.ul`
  display: grid;
  grid-gap: 10px;
  ${({ disable }) => (disable ? DisableFunctionality : true)}
`;

export const OptionsSliderDescription = styled.p`
  ${TextXS};
  width: 80%;
  color: ${({ theme }) => theme.typography.colors.subtitle};
  margin-top: 15px;
  margin-left: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 15px;
    margin-left: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-top: 15px;
    margin-left: 30px;
  }
`;

export const OptionsWrapperMultiSlider = styled.div`
  display: grid;
  gap: 20px;
  grid-template-rows: auto auto;
  ${OptionsCSS};
`;

export const SliderLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
`;
