import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Button } from '@mui/material';
import { ReactComponent as Logo } from 'assets/logo_new.svg';
import { ReactComponent as HandRock } from 'assets/hand-rock-solid.svg';
import { ReactComponent as ChartLine } from 'assets/chart-line-solid.svg';
import { ReactComponent as Microchip } from 'assets/microchip-solid.svg';
import { ReactComponent as Wrench } from 'assets/wrench-solid.svg';
import { ReactComponent as HandPaper } from 'assets/hand-paper-regular.svg';
import { ReactComponent as Clipboard } from 'assets/clipboard-list-solid.svg';
import { ReactComponent as Config } from 'assets/bars-progress-solid.svg';
import { ReactComponent as Connect } from 'assets/bluetooth-brands.svg';
import { ReactComponent as Times } from 'assets/times-solid.svg';
import { ReactComponent as Clock } from 'assets/clock-solid.svg';
import { ReactComponent as Cog } from 'assets/cog-solid.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import useBluetooth from 'hooks/bluetooth/useConnect';
import { useReplayStore } from 'reducers/replayStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import useSidebar from 'hooks/useSidebar';
import useLeaveConfigurator from 'hooks/useLeaveConfigurator';
import {
  APPLICATION_SETTINGS,
  CHOOSE_GRIPS,
  CONFIG_TEMPLATES,
  DEVICE_HISTORY,
  GRAPH,
  GRIPS_CONFIGURATION,
  PROSTHESIS_SETTINGS,
  SERVICE_MENU
} from 'consts/routes';

const Wrapper = styled.nav`
  display: flex;
  height: min-content;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colorPrimary};
  width: 50px;
  padding: 10px 0;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 5px;

  a {
    z-index: 1;
    text-decoration: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  display: block;
  padding: 15% 0;
  width: '40px';
  color: ${({ theme }) => theme.typography.colors.primary};
`;

const WrapperList = styled.div`
  width: 100%;

  a {
    text-decoration: none;
  }

  z-index: 1;
`;

const List = styled.ul`
  display: grid;
  grid-auto-flow: row;
`;

const InnerItem = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 0 auto;
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  padding: 5px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 7px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 10px 0;
  }

  &.active {
    background-color: ${({ theme }) => theme.colorSecondary};
  }
`;

const StyledIcon = css`
  width: 20px;
  fill: white;
`;

const StyledHandPaper = styled(HandPaper)`
  ${StyledIcon}
`;

const StyledChartLine = styled(ChartLine)`
  ${StyledIcon}
`;

const StyledHandRock = styled(HandRock)`
  ${StyledIcon}
`;

const StyledMicrochip = styled(Microchip)`
  ${StyledIcon}
`;

const StyledWrench = styled(Wrench)`
  ${StyledIcon}
`;

const StyledClipboard = styled(Clipboard)`
  ${StyledIcon}
`;

const StyledConfig = styled(Config)`
  ${StyledIcon}
`;

const StyledConnect = styled(Connect)`
  ${StyledIcon}
`;

const StyledTimes = styled(Times)`
  ${StyledIcon}
`;

const StyledClock = styled(Clock)`
  ${StyledIcon}
`;

const StyledCog = styled(Cog)`
  ${StyledIcon}
`;

const ButtonStyle = {
  height: '35px',
  width: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px',
  minWidth: 0
};

const SidebarMinimal = memo(() => {
  const { bluetoothConnect } = useBluetooth();
  const replayIsEnabled = useReplayStore((state) => state.enabled);
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const liveSessionEnabled = useLiveConfiguratorStore((state) => state.enabled);
  const { handleFirmwareNavbar, handleServiceMenuNavbar } = useSidebar();
  const { handleLogout, handleDisconnect } = useLeaveConfigurator();

  return (
    <Wrapper data-tour-general='sidebar'>
      <InnerWrapper>
        <NavLink to={CHOOSE_GRIPS}>
          <StyledLogo />
        </NavLink>
        <WrapperList>
          <List>
            <ReactTooltip id='grips' place='right' type='info' effect='solid'>
              <span>Choose grips</span>
            </ReactTooltip>
            <Item
              data-tip
              data-for='grips'
              as={NavLink}
              to={CHOOSE_GRIPS}
              activeClassName='active'
              data-tour-general='sidebar-grips'>
              <InnerItem>
                <StyledHandPaper />
              </InnerItem>
            </Item>
            <ReactTooltip id='emg' place='right' type='info' effect='solid'>
              <span>{replayIsEnabled ? 'EMG playback' : 'EMG settings'}</span>
            </ReactTooltip>
            <Item
              data-tip
              data-for='emg'
              as={NavLink}
              to={GRAPH}
              activeClassName='active'
              data-tour-general='sidebar-emg'>
              <InnerItem>
                <StyledChartLine />
              </InnerItem>
            </Item>
            <ReactTooltip id='grips-config' place='right' type='info' effect='solid'>
              <span>Grips configuration</span>
            </ReactTooltip>
            <Item
              data-tip
              data-for='grips-config'
              as={NavLink}
              to={GRIPS_CONFIGURATION}
              activeClassName='active'>
              <InnerItem>
                <StyledHandRock />
              </InnerItem>
            </Item>
            <ReactTooltip id='prosthesis-settings' place='right' type='info' effect='solid'>
              <span>Prosthesis settings</span>
            </ReactTooltip>
            <Item
              data-tip
              data-for='prosthesis-settings'
              as={NavLink}
              to={PROSTHESIS_SETTINGS}
              activeClassName='active'
              data-tour-general='sidebar-settings'>
              <InnerItem>
                <StyledClipboard />
              </InnerItem>
            </Item>
            <ReactTooltip id='history' place='right' type='info' effect='solid'>
              <span>Device history</span>
            </ReactTooltip>
            <Item
              as={NavLink}
              to={DEVICE_HISTORY}
              activeClassName='active'
              data-tip
              data-for='history'>
              <InnerItem>
                <StyledClock />
              </InnerItem>
            </Item>
            <ReactTooltip id='templates' place='right' type='info' effect='solid'>
              <span>Config templates</span>
            </ReactTooltip>
            <Item
              as={NavLink}
              to={CONFIG_TEMPLATES}
              activeClassName='active'
              data-tip
              data-for='templates'>
              <InnerItem>
                <StyledConfig />
              </InnerItem>
            </Item>
            <ReactTooltip id='firmware' place='right' type='info' effect='solid'>
              <span>Update firmware</span>
            </ReactTooltip>
            <Item
              data-tip
              data-for='firmware'
              data-tour-general='sidebar-firmware'
              onClick={() => handleFirmwareNavbar(deviceConnected, liveSessionEnabled)}>
              <InnerItem>
                <StyledMicrochip />
              </InnerItem>
            </Item>
            {!liveSessionEnabled && (
              <>
                <ReactTooltip id='service' place='right' type='info' effect='solid'>
                  <span>Tools</span>
                </ReactTooltip>
                <Item
                  as={NavLink}
                  to={SERVICE_MENU}
                  onClick={(e) => handleServiceMenuNavbar(deviceConnected, e)}
                  activeClassName='active'
                  data-tip
                  data-for='service'>
                  <InnerItem>
                    <StyledWrench />
                  </InnerItem>
                </Item>
              </>
            )}
            <ReactTooltip id='application-settings' place='right' type='info' effect='solid'>
              <span>Application settings</span>
            </ReactTooltip>
            <Item
              data-tip
              data-for='application-settings'
              as={NavLink}
              to={APPLICATION_SETTINGS}
              activeClassName='active'>
              <InnerItem>
                <StyledCog />
              </InnerItem>
            </Item>
            <ReactTooltip id='connect' place='right' type='info' effect='solid'>
              <span>{deviceConnected ? 'Disconnect prosthesis' : 'Connect to prosthesis'}</span>
            </ReactTooltip>
            <ReactTooltip id='logout' place='right' type='info' effect='solid'>
              <span>Logout</span>
            </ReactTooltip>
            {!liveSessionEnabled && (
              <Item data-tip data-for='connect'>
                <InnerItem>
                  <Button
                    onClick={deviceConnected ? () => handleDisconnect() : () => bluetoothConnect()}
                    sx={ButtonStyle}>
                    {deviceConnected ? <StyledTimes /> : <StyledConnect />}
                  </Button>
                </InnerItem>
              </Item>
            )}
            <Item data-tip data-for='logout'>
              <InnerItem>
                <Button onClick={handleLogout} sx={ButtonStyle}>
                  <LogoutIcon />
                </Button>
              </InnerItem>
            </Item>
          </List>
        </WrapperList>
      </InnerWrapper>
    </Wrapper>
  );
});

export default SidebarMinimal;
