import { singleElectrodeSettingsAlternatingPositions } from './configPropertiesPositions';

const singleElectrodeSettingsAlternatingNamings: [number, string][] = [
  [singleElectrodeSettingsAlternatingPositions.stateSwitchDelay, 'Direction change time'],
  [singleElectrodeSettingsAlternatingPositions.minimumPulseTime, 'Minimum pulse time'],
  [singleElectrodeSettingsAlternatingPositions.maximumPulseTime, 'Maximum pulse time'],
  [
    singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses,
    'Minimum time between pulses'
  ],
  [
    singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses,
    'Maximum time between pulses'
  ],
  [singleElectrodeSettingsAlternatingPositions.delayBetweenSignals, 'Delay between signals']
];

export const singleElectrodeSettingsAlternatingNamingsMap: any = new Map(
  singleElectrodeSettingsAlternatingNamings
);
// Delay between signals is currently not used
