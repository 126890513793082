import React, { useMemo } from 'react';
import useCompatibilities from 'hooks/useCompatibilities';
import { HISTORY_EVENTS, SUPPORTED_FEATURES } from 'consts/consts';
import { useConfigStore } from 'reducers/configStore';
import {
  GripSwitchingModes,
  InputDevices,
  InputSites as InputSitesEnum,
  SingleElectrodeMode,
  SpeedControlStrategies
} from 'bluetooth/Bluetooth/Control';
import { getCurrentConfigSelector, getFwVersionSelector } from 'reducers/helpers/selectors';
import { defaultConfig } from 'consts/deviceConfig/deviceConfig';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { useReplayStore } from 'reducers/replayStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { DeviceConfigTemplate } from 'consts/deviceConfig/deviceConfig.types';
import { ConfigurationSection, SettingsContainer } from './styled';
import useCanAccess from '../../hooks/useCanAccess';
import EmgSpike from './Features/EmgSpike';
import BatteryBeep from './Features/BatteryBeep';
import HoldOpen from './Features/HoldOpen';
import SingleElectrodeModeSettings from './Features/SingleElectrodeModeSettings';
import PulseTimings from './Features/PulseTimings';
import CoContractionTimings from './Features/CoContractionTimings';
import FreezeModeEMG from './Features/FreezeModeEmg';
import SoftGrip from './Features/Softgrip';
import BuzzingVolumeSettings from './Features/BuzzingVolumeSettings';
import InputOptionsEMG from './Features/InputOptionsEMG';
import InputSites from './Features/InputSites';
import SpeedControlStrategy from './Features/SpeedControlStrategy';
import CsBeforeStartup from './Features/CsBeforeStartup';
import GripSwitching from './Features/GripSwitching';
import SingleElectrodeStrategy from './Features/SingleElectrodeStrategy';
import FreezeModeEMGSettings from './Features/FreezeModeEMGSettings';
import FollowingGrip from './Features/FollowingGrip';
import FingersSpeed from './Features/FingerSpeedCalibration';
import FreezeModeEMGThresholds from './Features/FreezeModeEmgThresholds';
import EmergencyBatteryBeep from './Features/EmergencyBatterySettings';
import OneSpeed from './Features/OneSpeed';
import SingleElectrodeSettingsAlternating from './Features/SingleElectrodeModeSettingsAlternating';

export const parseNumber = (number: number) => Number(number / 1000).toFixed(2);

const permissionMapping = (permissionList: string[]) =>
  permissionList
    .map((item: string) =>
      useCanAccess({
        action: item,
        resource: 'settings'
      })
    )
    .map((result) => result.canAccess);

const ProsthesisSettingsComponent = () => {
  const {
    inputSite,
    inputDevice,
    controlMode,
    speedControlStrategy,
    gripSwitchingMode,
    batteryBeep,
    emgSpike,
    holdOpen,
    softGrip,
    pulseTimings,
    coContractionTimings,
    generalHandSettings,
    freezeModeEmg,
    singleElectrodeMode,
    singleElectrodeModeSettings,
    buzzingVolumeSettings,
    userFeedbackType,
    fingerStrength,
    freezeModeEmgSettings,
    followingGrip,
    emergencyBatterySettings,
    oneSpeed,
    singleElectrodeSettingsAlternating
  } = useConfigStore(getCurrentConfigSelector);
  const { setConfigProperty, setControlConfig, handleProcedure } = useConfigStore((state) => ({
    setConfigProperty: state.setConfigProperty,
    setControlConfig: state.setControlConfig,
    handleProcedure: state.handleProcedure
  }));
  const addConfigHistory = useConfigStore((state) => state.addConfigHistory);
  const replayIsEnabled = useReplayStore((state) => state.enabled);
  const {
    channel,
    enabled: sessionEnabled,
    sessionApi
  } = useLiveConfiguratorStore((state) => ({
    channel: state.channel,
    enabled: state.enabled,
    sessionApi: state.sessionApi
  }));
  const { supportedFeatures, isFullyCompatible } = useCompatibilities();
  const { connected: deviceConnected } = useDeviceInfoStore((state) => ({
    connected: state.connected
  }));
  const deviceInfoState = useDeviceInfoStore((state) => state);

  const [
    inputOptionsPermission,
    inputSitesPermission,
    speedStrategyPermission,
    startupSignalPermission,
    freezeModeSwitchPermission,
    cocontractionPermission,
    gripSwitchingPermission,
    alternatingPermission,
    fastRisingPermission,
    holdOpenPermission,
    softGripPermission,
    batteryBeepPermission,
    emgSpikesPermission
  ] = permissionMapping([
    'inputOptions',
    'inputSites',
    'speedStrategy',
    'startupSignal',
    'freezeModeSwitch',
    'gripSwitching',
    'cocontraction',
    'alternating',
    'fastRising',
    'holdOpen',
    'softGrip',
    'ignoreEmgSpikes',
    'batteryBeep',
    'emgSpikes'
  ]);

  const featuresOptions = useMemo(() => {
    let disableFreezeMode = false;
    const featuresSlugs = supportedFeatures
      .filter((feature) => feature.is_compatible)
      .map((feature) => feature.slug);

    if (!isFullyCompatible) {
      if (!featuresSlugs.includes(SUPPORTED_FEATURES.freezeModeEMG)) disableFreezeMode = true;
    }

    return {
      disableFreezeMode,
      supportNewUserFeedbackType: featuresSlugs.includes(SUPPORTED_FEATURES.feedbackType),
      supportFingerSpeedCalibration: featuresSlugs.includes(SUPPORTED_FEATURES.speedCalibration)
    };
  }, [supportedFeatures, isFullyCompatible]);

  const addHistoryProsthesis = (prevConfig) => {
    addConfigHistory(HISTORY_EVENTS.prosthesisSettings, prevConfig);
  };

  const isFreezeModeOn = Boolean(freezeModeEmg?.[2]);

  const normalizeConfig = (value, property: keyof DeviceConfigTemplate) => {
    const isNotInitialized = (value) => value === false;

    return isNotInitialized(value) ? defaultConfig[`${property}`] : value;
  };

  const configIsSupportedByFirmware = (property) => property !== undefined;
  const disableCalibration = replayIsEnabled || (!deviceConnected && !sessionEnabled);

  const coaptSelected = inputDevice?.[0] === InputDevices.kInputOptionPatRec;
  const slopeSelected =
    inputSite?.[0] === InputSitesEnum.kSingleElectrode &&
    singleElectrodeMode?.[0] === SingleElectrodeMode.kFastRising;

  return (
    <SettingsContainer>
      {configIsSupportedByFirmware(inputDevice) && (
        <ConfigurationSection disabled={!inputOptionsPermission}>
          <InputOptionsEMG
            addHistoryProsthesis={addHistoryProsthesis}
            inputDevice={normalizeConfig(inputDevice, 'inputDevice')}
            inputOptionsPermission={inputSitesPermission}
            replayIsEnabled={replayIsEnabled}
            setConfigProperty={setConfigProperty}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(inputSite) && (
        <ConfigurationSection disabled={!inputSitesPermission}>
          <InputSites
            addHistoryProsthesis={addHistoryProsthesis}
            inputSite={normalizeConfig(inputSite, 'inputSite')}
            controlMode={normalizeConfig(controlMode, 'controlMode')}
            speedControlStrategy={normalizeConfig(speedControlStrategy, 'speedControlStrategy')}
            gripSwitchingMode={normalizeConfig(gripSwitchingMode, 'gripSwitchingMode')}
            disable={replayIsEnabled || !inputSitesPermission || coaptSelected}
            setControlConfig={setControlConfig}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(speedControlStrategy) && (
        <ConfigurationSection disabled={!speedStrategyPermission}>
          <SpeedControlStrategy
            addHistoryProsthesis={addHistoryProsthesis}
            speedControlStrategy={normalizeConfig(speedControlStrategy, 'speedControlStrategy')}
            speedStrategyPermission={speedStrategyPermission}
            replayIsEnabled={replayIsEnabled}
            setConfigProperty={setConfigProperty}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(oneSpeed) &&
        speedControlStrategy?.[0] === SpeedControlStrategies.kThreshold && (
          <ConfigurationSection disabled={false}>
            <OneSpeed
              addHistoryProsthesis={addHistoryProsthesis}
              speed={normalizeConfig(oneSpeed, 'oneSpeed')}
              disable={replayIsEnabled}
            />
          </ConfigurationSection>
        )}
      {configIsSupportedByFirmware(gripSwitchingMode) && (
        <ConfigurationSection disabled={!gripSwitchingPermission}>
          <GripSwitching
            addHistoryProsthesis={addHistoryProsthesis}
            inputSite={normalizeConfig(inputSite, 'inputSite')}
            controlMode={normalizeConfig(controlMode, 'controlMode')}
            speedControlStrategy={normalizeConfig(speedControlStrategy, 'speedControlStrategy')}
            gripSwitchingMode={normalizeConfig(gripSwitchingMode, 'gripSwitchingMode')}
            disable={replayIsEnabled || !gripSwitchingPermission || coaptSelected}
            setControlConfig={setControlConfig}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(coContractionTimings) &&
        gripSwitchingMode?.[0] === GripSwitchingModes.kCoContraction && (
          <ConfigurationSection disabled={!cocontractionPermission}>
            <CoContractionTimings
              addHistoryProsthesis={addHistoryProsthesis}
              coContractionTimings={normalizeConfig(coContractionTimings, 'coContractionTimings')}
              disable={replayIsEnabled || !cocontractionPermission || coaptSelected}
              setConfigProperty={setConfigProperty}
            />
          </ConfigurationSection>
        )}
      {configIsSupportedByFirmware(singleElectrodeMode) &&
        gripSwitchingMode?.[0] === GripSwitchingModes.kSingleGripSwitching && (
          <ConfigurationSection disabled={!alternatingPermission}>
            <SingleElectrodeStrategy
              addHistoryProsthesis={addHistoryProsthesis}
              singleElectrodeMode={normalizeConfig(singleElectrodeMode, 'singleElectrodeMode')}
              disable={replayIsEnabled || !alternatingPermission || coaptSelected}
              setConfigProperty={setConfigProperty}
            />
          </ConfigurationSection>
        )}
      {configIsSupportedByFirmware(singleElectrodeModeSettings) &&
        singleElectrodeMode?.[0] === SingleElectrodeMode.kFastRising &&
        inputSite?.[0] === InputSitesEnum.kSingleElectrode && (
          <ConfigurationSection disabled={!fastRisingPermission}>
            <SingleElectrodeModeSettings
              addHistoryProsthesis={addHistoryProsthesis}
              singleElectrodeModeSettings={normalizeConfig(
                singleElectrodeModeSettings,
                'singleElectrodeModeSettings'
              )}
              disable={replayIsEnabled || !fastRisingPermission || coaptSelected}
              setConfigProperty={setConfigProperty}
            />
          </ConfigurationSection>
        )}
      {configIsSupportedByFirmware(singleElectrodeSettingsAlternating) &&
        getFwVersionSelector(deviceInfoState) >= 231 &&
        singleElectrodeMode?.[0] === SingleElectrodeMode.kAlternating &&
        inputSite?.[0] === InputSitesEnum.kSingleElectrode && (
          <ConfigurationSection disabled={false}>
            <SingleElectrodeSettingsAlternating
              addHistoryProsthesis={addHistoryProsthesis}
              singleElectrodeSettingsAlternating={normalizeConfig(
                singleElectrodeSettingsAlternating,
                'singleElectrodeSettingsAlternating'
              )}
              disabled={replayIsEnabled || coaptSelected}
              setConfigProperty={setConfigProperty}
            />
          </ConfigurationSection>
        )}
      {configIsSupportedByFirmware(pulseTimings) &&
        getFwVersionSelector(deviceInfoState) < 231 &&
        (gripSwitchingMode?.[0] === GripSwitchingModes.kOpenOpen ||
          (singleElectrodeMode?.[0] === SingleElectrodeMode.kAlternating &&
            gripSwitchingMode?.[0] === GripSwitchingModes.kSingleGripSwitching)) && (
          <ConfigurationSection disabled={false}>
            <PulseTimings
              addHistoryProsthesis={addHistoryProsthesis}
              pulseTimings={normalizeConfig(pulseTimings, 'pulseTimings')}
              disable={replayIsEnabled || coaptSelected}
            />
          </ConfigurationSection>
        )}
      {Boolean(gripSwitchingMode?.[0] === GripSwitchingModes.kHoldOpen) && (
        <ConfigurationSection disabled={!holdOpenPermission}>
          <HoldOpen
            addHistoryProsthesis={addHistoryProsthesis}
            holdOpen={normalizeConfig(holdOpen, 'holdOpen')}
            disable={!holdOpenPermission || replayIsEnabled || coaptSelected}
            setConfigProperty={setConfigProperty}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(generalHandSettings) && (
        <ConfigurationSection disabled={!startupSignalPermission}>
          <CsBeforeStartup
            addHistoryProsthesis={addHistoryProsthesis}
            generalHandSettings={normalizeConfig(generalHandSettings, 'generalHandSettings')}
            startupSignalPermission={startupSignalPermission}
            disable={replayIsEnabled || !startupSignalPermission || coaptSelected}
            setConfigProperty={setConfigProperty}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(followingGrip) && (
        <ConfigurationSection disabled={!startupSignalPermission}>
          <FollowingGrip
            addHistoryProsthesis={addHistoryProsthesis}
            followingGrip={normalizeConfig(followingGrip, 'followingGrip')}
            replayIsEnabled={replayIsEnabled}
            setConfigProperty={setConfigProperty}
            gripSwitchingMode={gripSwitchingMode}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(freezeModeEmg) && !featuresOptions.disableFreezeMode && (
        <ConfigurationSection disabled={false}>
          <FreezeModeEMG
            addHistoryProsthesis={addHistoryProsthesis}
            freezeModeSwitchPermission={freezeModeSwitchPermission}
            freezeModeEmg={normalizeConfig(freezeModeEmg, 'freezeModeEmg')}
            replayIsEnabled={replayIsEnabled}
            setConfigProperty={setConfigProperty}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(freezeModeEmgSettings) &&
        isFreezeModeOn &&
        !featuresOptions.disableFreezeMode && (
          <ConfigurationSection disabled={false}>
            <FreezeModeEMGSettings
              addHistoryProsthesis={addHistoryProsthesis}
              freezeModeEmgSettings={normalizeConfig(
                freezeModeEmgSettings,
                'freezeModeEmgSettings'
              )}
              replayIsEnabled={replayIsEnabled}
              setConfigProperty={setConfigProperty}
              gripSwitchingMode={gripSwitchingMode}
            />
          </ConfigurationSection>
        )}
      {configIsSupportedByFirmware(freezeModeEmg) &&
        isFreezeModeOn &&
        !featuresOptions.disableFreezeMode && (
          <ConfigurationSection disabled={false}>
            <FreezeModeEMGThresholds
              addHistoryProsthesis={addHistoryProsthesis}
              freezeModeEmg={freezeModeEmg === false ? defaultConfig.freezeModeEmg : freezeModeEmg}
              replayIsEnabled={replayIsEnabled}
              setConfigProperty={setConfigProperty}
            />
          </ConfigurationSection>
        )}
      {configIsSupportedByFirmware(emgSpike) && (
        <ConfigurationSection disabled={!emgSpikesPermission}>
          <EmgSpike
            addHistoryProsthesis={addHistoryProsthesis}
            emgSpike={normalizeConfig(emgSpike, 'emgSpike')}
            emgSpikesPermission={emgSpikesPermission}
            disabled={replayIsEnabled || !emgSpikesPermission || coaptSelected || slopeSelected}
            setConfigProperty={setConfigProperty}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(softGrip) && (
        <ConfigurationSection disabled={!softGripPermission}>
          <SoftGrip
            addHistoryProsthesis={addHistoryProsthesis}
            softGrip={normalizeConfig(softGrip, 'softGrip')}
            fingerStrength={normalizeConfig(fingerStrength, 'fingerStrength')}
            softGripPermission={softGripPermission}
            replayIsEnabled={replayIsEnabled}
            sessionApi={sessionApi}
            sessionEnabled={sessionEnabled}
            channel={channel}
            setConfigProperty={setConfigProperty}
            handleProcedure={handleProcedure}
            disableCalibration={disableCalibration}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(batteryBeep) && (
        <ConfigurationSection disabled={!batteryBeepPermission}>
          <BatteryBeep
            addHistoryProsthesis={addHistoryProsthesis}
            batteryBeep={normalizeConfig(batteryBeep, 'batteryBeep')}
            batteryBeepPermission={batteryBeepPermission}
            replayIsEnabled={replayIsEnabled}
            setConfigProperty={setConfigProperty}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(emergencyBatterySettings) && (
        <ConfigurationSection disabled={false}>
          <EmergencyBatteryBeep
            addHistoryProsthesis={addHistoryProsthesis}
            emergencyBatterySettings={normalizeConfig(
              emergencyBatterySettings,
              'emergencyBatterySettings'
            )}
            replayIsEnabled={replayIsEnabled}
            setConfigProperty={setConfigProperty}
          />
        </ConfigurationSection>
      )}
      {configIsSupportedByFirmware(buzzingVolumeSettings) && (
        <ConfigurationSection disabled={false}>
          <BuzzingVolumeSettings
            addHistoryProsthesis={addHistoryProsthesis}
            userFeedbackType={normalizeConfig(userFeedbackType, 'userFeedbackType')}
            buzzingVolumeSettings={normalizeConfig(buzzingVolumeSettings, 'buzzingVolumeSettings')}
            replayIsEnabled={replayIsEnabled}
            supportNewFeedbackType={featuresOptions.supportNewUserFeedbackType}
            setConfigProperty={setConfigProperty}
          />
        </ConfigurationSection>
      )}
      {featuresOptions?.supportFingerSpeedCalibration && (
        <ConfigurationSection disabled={false}>
          <FingersSpeed
            sessionApi={sessionApi}
            sessionEnabled={sessionEnabled}
            channel={channel}
            handleProcedure={handleProcedure}
            disableCalibration={disableCalibration}
          />
        </ConfigurationSection>
      )}
    </SettingsContainer>
  );
};

export default ProsthesisSettingsComponent;
