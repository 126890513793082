import React from 'react';
import './loader-pulse.css';

const LoaderPulse = ({ color = 'red', style, ...props }: { color?: string; style?: any }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 14.8 6.3'
    style={{ minWidth: '20px', ...style }}>
    <g style={{ fill: color }}>
      <circle className='loading-circle-1' cx='3.3' cy='2.7' r='1' />
      <circle className='loading-circle-2' cx='9.8' cy='2.7' r='1' />
    </g>
  </svg>
);

export default LoaderPulse;
