import { useEffect } from 'react';
import { flatten, unflatten } from 'flat';
import { keys, pickBy } from 'lodash';
import { useUpdateDeviceConfig } from 'hooks/api/useDevice';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useConfigStore } from 'reducers/configStore';
import { CommonConfigTemplate, ModeConfigTemplate } from 'consts/deviceConfig/deviceConfig.types';

const featureIsInactive = (val) => !val;
const countDisabledFeatures = (config) => {
  if(!config) {
    return 0;
  }
  const flattenConfig: any = flatten(config, { safe: true });
  return keys(pickBy(flattenConfig, featureIsInactive)).length;
};

const filteredConfigAPI = (configAPI): any => {
  const flattenConfigAPI: any = flatten(configAPI, { safe: true });
  const filteredConfigAPI = Object.fromEntries(
    Object.entries(flattenConfigAPI).filter(([, value]) => value !== false)
  );
  // @ts-ignore
  return unflatten(filteredConfigAPI, { safe: true });
};

export const prepareConfigProperties = (
  config: {
    common: {
      config: CommonConfigTemplate;
      configAPI: CommonConfigTemplate | null;
    };
    modes: {
      config: ModeConfigTemplate;
      configAPI: ModeConfigTemplate | null;
      slot: number;
      name: string;
      id: number | null;
    }[];
  },
  deviceId
) => {
  const configs = [
    [config.common.config, config.common.configAPI],
    ...config.modes.map((mode) => [mode.config, mode.configAPI])
  ];
  let needsUpdate = false;
  for (let i = 0; i < configs.length; i += 1) {
    if (countDisabledFeatures(configs[i][1]) > 0) {
      needsUpdate = true;
      break;
    }
  }
  let configPayload: null | { deviceId: number; data: any } = null;
  if (needsUpdate) {
    const filteredCommonConfig = filteredConfigAPI(config.common.configAPI);
    configPayload = {
      deviceId,
      data: {
        common: JSON.stringify({
          ...config.common.config,
          ...filteredCommonConfig,
          gripsPositions: {
            ...config.common.config.gripsPositions,
            ...filteredCommonConfig.gripsPositions
          }
        }),
        modes: config.modes.map((mode) => ({
          id: mode.id!,
          config: JSON.stringify({
            ...mode.config,
            ...filteredConfigAPI(mode.configAPI)
          })
        }))
      }
    };
  }
  return configPayload;
};

const useSynchronizeConfigProperties = () => {
  const { firstConnection, localConfigFetched, config, configConflict, getInitialConfigAPI } =
    useConfigStore((state) => ({
      firstConnection: state.firstConnection,
      localConfigFetched: state.localConfigFetched,
      configConflict: state.configConflict,
      config: state.config,
      getInitialConfigAPI: state.getInitialConfigAPI
    }));
  const { updateConfig } = useUpdateDeviceConfig();
  const deviceId = useDeviceInfoStore((state) => Number(state.deviceId));

  useEffect(() => {
    if (localConfigFetched && !firstConnection && !configConflict) {
      const updateConfigProperties = async () => {
        const configPayload = prepareConfigProperties(config, deviceId);
        if (configPayload) {
          await updateConfig(configPayload);
          await getInitialConfigAPI();
        }
      };
      updateConfigProperties();
    }
  }, [localConfigFetched, configConflict]);
};

export default useSynchronizeConfigProperties;
