import { fetchUserData } from 'api/authentication/authentication';
import { useQuery } from 'react-query';

export const useCheckUserAuthorization = () => useQuery(['auto-logout'], fetchUserData, {
  refetchOnWindowFocus: false,
  staleTime: 0,
  retry: 0
});

export default useCheckUserAuthorization;
