import React from 'react';
import IconsSidebar from 'components/organisms/IconsSidebar.js/IconsSidebar';
import DefaultLayout from 'layouts/DefaultLayout';
import MinimalLayout from 'layouts/MinimalLayout';
import { Header1 } from 'views/Servicing/styled';
import { IconSidebarModes } from 'components/atoms/IconDescribed/IconDescribed';

const LayoutSwitcher = ({
  component,
  header = null,
  handleUndo = null,
  isUnsaved = false,
  isStandard,
  padding = '0 0 0 0',
  paddingMinimal = '0 0 0 0',
  headerPadding = '0 0 0 0'
}) =>
  isStandard ? (
    <DefaultLayout padding={padding}>
      {handleUndo && <IconsSidebar undoFunc={handleUndo} unsaved={isUnsaved} />}
      {header && <Header1 style={{ padding: headerPadding }}>{header}</Header1>}
      {component()}
    </DefaultLayout>
  ) : (
    <MinimalLayout
      padding={paddingMinimal}
      // @ts-ignore
      iconsSidebar={
        handleUndo ? (
          <IconsSidebar mode={IconSidebarModes.minimal} undoFunc={handleUndo} unsaved={isUnsaved} />
        ) : null
      }>
      {component()}
    </MinimalLayout>
  );

export default LayoutSwitcher;
