import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Button, DialogContent, MenuItem, TextField } from '@mui/material';
import ErrorLabel from 'components/atoms/Error/ErrorLabel';
import { getPCBVersionAPI } from 'api/versions/versions';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const Wrapper = styled.form`
  display: grid;
  gap: 24px;
`;

type PcbReplacementBluetoothProps = {
  handleNext: Function;
  handleBluetooth: Function;
  handlePcb: Function;
  bluetoothId: string | undefined;
  pcb: any | undefined;
};

export const PCB_VERSION_QUERY_KEY = 'version/pcb';

const PcbReplacementBluetooth = ({
  handleNext,
  handleBluetooth,
  bluetoothId,
  handlePcb,
  pcb
}: PcbReplacementBluetoothProps) => {
  const { data: pcbVersions } = useQuery(PCB_VERSION_QUERY_KEY, () => getPCBVersionAPI());
  const [showError, setShowError] = useState(false);

  const onSubmit = () => {
    if (!bluetoothId || !pcb) {
      setShowError(true);
      return;
    }
    handleNext();
  };

  return (
    <DialogContent>
      <Wrapper>
        <p>Type Bluetooth ID of the new PCB in order to proceed.</p>
        <TextField
          id='bluetoothId'
          label='Bluetooth ID'
          required
          onChange={(e) => handleBluetooth(e.target.value)}
          value={bluetoothId || ''}
        />
        {pcbVersions && (
          <TextField
            id='selected-pcb'
            select
            label='PCB version'
            required
            SelectProps={{
              onChange: (e) =>
                handlePcb(pcbVersions.find((version) => version?.id === e.target.value)),
              value: pcb?.id || ''
            }}>
            {pcbVersions.map((pcbVersion) => (
              <MenuItem key={`selected-pcb_${pcbVersion?.id}`} value={pcbVersion?.id}>
                {pcbVersion?.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {showError && <ErrorLabel>This fields are required</ErrorLabel>}
        <ButtonsWrapper>
          <Button type='button' onClick={onSubmit}>
            Next step
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </DialogContent>
  );
};

export default PcbReplacementBluetooth;
