import React, { useCallback, useEffect, useRef, useState } from 'react';
import worker_script from 'workers/worker-script';
import wakeup_script from 'workers/wakeup-script';
import { debounce, throttle } from 'lodash';
import axios from 'axios';
import useSynchronizeAutoLogoutTimer from '../../../hooks/useSynchronizeAutoLogoutTimer';
import useLogout from '../../../hooks/useLogout';
import AutoLogoutModal from '../../molecules/Modals/AutoLogoutModal';
import { useModal } from '../../../hooks/useModal';
import { Commands } from '../../../bluetooth/Bluetooth/Defines';
import useUnsaved from '../../../hooks/useUnsaved';
import useModes from '../../../hooks/useModes';
import { useLiveConfiguratorStore } from '../../../reducers/liveConfiguratorStore';
import { refreshTokenApi } from '../../../api/authentication/authentication';
import useCheckUserAuthorization from '../../../hooks/api/useCheckUserAuthorization';
import { useMeetingStore } from '../../../reducers/meetingStore';

const timerWorker = new Worker(worker_script);
const wakeupWorker = new Worker(wakeup_script);

let timer: ReturnType<typeof setTimeout> | null = null;
let warningInactiveInterval: ReturnType<typeof setInterval> | null = null;
let saveModePromise: Promise<void> | null = null;

const autoLogoutMaxTime: number = Number(process.env.REACT_APP_AUTOLOGOUT_TIME) ?? 600; // number of seconds to perform auto logout
const autoLogoutWarningTime: number = Number(process.env.REACT_APP_AUTOLOGOUT_WARNING_TIME) ?? 540; // number of seconds to display modal

const AutoLogout = ({ children, user, playSound: playSoundElement }) => {
  const { enabled: remoteSessionEnabled } = useLiveConfiguratorStore((state) => state);
  const { meetingStatus: meetingEnabled } = useMeetingStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startListen, setStartListen] = useState<boolean>(false);
  const [autoLogoutRemainingTime, setAutoLogoutRemainingTime] = useState<number>(0);
  const { isError: isFetchUserError, refetch, error } = useCheckUserAuthorization();
  const { logout, offlineLogout } = useLogout();
  const { handleSaveModeBeforeAutoLogout } = useModes();
  const { isUnsaved } = useUnsaved();
  const isUnsavedRef = useRef(isUnsaved);
  const {
    isOpen: autoLogoutWarningOpen,
    handleOpen: handleAutoLogoutWarningOpen,
    handleClose: handleAutoLogoutWarningClose
  } = useModal();
  const {
    emitActivityEvent,
    emitEvent,
    initialized,
    on: startListenEvent,
    off: stopListenEvent
  } = useSynchronizeAutoLogoutTimer(user, () => {
    setAutoLogoutRemainingTime(0);
    timerWorker.postMessage({ action: 'reset' });
  });

  useEffect(() => {
    if (autoLogoutRemainingTime === autoLogoutWarningTime) openAutoLogoutWarning();
    if (autoLogoutRemainingTime === autoLogoutMaxTime) beforeLogout('timer');
  }, [autoLogoutRemainingTime]);

  const beforeLogout = debounce((name: string) => {
    if (saveModePromise) return;
    if (isUnsavedRef.current) {
      setIsLoading(true);
      emitEvent('configSaving');
      saveModePromise = handleSaveModeBeforeAutoLogout({ note: 'Automatic session logout' });
      saveModePromise.then(() => {
        emitEvent('forceLogout');
        saveModePromise = null;
        logout();
      });
      return;
    }
    setIsLoading(true);
    emitEvent('forceLogout');
    logout();
  }, 1000);

  const openAutoLogoutWarning = () => {
    handleAutoLogoutWarningOpen();
    playSoundElement();
  };

  const resetAutoLogout = () => {
    emitEvent('cancelLogout');
  };

  const checkLastActivityData = () => {
    const lastActivity = sessionStorage.getItem('lastActivity');
    if (lastActivity) {
      const lastActivityDate = new Date(lastActivity);
      const currentDate = new Date();
      const diff = Math.floor((currentDate.getTime() - lastActivityDate.getTime()) / 1000);

      if (diff > autoLogoutMaxTime) {
        logout();
      }
    }
  };

  const warningInactive = () => {
    if (timer) clearTimeout(timer);
    warningInactiveInterval = setInterval(() => {
      refetch();
    }, 60000);
  };
  const timeChecker = () => {
    timer = setTimeout(() => {
      warningInactive();
    }, 60000);
  };

  useEffect(() => {
    timeChecker();
    setStartListen(true);

    return () => {
      setStartListen(false);
      if (timer) clearTimeout(timer);
      if (warningInactiveInterval) clearInterval(warningInactiveInterval);
    };
  }, [timer]);

  useEffect(() => {
    const callback = () => {
      checkLastActivityData();
      refetch();
    };

    if (startListen) {
      document.addEventListener('visibilitychange', callback);
    } else {
      document.removeEventListener('visibilitychange', callback);
    }

    return () => {
      document.removeEventListener('visibilitychange', callback);
    };
  }, [startListen]);

  useEffect(() => {
    if (isFetchUserError && error) {
      if (!axios.isAxiosError(error)) return;
      if (
        error.response?.status === 401 &&
        error.response.data?.method !== undefined &&
        error.response.data.message === 'MFA is required for this request.'
      )
        return;
      if (warningInactiveInterval) clearInterval(warningInactiveInterval);

      offlineLogout();
    }
  }, [isFetchUserError]);

  useEffect(() => {
    timerWorker.onmessage = ({ data: { time, action } }) => {
      if (action === 'timer') {
        setAutoLogoutRemainingTime(time);
        return;
      }

      if (action === 'fakeActivity') {
        emitActivityEvent();
      }
    };

    wakeupWorker.onmessage = ({ data }) => {
      if (data === 'wakeup') {
        checkLastActivityData();
      }
    };
  }, []);

  useEffect(() => {
    if (!initialized) return;
    const callback = () => {
      beforeLogout('event');
    };

    const cancelAutoLogout = () => {
      setAutoLogoutRemainingTime(0);
      handleAutoLogoutWarningClose();
      refreshTokenApi();
    };

    const logoutFromADP = () => {
      window.location.reload()
    }

    startListenEvent('softLogout', callback);
    startListenEvent('cancelLogout', cancelAutoLogout);
    startListenEvent('clickLogoutButton', logoutFromADP);
    timerWorker.postMessage({ action: 'on' });
    return () => {
      timerWorker.postMessage({ action: 'off' });
      stopListenEvent('softLogout', callback);
      stopListenEvent('cancelLogout', cancelAutoLogout);
      stopListenEvent('clickLogoutButton', logoutFromADP);
    };

  }, [initialized]);

  const listenerCallback = useCallback(throttle(() => {
    emitActivityEvent();
  }, 1000), []);

  useEffect(() => {
    if (!initialized) return;

    sessionStorage.removeItem('lastActivity');
    document.addEventListener('click', listenerCallback);
    window.addEventListener(`received${Commands.kTelemetryData}`, listenerCallback);
    window.addEventListener(`replay`, listenerCallback);
    emitActivityEvent();

    return () => {
      document.removeEventListener('click', listenerCallback);
      window.removeEventListener(`received${Commands.kTelemetryData}`, listenerCallback);
      window.removeEventListener(`replay`, listenerCallback);
    };
  }, [initialized]);

  useEffect(() => {
    if (remoteSessionEnabled || meetingEnabled) {
      timerWorker.postMessage({ action: 'fakeActivity' });
    } else {
      timerWorker.postMessage({ action: 'fakeActivityReset' });
    }

    return () => {
      timerWorker.postMessage({ action: 'fakeActivityReset' });
    };
  }, [remoteSessionEnabled, meetingEnabled]);

  useEffect(() => {
    isUnsavedRef.current = isUnsaved;
  }, [isUnsaved]);

  return (
    <>
      {autoLogoutWarningOpen && (
        <AutoLogoutModal
          isLoading={isLoading}
          remainingTimeInSeconds={autoLogoutMaxTime - autoLogoutWarningTime}
          resetAction={resetAutoLogout}
          logoutAction={() => beforeLogout('modal')}
        />
      )}
      {children}
    </>
  );
};

export default AutoLogout;
