import React from 'react';
import toast from 'react-hot-toast';
import platform from 'platform';
import {
  getBootloaderStatusTimed,
  getEmergencyBatteryModeStatus,
  postActiveMode,
  postCommunicateMode
} from 'bluetooth-handler/bluetoothFunctions';
import { BootloaderStates } from 'bluetooth/Bluetooth/Defines';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import {
  ALLOWED_OS,
  FETCHING_STATES,
  MINIMAL_SUPPORTED_FIRMWARE_VERSION,
  REQUIRED_SUPPORTED_FIRMWARE_VERSION
} from 'consts/consts';
import { useDeviceUpdate, useUpdateDeviceConfig } from 'hooks/api/useDevice';
import useCompatibilities from 'hooks/useCompatibilities';
import useUnsaved from 'hooks/useUnsaved';
import { numericalFirmwareVersion } from 'utils/funcs';
import { useConfigStore } from 'reducers/configStore';
import { useUiStore } from 'reducers/uiStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { MODALS } from 'views/Modals';
import { EMERGENCY_MODE_NOTIFICATION } from 'consts/notifications';
import { CompatibilitiesExtendOptions } from 'api/versions/versions.types';
import { getCompatibilities } from 'api/versions/versions';
import useModes from 'hooks/useModes';

const useBluetooth = () => {
  const {
    bluetoothId,
    deviceId,
    firmware,
    amputeeId,
    getDeviceInfoAPI,
    setItemDeviceInfo,
    getFirmwareDevice,
    getPcbVersionDevice
  } = useDeviceInfoStore((state) => ({
    bluetoothId: state.bluetoothId,
    deviceId: Number(state.deviceId),
    firmware: state.firmware,
    amputeeId: state.amputeeId,
    setItemDeviceInfo: state.setItemDeviceInfo,
    getFirmwareDevice: state.getFirmwareDevice,
    getDeviceInfoAPI: state.getDeviceInfoAPI,
    getPcbVersionDevice: state.getPcbVersionDevice
  }));
  const {
    connectDevice,
    disconnectDevice,
    firstConnection,
    getInitialConfig,
    getInitialConfigAPI,
    slotSelected
  } = useConfigStore((state) => ({
    connectDevice: state.connectDevice,
    disconnectDevice: state.disconnectDevice,
    firstConnection: state.firstConnection,
    getInitialConfig: state.getInitialConfig,
    getInitialConfigAPI: state.getInitialConfigAPI,
    slotSelected: state.slotSelected
  }));
  const { mutateAsync: updateDevice } = useDeviceUpdate();
  const { updateConfig } = useUpdateDeviceConfig();
  const { isUnsaved } = useUnsaved();
  const { setItemUiStore, openModal } = useUiStore((state) => ({
    setItemUiStore: state.setItemUiStore,
    openModal: state.openModal
  }));
  const { handleOpenSave } = useModes();

  const synchronizeDeviceFirmware = async (firmwareVersionBluetooth) => {
    const deviceInfo = await getDeviceInfoAPI();
    const compatibilitiesParamsFirmware = {
      model: deviceInfo.model_id,
      software: process.env.REACT_APP_SOFTWARE_VERSION,
      pcb: deviceInfo.pcb_version.name,
      extend: [CompatibilitiesExtendOptions.features, CompatibilitiesExtendOptions.firmwareVersion]
    };
    const compatibilities = await getCompatibilities({ params: compatibilitiesParamsFirmware });
    const firmwareApiId = compatibilities?.items.find(
      (firmware) => firmware.firmware_version.name === firmwareVersionBluetooth
    )?.firmware_version_id;
    if (!firmwareApiId) {
      toast(
        'Firmware version of the device is different from the one stored in ADP, contact support.',
        { duration: 20000, icon: '⚠️' }
      );
      return;
    }
    await updateDevice({ deviceId, data: { firmware_version_id: firmwareApiId } });
    await getInitialConfigAPI();
  };

  const checkEmergencyMode = async () => {
    const emergencyStatus = await getEmergencyBatteryModeStatus();

    if (emergencyStatus?.[0] === 1) {
      toast(EMERGENCY_MODE_NOTIFICATION.message, EMERGENCY_MODE_NOTIFICATION.options);
      bluetoothDisconnect(true);
      return true;
    }

    return false;
  };

  const bluetoothConnect = async (customBluetoothId: string | null = null) => {
    if (!ALLOWED_OS.includes(platform.os.family)) {
      toast(
        <div>
          Your operating system is not supported in local mode, please read the{' '}
          <a
            rel='noreferrer'
            target='_blank'
            href='https://7842652.fs1.hubspotusercontent-na1.net/hubfs/7842652/PDF%20Resources/Software%20Instruction%202022-web.pdf?__hstc=226640322.174d0b9ca68e2b3b395230cc62bcffb3.1672752111406.1672752111406.1672752111406.1&__hssc=226640322.3.1672752111406&__hsfp=2755598511&hsCtaTracking=b87f3133-a2a6-459a-9760-2f121df48152%7C4b51ac36-22a5-4b50-baf7-37d0de976790'>
            IFU
          </a>
        </div>,
        { duration: 30000, icon: '⚠️', id: 'unsupportedOs' }
      );
    }
    // @ts-ignore
    try {
      setItemUiStore('connectionState', FETCHING_STATES.loading);

      const connected = await connectDevice({ bluetoothId: customBluetoothId ?? bluetoothId });

      setItemUiStore('shownGlobalModal', 'prevent');

      if (!connected) {
        toast.error("Couldn't connect with device, try again");
        setItemUiStore('shownGlobalModal', null);
        setItemUiStore('connectionState', FETCHING_STATES.failed);
        return;
      }

      await getPcbVersionDevice();

      const bootloaderStatus = await getBootloaderStatusTimed();
      if (bootloaderStatus[0] !== BootloaderStates.incative) {
        openModal(MODALS.bootloaderMode);
        setItemUiStore('shownGlobalModal', null);
        setItemUiStore('connectionState', FETCHING_STATES.successful);
        return;
      }
      // @ts-ignore
      const firmwareDevice = await getFirmwareDevice();

      if (connected && firmwareDevice) {
        let config;
        const firmwareVersionBluetooth = firmwareDevice.firmwareVersion
          .slice(0, 8)
          .map((item) => String.fromCharCode(item))
          .join('');
        const firmwareVersionBluetoothParsed = `${firmwareVersionBluetooth[1]}.${firmwareVersionBluetooth[4]}.${firmwareVersionBluetooth[7]}`;
        const firmwareVersionAPI = firmware?.name;
        if (firmwareVersionAPI !== firmwareVersionBluetoothParsed) {
          await synchronizeDeviceFirmware(firmwareVersionBluetoothParsed);
        }

        const isInEmergency = await checkEmergencyMode();

        if (isInEmergency) throw new Error();

        if (
          numericalFirmwareVersion(firmwareVersionBluetoothParsed) >=
          MINIMAL_SUPPORTED_FIRMWARE_VERSION
        ) {
          console.log('GET CONFIG');
          config = await getInitialConfig();
          const modeSlot = slotSelected ?? config.modes[0].slot;
          await postCommunicateMode(modeSlot);
          await postActiveMode(modeSlot);
        }

        if (
          firstConnection &&
          numericalFirmwareVersion(firmwareVersionBluetoothParsed) >=
            MINIMAL_SUPPORTED_FIRMWARE_VERSION
        ) {
          setItemUiStore('shownGlobalModal', 'prevent');
          const modesArray = config.modes.map((mode) => ({
            id: mode.id!,
            config: JSON.stringify(mode.config)
          }));
          await updateConfig({
            deviceId,
            data: {
              common: JSON.stringify(config.common),
              modes: modesArray
            }
          });
          await delay(500);
          await getInitialConfigAPI();
          setItemUiStore('shownGlobalModal', null);
        }

        if (
          numericalFirmwareVersion(firmwareVersionBluetoothParsed) <
          REQUIRED_SUPPORTED_FIRMWARE_VERSION
        ) {
          setItemDeviceInfo('supported', false);
          openModal(MODALS.firmware);
        }
      }
      setItemUiStore('shownGlobalModal', null);
      setItemUiStore('connectionState', FETCHING_STATES.successful);
      return true;
    } catch (e) {
      console.log(e);
      setItemUiStore('shownGlobalModal', null);
      setItemUiStore('connectionState', FETCHING_STATES.failed);
      return false;
    }
  };

  const bluetoothConnectRegister = async () => {
    if (!ALLOWED_OS.includes(platform.os.family)) {
      toast(
        <div>
          Your operating system is not supported in local mode, please read the{' '}
          <a
            rel='noreferrer'
            target='_blank'
            href='https://7842652.fs1.hubspotusercontent-na1.net/hubfs/7842652/PDF%20Resources/Software%20Instruction%202022-web.pdf?__hstc=226640322.174d0b9ca68e2b3b395230cc62bcffb3.1672752111406.1672752111406.1672752111406.1&__hssc=226640322.3.1672752111406&__hsfp=2755598511&hsCtaTracking=b87f3133-a2a6-459a-9760-2f121df48152%7C4b51ac36-22a5-4b50-baf7-37d0de976790'>
            IFU
          </a>
        </div>,
        { duration: 30000, icon: '⚠️', id: 'unsupportedOs' }
      );
    }
    try {
      setItemUiStore('connectionState', FETCHING_STATES.loading);

      const connected = await connectDevice({ bluetoothId: null });

      setItemUiStore('shownGlobalModal', 'prevent');

      if (!connected) {
        toast.error("Couldn't connect with device, try again");
        setItemUiStore('shownGlobalModal', null);
        setItemUiStore('connectionState', FETCHING_STATES.failed);
        return;
      }

      const deviceData = await getPcbVersionDevice();
      setItemUiStore('connectionState', FETCHING_STATES.successful);
      return deviceData;
    } catch (e) {
      console.log(e);
      setItemUiStore('connectionState', FETCHING_STATES.failed);
      return;
    } finally {
      setItemUiStore('shownGlobalModal', null);
    }
  };

  const bluetoothDisconnect = async (showServicing = true) => {
    await disconnectDevice();
    if (amputeeId && showServicing) openModal(MODALS.servicingModal);
  };

  return { bluetoothConnect, bluetoothDisconnect, bluetoothConnectRegister };
};

export default useBluetooth;
