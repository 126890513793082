import React from 'react';
import styled from 'styled-components';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import LoaderPulse from 'components/atoms/Loader/LoaderPulse/Loader-pulse';
import {
  HandMovementStates,
  gripsGroupsOptionsMap,
  handMovementStatesMap
} from 'utils/definesLocal';
import useWatch from 'hooks/useWatch';
import TelemetryController from 'bluetooth-handler/telemetryController';
import { Chip } from '@mui/material';
import { gripsImagesMap } from 'utils/gripsImages';

const Wrapper = styled.div`
  display: flex;
  background-color: #f6f6f6;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  gap: 24px;
`;

const Header = styled.h3`
  font-weight: 700;
`;

const GripImage = styled.img`
  height: 40px;
`;

const handMovementColorTuples: [HandMovementStates, string][] = [
  [HandMovementStates.idle, '#FFC1073D'],
  [HandMovementStates.opening, '#0D6EFD33'],
  [HandMovementStates.closing, '#F5C6CB']
];

const handMovementIndicatorTuples: [HandMovementStates, any][] = [
  [HandMovementStates.idle, <ErrorOutline sx={{ color: '#FFC107' }} />],
  [HandMovementStates.opening, <LoaderPulse color='#0E63F4' />],
  [HandMovementStates.closing, <LoaderPulse color='#D04A57' />]
];

const handMovementColorMap: Map<HandMovementStates, string> = new Map(handMovementColorTuples);
const handMovementIndicatorsMap: Map<HandMovementStates, string> = new Map(
  handMovementIndicatorTuples
);

const HandStatus = () => {
  const telemetryData = useWatch(TelemetryController, ['prosthesisGrip', 'handMovementState']);
  const canShowStatus =
    telemetryData.prosthesisGrip !== undefined && telemetryData.handMovementState !== undefined;

  return canShowStatus ? (
    <Wrapper>
      <Header>Hand status</Header>
      <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '8px' }}>
        <div>Grip:</div>
        <GripImage
          src={gripsImagesMap.get(telemetryData.prosthesisGrip) as any}
          alt='Current grip'
        />
        <Chip
          label={gripsGroupsOptionsMap.get(telemetryData.prosthesisGrip)}
          sx={{ width: '130px', fontSize: '14px' }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '8px' }}>
        <div>Action:</div>
        <Chip
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
                {handMovementIndicatorsMap.get(telemetryData.handMovementState)}
              </div>
              <div>{handMovementStatesMap.get(telemetryData.handMovementState)}</div>
            </div>
          }
          sx={{
            width: '145px',
            backgroundColor: handMovementColorMap.get(telemetryData.handMovementState),
            fontSize: '14px'
          }}
        />
      </div>
    </Wrapper>
  ) : (
    <></>
  );
};

export default HandStatus;
