import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HandRock } from 'assets/hand-rock-solid.svg';
import { ReactComponent as ChartLine } from 'assets/chart-line-solid.svg';
import { ReactComponent as Microchip } from 'assets/microchip-solid.svg';
import { ReactComponent as Wrench } from 'assets/wrench-solid.svg';
import { ReactComponent as HandPaper } from 'assets/hand-paper-regular.svg';
import { ReactComponent as Clipboard } from 'assets/clipboard-list-solid.svg';
import { ReactComponent as Config } from 'assets/bars-progress-solid.svg';
import { ReactComponent as Clock } from 'assets/clock-solid.svg';
import { ReactComponent as Cog } from 'assets/cog-solid.svg';
import { HeaderM, HeaderS } from 'components/atoms/Typography/Typography';
import { useReplayStore } from 'reducers/replayStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import useSidebar from 'hooks/useSidebar';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import {
  APPLICATION_SETTINGS,
  CHOOSE_GRIPS,
  CONFIG_TEMPLATES,
  DEVICE_HISTORY,
  GRAPH,
  GRIPS_CONFIGURATION,
  PROSTHESIS_SETTINGS,
  SERVICE_MENU
} from 'consts/routes';

const Wrapper = styled.nav`
  width: 100%;

  a {
    text-decoration: none;
  }

  z-index: 1;
`;

const List = styled.ul`
  display: grid;
  grid-auto-flow: row;
`;

const InnerItem = styled.div`
  display: flex;
  margin: 0 0 0 28px;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 0 0 0 28px;
  }
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  padding: 5px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 7px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 10px 0;
  }

  &.active {
    background-color: ${({ theme }) => theme.colorSecondary};
  }
`;

const StyledIcon = css`
  width: 10px;
  fill: white;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 20px;
  }
`;

const StyledHandPaper = styled(HandPaper)`
  ${StyledIcon}
`;

const StyledChartLine = styled(ChartLine)`
  ${StyledIcon}
`;

const StyledHandRock = styled(HandRock)`
  ${StyledIcon}
`;

const StyledMicrochip = styled(Microchip)`
  ${StyledIcon}
`;

const StyledWrench = styled(Wrench)`
  ${StyledIcon}
`;

const StyledClipboard = styled(Clipboard)`
  ${StyledIcon}
`;

const StyledConfig = styled(Config)`
  ${StyledIcon}
`;

const StyledClock = styled(Clock)`
  ${StyledIcon}
`;

const ItemText = styled.p`
  ${HeaderS};
  margin-left: 15px;
`;

const HeaderItem = styled(Item)`
  cursor: auto;
`;

const HeaderItemText = styled.p`
  ${HeaderM};
  color: #fff;
  cursor: auto;
`;

const StyledCog = styled(Cog)`
  ${StyledIcon}
`;

const Navbar = () => {
  const { t } = useTranslation();
  const { deviceConnected, serial } = useDeviceInfoStore((state) => ({
    deviceConnected: state.connected,
    serial: state.serial
  }));
  const liveSessionEnabled = useLiveConfiguratorStore((state) => state.enabled);
  const replayIsEnabled = useReplayStore((state) => state.enabled);
  const { handleFirmwareNavbar, handleServiceMenuNavbar } = useSidebar();

  return (
    <Wrapper>
      <List>
        <HeaderItem>
          <InnerItem>{serial && <HeaderItemText>Serial: {serial}</HeaderItemText>}</InnerItem>
        </HeaderItem>
        <Item
          as={NavLink}
          to={CHOOSE_GRIPS}
          activeClassName='active'
          data-tour-general='sidebar-grips'>
          <InnerItem>
            <StyledHandPaper />
            <ItemText>{t('navbar.choose_grips')}</ItemText>
          </InnerItem>
        </Item>
        <Item as={NavLink} to={GRAPH} activeClassName='active' data-tour-general='sidebar-emg'>
          <InnerItem>
            <StyledChartLine />
            <ItemText>
              {replayIsEnabled ? t('navbar.emg_playback') : t('navbar.emg_settings')}
            </ItemText>
          </InnerItem>
        </Item>
        <Item
          as={NavLink}
          to={GRIPS_CONFIGURATION}
          activeClassName='active'
          data-tour-general='sidebar-grips-config'>
          <InnerItem>
            <StyledHandRock />
            <ItemText>{t('navbar.grips_configuration')}</ItemText>
          </InnerItem>
        </Item>
        <Item
          as={NavLink}
          to={PROSTHESIS_SETTINGS}
          activeClassName='active'
          data-tour-general='sidebar-settings'>
          <InnerItem>
            <StyledClipboard />
            <ItemText>{t('navbar.prosthesis_settings')}</ItemText>
          </InnerItem>
        </Item>
        <Item
          as={NavLink}
          to={DEVICE_HISTORY}
          activeClassName='active'
          data-tour-general='sidebar-settings'>
          <InnerItem>
            <StyledClock />
            <ItemText>History log</ItemText>
          </InnerItem>
        </Item>
        <Item
          as={NavLink}
          to={CONFIG_TEMPLATES}
          activeClassName='active'
          data-tour-general='sidebar-settings'>
          <InnerItem>
            <StyledConfig />
            <ItemText>Config templates</ItemText>
          </InnerItem>
        </Item>
        <Item
          data-tour-general='sidebar-firmware'
          onClick={() => handleFirmwareNavbar(deviceConnected, liveSessionEnabled)}>
          <InnerItem>
            <StyledMicrochip />
            <ItemText>{t('navbar.update_firmware')}</ItemText>
          </InnerItem>
        </Item>
        {!liveSessionEnabled && (
          <Item
            as={NavLink}
            to={SERVICE_MENU}
            onClick={(e) => handleServiceMenuNavbar(deviceConnected, e)}
            activeClassName='active'>
            <InnerItem>
              <StyledWrench />
              <ItemText>{t('navbar.service_menu')}</ItemText>
            </InnerItem>
          </Item>
        )}
        <Item to={APPLICATION_SETTINGS} as={NavLink}>
          <InnerItem>
            <StyledCog />
            <ItemText>{t('settings.app_settings')}</ItemText>
          </InnerItem>
        </Item>
      </List>
    </Wrapper>
  );
};

export default Navbar;
