/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */
import sha256 from 'sha256';
import { numericalFirmwareVersion, parseBluetoothFirmware } from 'utils/funcs';
import { getBootloaderVersion, sendFwPartWithResponse } from './bluetoothFunctions';
import BluetoothWebController from './bluetoothWeb';
import { Commands, kFWPartSize, BootloaderStates } from '../bluetooth/Bluetooth/Defines';
import { delay, stringToUint8 } from '../bluetooth/Bluetooth/Utilities';

const blWebController = new BluetoothWebController();

class BootloaderController {
  static _instance: any;
  bootloaderStatus: BootloaderStates = BootloaderStates.incative;
  parts: number[][] = [];
  currentPart: number = 0;
  maxParts: number = 0;
  isListeningBootloaderStatus: boolean = false;
  bootloaderStatusAbortController: AbortController = new AbortController();

  constructor() {
    if (BootloaderController._instance) {
      return BootloaderController._instance;
    }
    BootloaderController._instance = this;
  }

  getMaxParts() {
    return this.maxParts;
  }

  async updateBootloaderParts(bytes) {
    const bytesUint8 = new Uint8Array(bytes);
    const numberOfParts = Math.ceil(bytesUint8.length / 119);
    this.maxParts = numberOfParts;
    const payloads: number[][] = [];
    for (let i = 0; i < numberOfParts; i += 1) {
      let element: any = [];
      element.push(...bytesUint8.slice(i * kFWPartSize, (i + 1) * kFWPartSize));
      if (i === numberOfParts - 1) {
        if (element.length < kFWPartSize) {
          const zeroArray = new Array(kFWPartSize - element.length).fill(0);
          element = [...element, ...zeroArray];
        }
      }
      payloads.push(element);
    }
    this.parts = payloads;
  }

  async initiateBootloader() {
    if (blWebController.telemetryEnabled) {
      await blWebController.telemetryOff();
    }
    await blWebController.writeWeb(Commands.kEnterBootloaderMode, [1]);
  }

  async checkBootloaderVersion() {
    if (blWebController.telemetryEnabled) {
      await blWebController.telemetryOff();
    }
    await blWebController.writeWeb(Commands.kEnterBootloaderMode, [1]);
    await this.waitForBootloaderStatus([BootloaderStates.waiting]);
    const bootloaderVersion = await getBootloaderVersion();
    const bootloaderVersionSanitized = numericalFirmwareVersion(
      parseBluetoothFirmware(bootloaderVersion.map((item) => String.fromCharCode(item)).join(''))
    );

    return bootloaderVersionSanitized;
  }

  async sendSHA() {
    const entierFirmware = this.parts.flat();
    const hashString = sha256(entierFirmware);
    const sha256Bytes: number[] = [];
    for (const element of hashString) {
      sha256Bytes.push(stringToUint8(element, 0));
    }
    await blWebController.writeWeb(Commands.kFirmwareChecksum, sha256Bytes);
  }

  notifyUpdateProgress() {
    const event = new CustomEvent(`bootloaderProgressUpdate`, {
      detail: this.currentPart
    });
    window.dispatchEvent(event);
  }

  listenBootloaderStatus() {
    this.bootloaderStatusAbortController = new AbortController();
    if (!this.isListeningBootloaderStatus) {
      window.addEventListener(
        `received${Commands.kBootloaderStatus}`,
        (data: any) => {
          console.log('RESOLVED BOOTLOADER', data.detail);
          this.bootloaderStatus = data.detail?.[0].payload?.[0];
        },
        { signal: this.bootloaderStatusAbortController.signal }
      );
      this.isListeningBootloaderStatus = true;
    }
  }

  abortBootloaderStatusListening() {
    this.bootloaderStatusAbortController.abort();
    this.isListeningBootloaderStatus = false;
  }

  async waitForBootloaderStatus(
    awaitedBootloaderStates: Array<BootloaderStates>,
    notificationsStopped = false
  ) {
    let maxDepth = 10000;
    let delayTime = 5;
    let counter = 0;

    if (notificationsStopped) {
      maxDepth = 100;
      delayTime = 1000;
    }

    while (
      !awaitedBootloaderStates.includes(this.bootloaderStatus) &&
      this.bootloaderStatus !== undefined &&
      counter < maxDepth &&
      blWebController.connected
    ) {
      if (notificationsStopped) {
        blWebController.queryResponseCommand(
          Commands.kQueryBootloaderStatus,
          [],
          Commands.kBootloaderStatus
        );
      }
      await delay(delayTime);
      counter += 1;
    }

    while (
      !awaitedBootloaderStates.includes(this.bootloaderStatus) &&
      this.bootloaderStatus !== undefined &&
      counter < maxDepth &&
      blWebController.connected
    ) {
      blWebController.queryResponseCommand(
        Commands.kQueryBootloaderStatus,
        [],
        Commands.kBootloaderStatus
      );
      await delay(delayTime);
      counter += 1;
    }

    return this.bootloaderStatus;
  }

  async updateFirmware() {
    this.currentPart = 0;
    if (this.parts.length > 0 && blWebController.connected) {
      let batchProgress = 0;
      const partsInBatch = 10;
      await this.initiateBootloader();
      await this.waitForBootloaderStatus([BootloaderStates.waiting]);
      if (this.bootloaderStatus === BootloaderStates.waiting) {
        for (let i = 0; i < this.parts.length; i += 1) {
          if (batchProgress === partsInBatch) {
            batchProgress = 0;
            await delay(50);
          }
          this.currentPart += 1;
          batchProgress += 1;
          const sentPart = [i, ...this.parts[i]];
          await blWebController.writeWeb(Commands.kPartOfFWImage, sentPart);
          console.log('SENDING PART', sentPart, this.bootloaderStatus);
          this.notifyUpdateProgress();
          await delay(30);
        }
        await this.sendSHA();
        await this.waitForBootloaderStatus([BootloaderStates.error, BootloaderStates.complete]);
      }
    }
    return this.bootloaderStatus;
  }

  async sendPart(initial) {
    if (initial < 0) {
      return null;
    }

    const maxDepth = 100;
    let depth = 0;

    const sentPart = [initial, ...this.parts[initial]];
    let partResponse = null;

    while (
      (partResponse?.[0] === 1 || partResponse === null) &&
      depth < maxDepth &&
      blWebController.connected
    ) {
      partResponse = await sendFwPartWithResponse(sentPart);
      depth += 1;
    }

    if (depth >= maxDepth) return null;

    return partResponse;
  }

  async updateFirmwareNew() {
    this.currentPart = 0;
    if (this.parts.length > 0 && blWebController.connected) {
      let batchProgress = 0;
      const partsInBatch = 10;
      await this.initiateBootloader();
      await this.waitForBootloaderStatus([BootloaderStates.waiting]);
      await blWebController.writeWeb(Commands.kInitializeFwTransfer, [1]);
      await this.waitForBootloaderStatus([BootloaderStates.transfer], true);
      if (this.bootloaderStatus === BootloaderStates.transfer) {
        for (let i = 0; i < this.parts.length; i += 1) {
          if (batchProgress === partsInBatch) {
            batchProgress = 0;
            await delay(50);
          }
          this.currentPart += 1;
          batchProgress += 1;
          const partResponse = await this.sendPart(i);
          if (partResponse === null) {
            return BootloaderStates.error;
          }
          this.notifyUpdateProgress();
        }
        await this.sendSHA();
        await this.waitForBootloaderStatus([BootloaderStates.error, BootloaderStates.complete]);
      }
    }
    return this.bootloaderStatus;
  }
}

export default BootloaderController;
