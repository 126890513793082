/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum Grips {
  kGripRestOpp = 0,
  kGripPower = 1,
  kGripTripodClosed = 2,
  kGripTrigger = 3,
  kGripPrecisionOpen = 4,
  kGripRestNopp = 5,
  kGripHook = 6,
  kGripKey = 7,
  kGripMouse = 8,
  kGripFingerPoint = 9,
  kGripCamera = 10,
  kGripTripodOpen = 11,
  kGripPrecisionClosed = 12,
  kGripFingerPointOpen = 13,
  kGripCounting = 14,
  kGripTypeUnknown = 255
}
