import React, { useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Undo } from 'assets/undo-solid.svg';
import { ReactComponent as Restore } from 'assets/restore.svg';
import IconDescribed, { IconSidebarModes } from 'components/atoms/IconDescribed/IconDescribed';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getScrollbarWidth } from 'utils/funcs';
import useSidebar from 'hooks/useSidebar';
import { IconsContainer, SavedContainer, StyledSaved } from './styled';

type IconsSidebarProps = {
  undoFunc?: () => void;
  unsaved?: boolean;
  mode?: IconSidebarModes;
};

const IconsSidebar = ({
  undoFunc = () => true,
  unsaved = false,
  mode = IconSidebarModes.standard
}: IconsSidebarProps) => {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const { width, height } = useWindowDimensions();
  const { handleSave, handleRestoreRecent, ticketCanBeSent, canShowSend } = useSidebar();

  useEffect(() => {
    const viewPort = document.querySelector('body')!;
    const viewPortHeight = viewPort.getBoundingClientRect().height;

    const refHeight = ref.current.getBoundingClientRect().height;

    ref.current.style.top = `${viewPortHeight / 2 - refHeight / 2}px`;
    ref.current.style.right = `${getScrollbarWidth() - 1}px`;
  }, [width, height]);

  return (
    <IconsContainer mode={mode} ref={ref}>
      {canShowSend && ticketCanBeSent && (
        <>
          <ReactTooltip id='save' data-tour-general='save' place='left' type='info' effect='solid'>
            <span>{t('utils.send')}</span>
          </ReactTooltip>
          <SavedContainer data-tip data-for='save'>
            <IconDescribed
              description='Send to prosthesis'
              onClick={() => handleSave(unsaved)}
              mode={mode}>
              <StyledSaved unsaved={unsaved} />
            </IconDescribed>
          </SavedContainer>
        </>
      )}
      <>
        <ReactTooltip id='restore' place='left' type='info' effect='solid'>
          <span>Restore recent config</span>
        </ReactTooltip>
        <IconDescribed
          onClick={handleRestoreRecent}
          description='Restore recent config'
          data-tip
          data-for='restore'
          mode={mode}>
          <Restore />
        </IconDescribed>
      </>
      {undoFunc && (
        <>
          <ReactTooltip id='undo' place='left' type='info' effect='solid'>
            <span>{t('utils.undo')}</span>
          </ReactTooltip>
          <IconDescribed onClick={undoFunc} description='Undo' data-tip data-for='undo' mode={mode}>
            <Undo />
          </IconDescribed>
        </>
      )}
    </IconsContainer>
  );
};

export default IconsSidebar;
