/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { useConfigStore } from 'reducers/configStore';
import { oneSpeedEntry } from 'consts/deviceConfig/deviceConfig.types';
import { initialState } from 'reducers/helpers/initialState';
import { SliderLabelWrapper } from 'components/molecules/Sliders/styled';
import { MultiSlider } from '../../../components/molecules/Sliders/Sliders';

const OneSpeed = ({
  addHistoryProsthesis,
  speed,
  disable
}: {
  addHistoryProsthesis: any;
  speed: oneSpeedEntry;
  disable: boolean;
}) => {
  const [oneSpeedLocal, setOneSpeedLocal] = useState<any>(initialState.config.oneSpeed);
  const { setConfigProperty } = useConfigStore((state) => ({
    setConfigProperty: state.setConfigProperty
  }));

  const handleOneSpeedSlider = (oneSpeedValue) => {
    addHistoryProsthesis(setConfigProperty('oneSpeed', [oneSpeedValue]));
  };

  useEffect(() => {
    if (speed) {
      setOneSpeedLocal(speed);
    }
  }, [speed]);

  const OneSpeed = useMemo(
    () => (
      <MultiSlider
        header='One speed settings'
        disabled={disable}
        sliderValues={oneSpeedLocal}
        limits={[{ min: 50, max: 100 }]}
        handleChange={(...args) =>
          setOneSpeedLocal((prev) => {
            const localCopy = [...prev];
            localCopy[0] = args[1];
            return localCopy;
          })
        }
        labelSliders={[
          <SliderLabelWrapper>
            <p>Speed: {oneSpeedLocal?.[0]}%</p>
          </SliderLabelWrapper>
        ]}
        handleOnAfterChange={(...args) => handleOneSpeedSlider(args[1])}
        description='Sets speed of fingers in one speed mode'
        key='One speed slider'
      />
    ),
    [speed, oneSpeedLocal, disable]
  );

  return OneSpeed;
};

export default OneSpeed;
