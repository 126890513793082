/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, DialogContent } from '@mui/material';
import { TextS } from 'components/atoms/Typography/Typography';
import ModalBase from './ModalBase';
import ConfirmationLoader from '../../../layouts/ConfirmationLoader/ConfirmationLoader';
import Loader from '../../atoms/Loader/Loader';

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 20px;
`;

const InnerWrapper = styled.div`
    width: 550px;
`;

const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ParagraphBold = styled.p`
    ${TextS};
    margin-bottom: 20px;
`;

type AutoLogoutModalProps = {
  resetAction: () => void;
  logoutAction: () => void;
  remainingTimeInSeconds: number;
  isLoading: boolean;
};

let remainingTimeInterval: ReturnType<typeof setInterval> | null = null;

const AutoLogoutModal = ({
                           resetAction,
                           logoutAction,
                           isLoading = false,
                           remainingTimeInSeconds = 10
                         }: AutoLogoutModalProps) => {
  const [autoLogoutRemainingTime, setAutoLogoutRemainingTime] = useState(remainingTimeInSeconds);
  useEffect(() => {
    if (autoLogoutRemainingTime <= 0) {
      if (remainingTimeInterval) clearInterval(remainingTimeInterval);
      setAutoLogoutRemainingTime(0);
      logoutAction();
    }
  }, [autoLogoutRemainingTime]);

  useEffect(() => {
    remainingTimeInterval = setInterval(() => {
      setAutoLogoutRemainingTime((prev) => prev - 1);
    }, 1000);

    return () => {
      if (remainingTimeInterval) clearInterval(remainingTimeInterval);
    };
  }, []);
  return (
    <ModalBase handleClick={resetAction} header='Are you there?'>
      <DialogContent>
        {isLoading ? (
          <InnerWrapper>
            <LoaderWrapper>
              <Loader />
              <ParagraphBold>Logout in progress...</ParagraphBold>
            </LoaderWrapper>
          </InnerWrapper>
        ) : (
          <InnerWrapper>
            <ParagraphBold>
              {`Are you still active? We will log you out in ${autoLogoutRemainingTime} seconds due to inactivity.`}
            </ParagraphBold>
            <ButtonsWrapper>
              <Button color='primary' onClick={resetAction}>
                Yes, I&apos;m here
              </Button>
              <Button color='error' onClick={logoutAction}>
                Log me out
              </Button>
            </ButtonsWrapper>
          </InnerWrapper>
        )}

      </DialogContent>
    </ModalBase>
  );
};

export default AutoLogoutModal;
