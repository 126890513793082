import TelemetryController from 'bluetooth-handler/telemetryController';
import { emgColors } from 'theme/emgColors/emgColors';
import TelemetryParser from 'utils/TelemetryParser';

const telemetryParser = new TelemetryParser();

export const parseTelemetryData = (fixedUpdate = true) => {
  const newData = telemetryParser.parseTelemetry(TelemetryController.newestTelemetryData, 500);
  const newEmgExtension = fixedUpdate ? newData[0] : TelemetryController.telemetryData[0];
  const newEmgFlexion = fixedUpdate ? newData[1] : TelemetryController.telemetryData[1];
  if (newEmgExtension && newEmgFlexion) {
    const emgExtensionData = newEmgExtension.map((point, i) => [i, point]);
    const emgFlexionData = newEmgFlexion.map((point, i) => [i, point]);

    const emgExtensionOptions = {
      values: emgExtensionData,
      label: 'EMG extension',
      color: emgColors.emgExtension
    };
    const emgFlexionOptions = {
      values: emgFlexionData,
      label: 'EMG flexion',
      color: emgColors.emgFlexion
    };

    return { emgExtensionOptions, emgFlexionOptions };
  }

  return null;
};

export const updateAllGraphs = (graphsContainers: { container: any; updateFunc: Function }[]) => {
  graphsContainers.forEach((element) => {
    if (element.container.current) {
      element.updateFunc();
    }
  });
};
