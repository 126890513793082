/* eslint-disable prefer-destructuring */
import React, { useState, useMemo, useEffect } from 'react';
import { initialState } from 'reducers/helpers/initialState';
import { holdOpenEntry } from 'consts/deviceConfig/deviceConfig.types';
import { SetConfigPropertyType } from 'reducers/configStore';
import { parseNumber } from '../ProsthesisSettingsComponent';
import { MultiSlider } from '../../../components/molecules/Sliders/Sliders';

const HoldOpen = ({
  addHistoryProsthesis,
  holdOpen,
  disable,
  setConfigProperty
}: {
  addHistoryProsthesis: any;
  holdOpen: holdOpenEntry;
  disable: boolean;
  setConfigProperty: SetConfigPropertyType;
}) => {
  const [holdOpenLocal, setHoldOpenLocal] = useState<any>(initialState.config.holdOpen);
  const [holdOpenShort, holdOpenLong] = holdOpen;

  const handleHoldOpenSliders = (value, sliderNumber) => {
    const short = sliderNumber === 0;
    let newHoldOpenLong = holdOpenLong;
    let newHoldOpenShort = holdOpenShort;
    if (short && value > holdOpenLong) {
      newHoldOpenLong = value;
    }
    if (!short && value < holdOpenShort) {
      newHoldOpenShort = value;
    }
    if (short) {
      addHistoryProsthesis(setConfigProperty('holdOpen', [value, newHoldOpenLong]));
    } else {
      addHistoryProsthesis(setConfigProperty('holdOpen', [newHoldOpenShort, value]));
    }
  };

  useEffect(() => {
    if (holdOpen) {
      setHoldOpenLocal(holdOpen);
    }
  }, [holdOpen]);

  const HoldOpen = useMemo(
    () => (
      // @ts-ignore
      <MultiSlider
        header='Hold-open timings'
        data-tour='hold'
        sliderValues={holdOpenLocal}
        disabled={disable}
        limits={[
          { min: 250, max: 5000 },
          { min: 300, max: 5000 }
        ]}
        handleChange={(...args) =>
          setHoldOpenLocal((prev) => {
            const localCopy = [...prev];
            localCopy[args[3]] = args[1];
            return localCopy;
          })
        }
        labelSliders={[
          <p>Primary hold open time: {parseNumber(holdOpenLocal?.[0])} s</p>,
          <p>Secondary hold open time: {parseNumber(holdOpenLocal?.[1])} s</p>
        ]}
        handleOnAfterChange={(...args) => handleHoldOpenSliders(args[1], args[2])}
        description='Sets the short and long hold open times for grip switching'
      />
    ),
    [holdOpen, holdOpenLocal, disable]
  );

  return HoldOpen;
};

export default HoldOpen;
