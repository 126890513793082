import { useQuery } from 'react-query';
import { useAuthStore } from 'reducers/authStore';
import { rolesByName } from 'utils/permissionUtils';
import { USER_QUERY_KEY, useUserMe } from './api/useUsers';
import { getUser } from '../api/users/users';
import { UserExtendOptions, UsersQueryParams } from '../api/users/users.types';

export default () => {
  const { token, mfa } = useAuthStore((state) => ({ token: state.token, mfa: state.mfa }));
  const enabled: boolean = Boolean(token) && !mfa.required;
  const { result: user, isLoading: isLoadingUserMe, isError, isFetched } = useUserMe(enabled);

  const queryParams: UsersQueryParams = {
    extend: [UserExtendOptions.roles, UserExtendOptions.locationCompany]
  };

  const userData = useQuery(
    [USER_QUERY_KEY, user?.id],
    () => getUser(user.id, { params: queryParams }),
    {
      cacheTime: Infinity,
      enabled: Boolean(user),
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0
    }
  );

  return {
    ...userData,
    me: user,
    rolesByName: rolesByName(userData?.data?.roles),
    isLoading: isLoadingUserMe || userData.isLoading,
    isFetched,
    isError
  };
};
