/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { LoadingButton } from '@mui/lab';
import { HeaderStandard } from 'components/atoms/Typography/Typography';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useMeetingStore } from 'reducers/meetingStore';
import useMeeting from 'hooks/useMeeting';
import { useUiStore } from 'reducers/uiStore';
import { FETCHING_STATES } from 'consts/consts';
import { ViewModes } from 'utils/ViewModes/viewModes';
import useLeaveConfigurator from 'hooks/useLeaveConfigurator';

const Wrapper = styled.div`
  min-height: 85px;
  display: flex;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px;
  border-bottom: 1px solid #dee2e6;
  gap: 16px;
  padding: 8px 0;
`;

const DropdownWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const ViewModesOptions = [
  { id: ViewModes.standard, name: 'Standard' },
  { id: ViewModes.minimal, name: 'Minimal' },
  { id: ViewModes.minimalEMG, name: 'Minimal + EMG' }
];

const ViewModesOptionsMeeting = [
  { id: ViewModes.minimal, name: 'Minimal' },
  { id: ViewModes.minimalEMG, name: 'Minimal + EMG' }
];

const TopBar = ({ ...props }) => {
  const { enabled: remoteSessionEnabled } = useLiveConfiguratorStore();
  const { amputeeId } = useDeviceInfoStore();
  const { viewMode, meetingStatus, setItemMeeting } = useMeetingStore();
  const { handleCloseMeeting, handleOpenMeeting } = useMeeting();
  const videoSessionState = useUiStore((state) => state.videoSessionState);
  const { handleRedirectAdp } = useLeaveConfigurator();

  const toggleMeeting = async () => {
    if (!meetingStatus) {
      handleOpenMeeting(Number(amputeeId));
    } else {
      handleCloseMeeting();
    }
  };

  const switchViewMode = (viewModeId: ViewModes) => {
    setItemMeeting('viewMode', viewModeId);
  };

  return (
    <Wrapper {...props}>
      <HeaderStandard>Zeus Configurator</HeaderStandard>
      <DropdownWrapper>
        <Button onClick={handleRedirectAdp} color='secondary'>
          Go to ADP
        </Button>
        {(remoteSessionEnabled || meetingStatus) && (
          <LoadingButton
            onClick={toggleMeeting}
            loading={videoSessionState === FETCHING_STATES.loading}>
            <span>{meetingStatus ? 'Stop Meeting' : 'Start meeting'}</span>
          </LoadingButton>
        )}
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id='selected-mode-select-label'>Choose view</InputLabel>
          <Select
            value={viewMode}
            labelId='selected-mode-select-label'
            id='selected-mode-select'
            label='Choose view'
            onChange={(e) => switchViewMode(e.target.value as ViewModes)}>
            {(meetingStatus ? ViewModesOptionsMeeting : ViewModesOptions).map((option) => (
              <MenuItem key={`selected-mode-select_${option.id}`} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DropdownWrapper>
    </Wrapper>
  );
};

export default TopBar;
