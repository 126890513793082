/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { Tooltip } from '@mui/material';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import { FETCHING_STATES } from 'consts/consts';
import useProcedureReply from 'hooks/useProcedure';
import TelemetryController from 'bluetooth-handler/telemetryController';
import { useUiStore } from 'reducers/uiStore';
import { OptionsWithSlider } from 'components/molecules/Sliders/Sliders';
import {
  CalibrationWrapper,
  ProcedureReplyWrapper,
  ProcedureRow,
  ProcedureStatusBox,
  ProcedureTable,
  ProcedureTableWrapper,
  Header2
} from 'components/molecules/CalibrationProcedure/styled';

const FingersSpeed = ({
  sessionEnabled,
  sessionApi,
  channel,
  handleProcedure,
  disableCalibration
}) => {
  const procedureFingersSpeed = useProcedureReply(ProcedureTypes.calibrateFingersSpeed);
  const procedureState = useUiStore((state) => state.procedureState);

  const handleFingerSpeedCalibration = async () => {
    const voltage = await TelemetryController.checkVoltage();

    if (!voltage) {
      toast.error(`Couldn't read voltage value, please reconnect the device`);
      return;
    }

    if (Number(voltage) < 750) {
      toast.error(
        `Current battery voltage (${(voltage / 100).toFixed(
          2
        )}V) is too low for fingers speed calibration, please charge the battery to 7.50V and repeat the procedure`
      );
      return;
    }

    handleProcedure({
      procedureNumber: ProcedureTypes.calibrateFingersSpeed,
      ...(sessionEnabled && {
        liveSession: {
          clinicianUUID: sessionApi?.clinician_uuid,
          channelName: channel?.name
        }
      })
    });
  };

  return (
    <>
      <OptionsWithSlider
        header='Fingers speed calibration'
        toggleOptions={['On', 'Off']}
        disabled
        toggleValue={0}
        sliderValue={0}
        handleSliderChange={() => false}
        sliderLimits={{ min: 0, max: 0 }}
        sliderLabel={<></>}
        handleToggleChange={() => false}
        handleSliderOnAfterChange={() => false}
        description='Used to set correct fingers speed after repair and firmware update'
        hideInput
      />
      <CalibrationWrapper>
        {procedureFingersSpeed && (
          <ProcedureReplyWrapper>
            <Header2 as='h4'>Procedure results</Header2>
            <ProcedureTableWrapper>
              <ProcedureTable>
                <ProcedureRow>
                  <td aria-label='Procedure information'>&nbsp;</td>
                  <td>Thumb</td>
                  <td>Index</td>
                  <td>Middle</td>
                  <td>Ring</td>
                  <td>Pinky</td>
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Status</td>
                  {procedureFingersSpeed.status!.map((status, index) => (
                    <ProcedureStatusBox colSpan={1} status={procedureFingersSpeed.status![index]}>
                      {status === 1 ? 'OK' : 'NOT OK'}
                    </ProcedureStatusBox>
                  ))}
                </ProcedureRow>
                <ProcedureRow>
                  <td colSpan={1}>Speed</td>
                  {procedureFingersSpeed.speeds!.map((current) => (
                    <td colSpan={1}>{current}</td>
                  ))}
                </ProcedureRow>
              </ProcedureTable>
            </ProcedureTableWrapper>
          </ProcedureReplyWrapper>
        )}
        <Tooltip title={disableCalibration ? 'Device not connected' : null}>
          <span>
            <LoadingButton
              sx={{ width: 250 }}
              disabled={disableCalibration}
              loading={procedureState === FETCHING_STATES.loading}
              onClick={handleFingerSpeedCalibration}>
              <span>Calibration procedure</span>
            </LoadingButton>
          </span>
        </Tooltip>
      </CalibrationWrapper>
    </>
  );
};

export default FingersSpeed;
