import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { HeaderXL } from 'components/atoms/Typography/Typography';
import GripsTester from 'components/molecules/GripTester/GripTester';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { useConfigStore } from 'reducers/configStore';
import I2cCommunicationCheck from 'components/organisms/Procedures/I2cCommunicationCheck';
import { SettingsContainer } from 'views/ProsthesisSettings/styled';
import CheckMovementRangeFingerClosingCombinedProcedure from 'components/organisms/Procedures/CheckMovementRangeFingerClosingCombined';
import ServicePositions from 'components/molecules/ServicePositions/ServicePositions';
import OptionElement from 'components/molecules/OptionElement/OptionElement';
import { getFwVersionSelector } from 'reducers/helpers/selectors';

export const Header1 = styled.h2`
  ${HeaderXL};
  color: ${({ theme }) => theme.colorPrimary};
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const ServiceMenu = () => {
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const deviceInfoState = useDeviceInfoStore((state) => state);
  const { handleProcedure } = useConfigStore((state) => ({
    handleProcedure: state.handleProcedure
  }));
  const {
    channel,
    enabled: sessionEnabled,
    sessionApi
  } = useLiveConfiguratorStore((state) => ({
    channel: state.channel,
    enabled: state.enabled,
    sessionApi: state.sessionApi
  }));
  const { push } = useHistory();

  useEffect(() => {
    if (!deviceConnected) {
      push('/choose-grips');
    }
  }, [deviceConnected]);

  const disableCalibration = !deviceConnected || sessionEnabled;

  return (
    <Wrapper>
      <SettingsContainer>
        <OptionElement
          name='Service positions'
          description='Used to set positions of the fingers during repair and servicing.'>
          <ServicePositions />
        </OptionElement>
        <OptionElement
          name='Grip changer'
          description='Tool to manually select grip.'
          style={{ maxWidth: '400px' }}>
          <GripsTester />
        </OptionElement>
        <div>
          <I2cCommunicationCheck
            channel={channel}
            sessionEnabled={sessionEnabled}
            sessionApi={sessionApi}
            disableCalibration={disableCalibration}
            handleProcedure={handleProcedure}
          />
        </div>
        {getFwVersionSelector(deviceInfoState) >= 230 && (
          <div>
            <CheckMovementRangeFingerClosingCombinedProcedure
              channel={channel}
              sessionEnabled={sessionEnabled}
              sessionApi={sessionApi}
              disableCalibration={disableCalibration}
              handleProcedure={handleProcedure}
            />
          </div>
        )}
      </SettingsContainer>
    </Wrapper>
  );
};

export default ServiceMenu;
