/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-escape */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthentication, useRegisterUser } from 'hooks/api/useAuthentication';
import AetherLogo from 'assets/aether_new_black.png';
import { useAuthStore } from 'reducers/authStore';
import useBluetooth from 'hooks/bluetooth/useConnect';
import { useHistory } from 'react-router-dom';
import { useUiStore } from 'reducers/uiStore';
import { FETCHING_STATES } from 'consts/consts';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { CHECK_DEVICE_SERIAL_QUERY_KEY, useAddDeviceWithCode } from 'hooks/api/useDevice';
import useUserData from 'hooks/useUserData';
import { useConfigStore } from 'reducers/configStore';
import RegisterLayout from 'layouts/RegisterLayout/RegisterLayout';
import { checkDeviceSerial } from 'api/device/device';
import { userHasPermissions } from 'utils/permissionUtils';
import { isNetworkError } from 'utils/notifications';
import { useQueryClient } from 'react-query';
import BluetoothWebController from 'bluetooth-handler/bluetoothWeb';
import { RoleEnum } from 'api/users/users.types';
import {
  FORM_STEPS,
  StepConnection,
  StepVerifyCode,
  StepLogin,
  StepLoginSuccess,
  StepMfaVerify,
  StepRegister,
  StepWelcome,
  StepNotAllowed
} from './Steps';
import { codeSchema, loginSchema, registerSchema } from '../utils';
import { Logo, LogoLink } from '../styled';
import { RegisterFooter } from '../Components/Components';

interface IFormInput {
  email: string;
  password: string;
}

interface IFormInputRegister {
  email: string;
  password: string;
  name: string;
  companyName: string;
}

const bluetoothWeb = new BluetoothWebController();

const Register = () => {
  const [formStep, setFormStep] = useState(FORM_STEPS.welcome);
  const { me, rolesByName } = useUserData();
  const [showConnectionError, setShowConnectionError] = useState(false);
  const { bluetoothConnectRegister } = useBluetooth();
  const { connectionState } = useUiStore((state) => ({
    connectionState: state.connectionState
  }));
  const {
    deviceConnected,
    serial,
    deviceId,
    setItemDeviceInfo,
    deviceCode,
    setDeviceCode,
    setDeviceCodeClaimed
  } = useDeviceInfoStore((state) => ({
    deviceConnected: state.connected,
    serial: state.serial,
    deviceId: state.deviceId,
    setItemDeviceInfo: state.setItemDeviceInfo,
    deviceCode: state.deviceCode,
    setDeviceCode: state.setDeviceCode,
    setDeviceCodeClaimed: state.setDeviceCodeClaimed
  }));
  const {
    isLoading: isLoadingLogin,
    login,
    verifyCode,
    reSendCode,
    errorLogin,
    errorVerifyCode
  } = useAuthentication();

  const {
    mutateAsync: registerUser,
    isLoading: isLoadingRegister,
    error: errorRegister
  } = useRegisterUser();
  const { token, mfa, registeredUser, setItemAuth } = useAuthStore((state) => ({
    token: state.token,
    mfa: state.mfa,
    setItemAuth: state.setItemAuth,
    registeredUser: state.registeredUser
  }));
  const verificationMethod = mfa?.channel;
  const history = useHistory();
  const { disconnectDevice, getInitialConfigAPI } = useConfigStore((state) => ({
    disconnectDevice: state.disconnectDevice,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const [deviceExists, setDeviceExists] = useState<Boolean | undefined>(undefined);
  const {
    mutateAsync: addDeviceWithCode,
    error: errorAddDevice,
    isLoading: isLoadingAddDeviceWithCode
  } = useAddDeviceWithCode();
  const [loadingConnection, setLoadingConnection] = useState(false);
  const { control: loginFormControl, handleSubmit: handleSubmitLogin } = useForm({
    resolver: yupResolver(loginSchema)
  });
  const { control: verifyFormControl, handleSubmit: handleSubmitVerifyCode } = useForm({
    resolver: yupResolver(codeSchema)
  });
  const [checkSerialError, setCheckSerialError] = useState(undefined);
  const {
    control: registerFormControl,
    handleSubmit: handleSubmitRegister,
    watch
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      retypePassword: '',
      'privacy-policy': false,
      'data-processing-policy': false,
      name: '',
      companyName: ''
    },
    resolver: yupResolver(registerSchema)
  });
  const passwordWatch = watch('password');
  const verificationCodeValid = deviceCode.code?.length === 6;
  const connectionErrors = showConnectionError || deviceExists === false || checkSerialError;
  const userHasAccess = userHasPermissions(
    [RoleEnum.clinician, RoleEnum.clinicAdmin, RoleEnum.clinicianSupport],
    Object.keys(rolesByName)?.length > 0 ? rolesByName : undefined
  );
  const queryClient = useQueryClient();

  const handleDisconnect = async (resetFlow = true) => {
    if (deviceConnected) await disconnectDevice();
    bluetoothWeb.forgetDevice();
    if (resetFlow) setFormStep(FORM_STEPS.welcome);
  };

  const startConfiguration = async () => {
    try {
      setLoadingConnection(true);

      setCheckSerialError(undefined);
      setDeviceExists(undefined);
      setShowConnectionError(false);
      handleDisconnect(false);
      setFormStep(FORM_STEPS.connection);

      const deviceData = await bluetoothConnectRegister();

      if (!deviceData) {
        setShowConnectionError(true);
        return;
      }

      const serialStatus = await queryClient.fetchQuery([CHECK_DEVICE_SERIAL_QUERY_KEY], () =>
        checkDeviceSerial(deviceData?.serialNumber)
      );

      const deviceExistsInAdp = serialStatus?.status;

      setDeviceExists(deviceExistsInAdp);

      if (!deviceExistsInAdp) {
        await disconnectDevice();
        bluetoothWeb.forgetDevice();
      }
    } catch (e: any) {
      if (isNetworkError(e)) {
        setCheckSerialError(e);
        await disconnectDevice();
        bluetoothWeb.forgetDevice();
        return;
      }
      setShowConnectionError(true);
    } finally {
      setLoadingConnection(false);
    }
  };

  const onSubmitLogin: SubmitHandler<IFormInput> = async (data) => {
    await login(data);
  };

  const onSubmitRegister: SubmitHandler<IFormInputRegister> = async (data) => {
    if (!serial || !deviceCode.code) return;
    const response = await registerUser({
      password: data.password,
      email: data.email,
      device_serial: serial,
      company_name: data.companyName,
      device_code: deviceCode.code,
      name: data.name
    });
    setItemAuth('registeredUser', true);
    setDeviceCode(null);
    setDeviceCodeClaimed(true);
    setItemDeviceInfo('deviceId', response.devices_as_clinician[0].id);
    setFormStep(FORM_STEPS.verifyCode);
    await login({ email: data.email, password: data.password });
  };

  const redirectConfigurator = async () => {
    await getInitialConfigAPI();
    history.push(`/device?deviceId=${deviceId}&connect=1`);
  };

  const handleAddDevice = async (deviceAccess: any = false) => {
    if (!deviceCode.code || !serial) return;

    let deviceId;

    if (deviceAccess) {
      deviceId = deviceAccess.id;
    } else {
      const response = await addDeviceWithCode({ code: deviceCode.code, serial });
      deviceId = response.id;
    }
    setDeviceCodeClaimed(true);
    setDeviceCode(null);
    setItemDeviceInfo('deviceId', deviceId);
  };

  const redirectAdp = () => {
    window.location.href = `${process.env.REACT_APP_ADMIN_PANEL_URL}/dashboard`;
  };

  const onSubmit2Fa = async (data: any) => {
    if (data.code && verificationMethod) {
      verifyCode({
        channel: verificationMethod,
        code: data.code,
        remember_mfa_session: data.rememberDevice
      });
    }
  };

  const handleChangeCode = (e: string) => {
    setDeviceCode(e.toUpperCase());
  };

  useEffect(() => {
    if (token && me && userHasAccess === false && mfa.required === false) {
      setFormStep(FORM_STEPS.notAllowed);
      return;
    }

    if (
      formStep === FORM_STEPS.connection &&
      (connectionState === FETCHING_STATES.loading || connectionErrors)
    ) {
      return;
    }

    // Handle device disconnection
    if (!deviceConnected && connectionState !== FETCHING_STATES.loading) {
      setFormStep(FORM_STEPS.welcome);
      return;
    }

    // Handle MFA
    if (mfa.required === true) {
      setFormStep(FORM_STEPS.mfaVerify);
      return;
    }

    // Handle authenticated user
    if (token) {
      // Handle logged in user
      if (verificationCodeValid && !deviceCode.claimed) {
        setFormStep(FORM_STEPS.verifyCode);
        return;
      }

      // Handle registered user
      if (deviceCode.claimed) {
        setFormStep(FORM_STEPS.loginSuccess);
      }
    }
  }, [
    deviceConnected,
    mfa,
    token,
    deviceExists,
    formStep,
    verificationCodeValid,
    deviceCode.claimed,
    userHasAccess
  ]);

  return (
    <RegisterLayout>
      <Logo>
        <LogoLink href={`${process.env.REACT_APP_ADMIN_PANEL_URL}`}>
          <img src={AetherLogo} alt='Aether logo' />
        </LogoLink>
      </Logo>
      {formStep === FORM_STEPS.welcome && <StepWelcome startConfiguration={startConfiguration} />}
      {formStep === FORM_STEPS.connection && (
        <StepConnection
          connectionState={connectionState}
          showConnectionError={showConnectionError}
          deviceConnected={deviceConnected}
          serial={serial}
          setFormStep={setFormStep}
          startConfiguration={startConfiguration}
          onChangeCode={handleChangeCode}
          valueCode={deviceCode.code}
          deviceExistsInAdp={deviceExists}
          verificationCodeValid={verificationCodeValid}
          handleDisconnect={handleDisconnect}
          isLoading={loadingConnection}
          checkSerialError={checkSerialError}
        />
      )}
      {formStep === FORM_STEPS.login && (
        <StepLogin
          handleSubmit={handleSubmitLogin}
          onSubmit={onSubmitLogin}
          serial={serial}
          control={loginFormControl}
          loadingLogin={isLoadingLogin}
          setFormStep={setFormStep}
          handleDisconnect={handleDisconnect}
          errorLogin={errorLogin}
        />
      )}
      {formStep === FORM_STEPS.register && (
        <StepRegister
          handleSubmit={handleSubmitRegister}
          onSubmit={onSubmitRegister}
          serial={serial}
          control={registerFormControl}
          loadingLogin={isLoadingRegister || isLoadingLogin}
          setFormStep={setFormStep}
          password={passwordWatch}
          valueCode={deviceCode.code}
          onChangeCode={handleChangeCode}
          verificationCodeValid={verificationCodeValid}
          handleDisconnect={handleDisconnect}
          errorRegister={errorRegister}
        />
      )}
      {formStep === FORM_STEPS.mfaVerify && (
        <StepMfaVerify
          handleSubmit={handleSubmitVerifyCode}
          onSubmit={onSubmit2Fa}
          loadingVerify={isLoadingLogin}
          control={verifyFormControl}
          verificationMethod={mfa.channel}
          resendCode={() => reSendCode()}
          errorVerifyCode={errorVerifyCode}
        />
      )}
      {formStep === FORM_STEPS.loginSuccess && (
        <StepLoginSuccess
          redirectConfigurator={redirectConfigurator}
          isRegister={registeredUser}
          redirectAdp={redirectAdp}
        />
      )}
      {formStep === FORM_STEPS.verifyCode && (
        <StepVerifyCode
          errorAddDevice={errorAddDevice}
          handleAddDevice={handleAddDevice}
          valueCode={deviceCode.code}
          onChangeCode={handleChangeCode}
          isLoading={isLoadingAddDeviceWithCode || isLoadingLogin}
          verificationCodeValid={verificationCodeValid}
          serialNumber={serial}
        />
      )}
      {formStep === FORM_STEPS.notAllowed && <StepNotAllowed redirectAdp={redirectAdp} />}
      <RegisterFooter token={token} loading={connectionState === FETCHING_STATES.loading} />
    </RegisterLayout>
  );
};

export default Register;
