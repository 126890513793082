/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-bitwise */
/* eslint-disable no-await-in-loop */
import {
  timeoutCommand,
  timeoutCommandCustom,
  timeoutCommandFirmware,
  timeoutCommandLite,
  timeoutCommandLiteCustom
} from 'utils/funcs';
import {
  autoGraspEntry,
  batteryBeepEntry,
  buzzingVolumeSettingsEntry,
  coContractionTimingsEntry,
  controlConfigEntry,
  DeviceConfigTemplate,
  emgGainsEntry,
  emgSpikeEntry,
  emgThresholdsEntry,
  fingerStrengthEntry,
  freezeModeEmgEntry,
  generalHandSettingsEntry,
  gripPairsConfigEntry,
  gripSequentialConfigEntry,
  gripsPositionsEntry,
  holdOpenEntry,
  intervalEntry,
  pulseTimingsEntry,
  rtcDate,
  singleElectrodeModeEntry,
  singleElectrodeModeSettingsEntry,
  singleElectrodeSettingsAlternatingEntry,
  softGripEntry,
  strengthIndexEntry,
  userFeedbackType,
  freezeModeEmgSettingsEntry,
  inputDevicesEntry,
  followingGripEntry,
  emergencyBatterySettingsEntry,
  oneSpeedEntry
} from 'consts/deviceConfig/deviceConfig.types';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import { CALIBRATION_PROCEDURE_TIMEOUT } from 'consts/consts';
import { FREEZE_MODE_ON_DUAL, FREEZE_MODE_ON_SINGLE } from 'consts/deviceConfig/freezeMode';
import BluetoothWebController from 'bluetooth-handler/bluetoothWeb';
import {
  ControlModes,
  GripSwitchingModes,
  InputDevices,
  InputSites,
  SpeedControlStrategies
} from '../bluetooth/Bluetooth/Control';
import { BootloaderStates, Commands, QueryCommands } from '../bluetooth/Bluetooth/Defines';
import { Grips } from '../bluetooth/Bluetooth/Grips';

const bluetooth = new BluetoothWebController();

const errors = {
  badConnection: 'Connection is corrupted, restart the prosthesis and try connecting again',
  badFingersConfig: 'Fingers config could not be sent, check prosthesis connection',
  badInitialConfig: 'Initial config could not be loaded, try again',
  badGripConfig: 'Grip config could not be sent, check prosthesis connection',
  badControlConfig: 'Control config could not be sent, check prosthesis connection',
  bootloaderNotResponding: 'Connection failed, bootloader not responding'
};

export type statusTypeFreeze = [0] | [1];

export const getBootloaderStatusTimed = async (): Promise<BootloaderStates> => {
  const [{ payload: bootloaderStatus }] = (await timeoutCommand(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryBootloaderStatus,
        [],
        Commands.kBootloaderStatus
      ),
    errors.bootloaderNotResponding
  )) || [{ payload: false }];
  return bootloaderStatus;
};

export const getBootloaderStatus = async (): Promise<BootloaderStates> => {
  const [{ payload: bootloaderStatus }] = (await timeoutCommandLiteCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryBootloaderStatus,
        [],
        Commands.kBootloaderStatus
      ),
    300,
    1
  )) || [{ payload: null }];
  return bootloaderStatus?.[0];
};

export const getEmgThresholds = async () => {
  const [{ payload: emgThresholds }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryEmgThresholds, [], Commands.kSetEmgThresholds)
  )) || [{ payload: false }];
  return emgThresholds;
};

export const getGripsPairsConfig = async () => {
  const [{ payload: gripPairsConfig }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryGripPairs, [], Commands.kSetGripPairs)
  )) || [[{ payload: false }]];
  return gripPairsConfig;
};

export const getGripsSequentialConfig = async () => {
  const [{ payload: gripSequentialConfig }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryGripsSequence, [], Commands.kSetGripsSequence)
  )) || [{ payload: false }];
  return gripSequentialConfig;
};

export const getGripPositions = async (grip: Grips): Promise<number[]> => {
  const [{ payload: gripPositions }]: [{ payload: number[] }] = (await timeoutCommandLiteCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryInitialGripPositions,
        [grip],
        Commands.kInitialGripPositions
      ),
    250,
    5
  )) || [{ payload: false }];
  return gripPositions;
};

export const getGripLimitPositions = async (grip: Grips): Promise<number[]> => {
  const [{ payload: gripLimitPositions }] = (await timeoutCommandLiteCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kQueryFingerLimits,
        [grip],
        Commands.kSetFingerLimits
      ),
    250,
    5
  )) || [{ payload: false }];
  return gripLimitPositions;
};

export const getControlConfig = async () => {
  const [{ payload: controlConfig }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryControlConfig, [], Commands.kControlConfig)
  )) || [{ payload: false }];
  return controlConfig;
};

export const getAutoGrasp = async () => {
  const [{ payload: autoGrasp }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryAutograspSettings,
      [],
      Commands.kAutoGraspSettings
    )
  )) || [{ payload: false }];
  return autoGrasp;
};

export const getHoldOpen = async () => {
  const [{ payload: holdOpen }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryHoldOpenGripSwitchingSettings,
      [],
      Commands.kSetHoldOpenGripSwitchingSettings
    )
  )) || [{ payload: false }];
  return holdOpen;
};

export const getSoftGrip = async () => {
  const [{ payload: softGrip }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryCurrentControlStrategy,
      [],
      Commands.kSetCurrentControlStrategy
    )
  )) || [{ payload: false }];
  return softGrip;
};

export const getUserFeedbackType = async () => {
  const [{ payload: userFeedbackType }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryUserFeedbackType,
      [],
      Commands.kSetUserFeedbackType
    )
  )) || [{ payload: false }];
  return userFeedbackType;
};

export const getBatteryBeep = async () => {
  const [{ payload: batteryBeep }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryLowBatteryCheckerSettings, 0],
      Commands.kSetLowBatterySettings
    )
  )) || [{ payload: false }];
  return batteryBeep;
};

export const getSingleElectrodeMode = async () => {
  const [{ payload: singleElectrodeMode }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQuerySingleElectrodeSelectedMode, 0],
      Commands.kSingleElectrodeSelectedMode
    )
  )) || [{ payload: false }];
  return singleElectrodeMode;
};

export const getBuzzingVolumeSettings = async () => {
  const [{ payload: buzzingVolumeSettings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryBuzzingVolumeSettings, 0],
      Commands.kBuzzingVolumeSettings
    )
  )) || [{ payload: false }];
  return buzzingVolumeSettings;
};

export const getSingleElectrodeModeSettings = async () => {
  const [{ payload: singleElectrodeModeSettings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQuerySingleElectrodeModeFastOpenSlowCloseSettings, 0],
      Commands.kSingleElectrodeModeFastOpenSlowCloseSettings
    )
  )) || [{ payload: false }];

  return singleElectrodeModeSettings;
};

export const getEmgSpike = async () => {
  const [{ payload: emgSpike }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryEMGSpikesCancelling,
      [],
      Commands.kSetEMGSpikesCancellingSettings
    )
  )) || [{ payload: false }];
  return emgSpike;
};

export const getFingerStrength = async () => {
  const [{ payload: fingerStrength }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kFrameTypeQueryFingerCurrentThreshold,
      [1],
      Commands.kFrameTypeFingerCurrentThreshold
    )
  )) || [{ payload: false }];
  return fingerStrength;
};

const getTelemetryData = async () => {
  const data = await bluetooth.queryResponseCommand(
    Commands.kStartOrStopTransmittingTelemetryData,
    [1],
    Commands.kTelemetryData
  );
  return data;
};

export const queryTelemetryOnce = async () => {
  if (bluetooth.connected) {
    const data = await getTelemetryData();
    const telemetryObject = {
      fingers: {
        0: {},
        1: {},
        2: {},
        3: {},
        4: {}
      },
      isThumbOpposed: !(data[0].payload[22] & 0x02)
    };
    for (let i = 0; i < 5; i += 1) {
      telemetryObject.fingers[i].encoderTicks = data[0].payload[i * 4];
    }
    return telemetryObject;
  }
  return false;
};

export const telemetryEnabled = async (telemetryStatus: boolean) => {
  let telemetryStatusNum: number = 0;
  if (telemetryStatus === true) {
    telemetryStatusNum = 1;
  }

  await bluetooth.writeWeb(Commands.kStartOrStopTransmittingTelemetryData, [telemetryStatusNum]);
};

export const enterBootloaderMode = async (bootloaderMode: BootloaderStates) => {
  await bluetooth.writeWeb(Commands.kEnterBootloaderMode, [bootloaderMode]);
};

export const getSerialNumber = async () => {
  const [{ payload: serialNumber }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQuerySerialNumber, [], Commands.kSerialNumberReply)
  )) || [{ payload: false }];
  return serialNumber;
};

export const getBootloaderVersion = async () => {
  const [{ payload: bootloaderVersion }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryBootloaderVersion,
      [],
      Commands.kBootloaderVersion
    )
  )) || [{ payload: false }];
  return bootloaderVersion;
};

export const getInterval = async () => {
  const [{ payload: interval }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryLongCoContractionTime,
      [],
      Commands.kSetIntervalBetweenCocontractionPulses
    )
  )) || [{ payload: false }];
  return interval;
};

export const getFirmwareVersion = async () => {
  const [{ payload: firmwareVersion }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryFirmwareAndAppVersions,
      [],
      Commands.kFirmwareAndAppVersions
    )
  )) || [{ payload: false }];
  return firmwareVersion;
};

export const getDevicesInfo = async () => {
  const [{ payload: devicesInfo }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryDevicesInfo, 0],
      Commands.kReplyDevicesInfo
    )
  )) || [{ payload: false }];
  return devicesInfo;
};

export const getFreezeMode = async () => {
  const [{ payload: freezeMode }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kFrameTypeQueryFreezeMode,
      [],
      Commands.kFrameTypeFreezeMode
    )
  )) || [{ payload: false }];
  return freezeMode;
};

export const waitRequestAction = async () => {
  const [{ payload: action }] = (await timeoutCommandCustom(
    () => bluetooth.queryResponseCommand(null, null, Commands.kRequestAction),
    10000
  )) || [{ payload: false }];
  return action;
};

export const getEmgGains = async () => {
  const [{ payload: emgGains }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQuerySignalGains, [], Commands.kSetSignalGains)
  )) || [{ payload: false }];
  return emgGains;
};

export const getPulseTimings = async () => {
  const [{ payload: pulseTimings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryPulseTimings, [], Commands.kPulseTimings)
  )) || [{ payload: false }];
  return pulseTimings;
};

export const getCoContractionTimings = async () => {
  const [{ payload: coContractionTimings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryCoContractionTimings,
      [],
      Commands.kCoContractionTimings
    )
  )) || [{ payload: false }];
  return coContractionTimings;
};

export const getFreezeModeEmg = async () => {
  const [{ payload: freezeModeEmg }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryFreezeSignalDetectorSettings, 0],
      Commands.kFreezeSignalDetectorSettings
    )
  )) || [{ payload: false }];
  return freezeModeEmg;
};

export const getGeneralHandSettings = async () => {
  const [{ payload: generalHandSettings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryGeneralHandSettings, 0],
      Commands.kGeneralHandSettings
    )
  )) || [{ payload: false }];
  return generalHandSettings ? generalHandSettings.slice(0, 3) : generalHandSettings;
};

export const getFreezeModeEmgSettings = async () => {
  const [{ payload: freezeModeEmgSettingsClose }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryLongDoubleStageHoldSettings, 0],
      Commands.kLongDoubleStageHoldSettings
    )
  )) || [{ payload: false }];
  await delay(100);
  await bluetooth.queryResponseCommand(
    Commands.kQueryForwarder,
    [QueryCommands.kQueryLongDoubleStageHoldSettings, 1],
    Commands.kLongDoubleStageHoldSettings
  );
  await delay(100);
  const [{ payload: freezeModeEmgSettingsOpen }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryLongDoubleStageHoldSettings, 1],
      Commands.kLongDoubleStageHoldSettings
    )
  )) || [{ payload: false }];
  if (freezeModeEmgSettingsOpen) {
    const thresholdClosing = freezeModeEmgSettingsClose[1];
    const thresholdOpening = freezeModeEmgSettingsOpen[1];
    const stage1HoldOpenTime = freezeModeEmgSettingsClose[2];
    const stage2HoldOpenTime = freezeModeEmgSettingsClose[3];
    return [thresholdOpening, thresholdClosing, stage1HoldOpenTime, stage2HoldOpenTime];
  }
  return false;
};

export const getFollowingGrip = async () => {
  const [{ payload: followingGrip }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(Commands.kQueryGripFollowing, [], Commands.kSetGripFollowing)
  )) || [{ payload: false }];
  return followingGrip;
};

export const getEmergencyBatterySettings = async () => {
  const [{ payload: emergencyBatterySettings }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryUsageModeLowBatteryEmergencySettings, 0],
      Commands.kLowBatteryEmergencySettings
    )
  )) || [{ payload: false }];
  return emergencyBatterySettings;
};

export const getEmergencyBatteryModeStatus = async () => {
  const [{ payload: emergencyBatteryModeState }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryLowBatteryEmergencyState, 0],
      Commands.kLowBatteryEmergencyState
    )
  )) || [{ payload: false }];
  return emergencyBatteryModeState;
};

export const getOneSpeed = async () => {
  const [{ payload: oneSpeed }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQueryOneSpeedValue, 0],
      Commands.kOneSpeedValue
    )
  )) || [{ payload: false }];
  return oneSpeed;
};

export const getSingleElectrodeSettingsAlternating = async () => {
  const [{ payload: singleElectrodeSettingsAlternating }] = (await timeoutCommandLite(() =>
    bluetooth.queryResponseCommand(
      Commands.kQueryForwarder,
      [QueryCommands.kQuerySingleElectrodeSettings, 0],
      Commands.kSetSingleElectrodeSettings
    )
  )) || [{ payload: false }];
  return singleElectrodeSettingsAlternating;
};

export const sendFwPartWithResponse = async (fwPart) => {
  const [{ payload: fwPartStatus }] = (await timeoutCommandFirmware(() =>
    bluetooth.queryResponseCommand(Commands.kPartOfFWImage, fwPart, Commands.kFwPartStatus)
  )) || [{ payload: false }];
  return fwPartStatus;
};

export const runProcedure = async (procedureNumber: ProcedureTypes, input): Promise<number[]> => {
  const [{ payload: procedureReply }] = (await timeoutCommandCustom(
    () =>
      bluetooth.queryResponseCommand(
        Commands.kFrameTypeRunProcedure,
        [procedureNumber, ...input],
        Commands.kFrameTypeProcedureReply
      ),
    CALIBRATION_PROCEDURE_TIMEOUT
  )) || [{ payload: false }];
  return procedureReply;
};

export const postCurrentGrip = async (grip: Grips) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kSetGripMode, [grip]));
};

export const postControlConfig = async (controlConfig: controlConfigEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kControlConfig, controlConfig)
  );
};

export const postGripPairs = async (gripPairsConfig: gripPairsConfigEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetGripPairs, gripPairsConfig)
  );
};

export const postGripSpeed = async (gripSpeed: number) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kSetGripSpeed, [gripSpeed]));
};

export const postRtcTime = async (rtcDate: rtcDate) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kSetRtcTime, rtcDate));
};

export const postGripSequentialConfig = async (gripSequentialConfig: gripSequentialConfigEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetGripsSequence, gripSequentialConfig)
  );
};

export const postInitialGripPositions = async (grip: Grips, initialGripPositions) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kInitialGripPositions, [grip, ...initialGripPositions])
  );
};

export const postFingerLimits = async (grip: Grips, fingerLimits) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetFingerLimits, [grip, ...fingerLimits])
  );
};

export const postInputSite = async (inputSite: InputSites[]) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kInputSite, inputSite));
};

export const postInputOption = async (inputDevice: InputDevices[]) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kInputOption, inputDevice));
};

export const postControlMode = async (controlMode: ControlModes[]) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kSetControlMode, controlMode));
};

export const postSpeedControlStrategy = async (speedControlStrategy: SpeedControlStrategies[]) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetSpeedControlStrategy, speedControlStrategy)
  );
};

export const postGripSwitchingModes = async (gripSwitchingMode: GripSwitchingModes[]) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetGripSwitchingMode, gripSwitchingMode)
  );
};

export const postEmgSpikeCancelling = async (emgSpike: emgSpikeEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetEMGSpikesCancellingSettings, emgSpike)
  );
};

export const postAutoGrasp = async (autoGrasp: autoGraspEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kAutoGraspSettings, autoGrasp)
  );
};

export const postBatteryBeep = async (batteryBeep: batteryBeepEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetLowBatterySettings, batteryBeep)
  );
};

export const postHoldOpen = async (holdOpen: holdOpenEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetHoldOpenGripSwitchingSettings, holdOpen)
  );
};

export const postSoftGrip = async (softGrip: softGripEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetCurrentControlStrategy, softGrip)
  );
};

export const postEmgThresholds = async (emgThresholds: emgThresholdsEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetEmgThresholds, emgThresholds)
  );
};

export const postInterval = async (interval: intervalEntry) => {
  await bluetooth.writeWeb(Commands.kSetIntervalBetweenCocontractionPulses, interval);
};

export const postFingerStrength = async (fingerStrength: fingerStrengthEntry) => {
  const strengthIndex: strengthIndexEntry = fingerStrength[1];
  const thumbStrengthMap: any = new Map([
    [50, 100],
    [100, 150],
    [150, 200],
    [300, 350],
    [500, 550],
    [700, 750]
  ]);
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kFrameTypeFingerCurrentThreshold, [
      0,
      thumbStrengthMap.get(strengthIndex)
    ])
  );
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kFrameTypeFingerCurrentThreshold, [1, strengthIndex])
  );
};

export const postJointTargetPosition = async (finger, position) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetJointTargetPosition, [finger, position])
  );
};

export const postFreezeMode = async (status: statusTypeFreeze) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kFrameTypeFreezeMode, status));
};

export const postEmgGains = async (emgGains: number[]) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kSetSignalGains, emgGains));
};

export const postGripFollowing = async (gripFollowing: followingGripEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSetGripFollowing, gripFollowing)
  );
};

export const postAppReceivedProcedure = async (receivedProcedureNumber) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kAppReceivedFrameCallback, [receivedProcedureNumber])
  );
};

export const postPulseTimings = async (pulseTimings: pulseTimingsEntry) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kPulseTimings, pulseTimings));
};

export const postSingleElectrodeMode = async (electrodeMode: singleElectrodeModeEntry) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSingleElectrodeSelectedMode, electrodeMode)
  );
};

export const postSingleElectrodeSettings = async (
  singleElectrodeSettings: singleElectrodeModeSettingsEntry
) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(
      Commands.kSingleElectrodeModeFastOpenSlowCloseSettings,
      singleElectrodeSettings
    )
  );
};

export const postSingleElectrodeSettingsAlternating = async (
  singleElectrodeSettingsAlternating: singleElectrodeSettingsAlternatingEntry
) => {
  await bluetooth.writeWeb(
    Commands.kSetSingleElectrodeSettings,
    singleElectrodeSettingsAlternating
  );
};

export const postCoContractionTimings = async (coContractionTimings: number[]) => {
  await bluetooth.writeWeb(Commands.kCoContractionTimings, coContractionTimings);
};

export const postSerialNumber = async (serialNumber: string) => {
  const asciiSerial: number[] = [];
  for (let index = 0; index < serialNumber.length; index += 1) {
    asciiSerial.push(serialNumber[index].charCodeAt(0));
  }
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSerialNumberReply, asciiSerial)
  );
};

export const postGeneralHandSettings = async (generalHandSettings: generalHandSettingsEntry) => {
  // Fill 23 unused bytes
  const fill = new Array(23).fill(0);
  const generalHandSettingsPayload = [...generalHandSettings, ...fill];
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kGeneralHandSettings, generalHandSettingsPayload)
  );
};

export const postFreezeModeEmg = async (
  freezeModeEmg: number[],
  fwVersion,
  inputSite: [InputSites],
  inputDevice: [InputDevices]
) => {
  let newFreezeModeEmg: number[] = [...freezeModeEmg];

  // Set Single channel freeze mode values for FW at least 2.0.0 and inputSite single
  const singleOn =
    fwVersion?.[1] >= 2 && inputSite?.[0] === InputSites.kSingleElectrode && freezeModeEmg[2] !== 0;

  if (singleOn) {
    newFreezeModeEmg = [
      freezeModeEmg[0],
      freezeModeEmg[1],
      FREEZE_MODE_ON_SINGLE[0],
      FREEZE_MODE_ON_SINGLE[1]
    ];
  }

  // Set Dual channel freeze mode values for FW below 2.0.0
  const firmwareBelow2_0_0 = fwVersion?.[1] < 2 && freezeModeEmg[2] !== 0;

  if (firmwareBelow2_0_0) {
    newFreezeModeEmg = [
      freezeModeEmg[0],
      freezeModeEmg[1],
      FREEZE_MODE_ON_DUAL[0],
      FREEZE_MODE_ON_DUAL[1]
    ];
  }

  // Set Dual channel freeze mode values for COAPT
  const coaptOn =
    inputDevice && inputDevice?.[0] === InputDevices.kInputOptionPatRec && freezeModeEmg[2] !== 0;

  if (coaptOn) {
    newFreezeModeEmg = [
      freezeModeEmg[0],
      freezeModeEmg[1],
      FREEZE_MODE_ON_DUAL[0],
      FREEZE_MODE_ON_DUAL[1]
    ];
  }

  // Fill 7 unused bytes
  const freezeModeSettings = [...newFreezeModeEmg, 0, 0, 0, 0, 0, 0, 0];
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kFreezeSignalDetectorSettings, freezeModeSettings)
  );
};

export const postActiveMode = async (activeMode: number) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSelectActiveMode, [activeMode])
  );
};

export const postCommunicateMode = async (communicateMode: number) => {
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kSelectUsageMode, [communicateMode])
  );
};

export const postSaveSettings = async () => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kSaveSettings, []));
};

export const postBuzzingVolumeSettings = async (buzzingSettings: buzzingVolumeSettingsEntry) => {
  // Fill 10 unused bytes
  const buzzingVolume = [...buzzingSettings, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kBuzzingVolumeSettings, buzzingVolume)
  );
};

export const postUserFeedbackType = async (type: userFeedbackType) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kSetUserFeedbackType, type));
};

export const postFreezeModeEmgSettings = async (
  freezeModeEmgSettings: freezeModeEmgSettingsEntry
) => {
  const thresholdOpening = freezeModeEmgSettings[0];
  const thresholdClosing = freezeModeEmgSettings[1];
  const stage1HoldOpenTime = freezeModeEmgSettings[2];
  const stage2HoldOpenTime = freezeModeEmgSettings[3];

  const filler = [0, 0, 0, 0, 0, 0];

  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kLongDoubleStageHoldSettings, [
      1,
      thresholdOpening,
      stage1HoldOpenTime,
      stage2HoldOpenTime,
      ...filler
    ])
  );
  await delay(100);
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kLongDoubleStageHoldSettings, [
      0,
      thresholdClosing,
      stage1HoldOpenTime,
      stage2HoldOpenTime,
      ...filler
    ])
  );
  await delay(100);
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(Commands.kLongDoubleStageHoldSettings, [
      2,
      thresholdOpening,
      stage1HoldOpenTime,
      stage2HoldOpenTime,
      ...filler
    ])
  );
};

export const postEmergencyBatterySettings = async (
  emergencyBatterySettings: emergencyBatterySettingsEntry
) => {
  // Fill 10 unused bytes
  const emergencyBatterySettingsFilled = [...emergencyBatterySettings, 0, 0, 0, 0, 0, 0, 0];
  await timeoutCommandLite(() =>
    bluetooth.queryAcknowledge(
      Commands.kLowBatteryEmergencySettings,
      emergencyBatterySettingsFilled
    )
  );
};

export const postOneSpeed = async (oneSpeed: oneSpeedEntry) => {
  await timeoutCommandLite(() => bluetooth.queryAcknowledge(Commands.kOneSpeedValue, oneSpeed));
};

export const sendFingersConfigHelper = async (grip, valuesInitial, valuesLimit) => {
  await postInitialGripPositions(grip, valuesInitial);
  await postFingerLimits(grip, valuesLimit);
  const gripValuesSent = [...valuesInitial, ...valuesLimit];

  return { gripValuesSent };
};

export const sendAllFingersHelper = async (gripsPositions: gripsPositionsEntry) => {
  for (const grip in gripsPositions) {
    if (Object.prototype.hasOwnProperty.call(gripsPositions, grip)) {
      const gripPositions = gripsPositions[grip];
      await sendFingersConfigHelper(grip, gripPositions.initial, gripPositions.limit);
    }
  }
};

const sanitizeGripsSequential = (gripSequentialConfigToSend) => {
  const sanitizedGripPairConfig = [
    ...gripSequentialConfigToSend.slice(0, 5),
    255,
    ...gripSequentialConfigToSend.slice(6, 11),
    255
  ];
  return sanitizedGripPairConfig;
};

const gripsToDownload = [
  Grips.kGripPower,
  Grips.kGripHook,
  Grips.kGripFingerPoint,
  Grips.kGripMouse,
  Grips.kGripKey,
  Grips.kGripTrigger,
  Grips.kGripTripodClosed,
  Grips.kGripPrecisionOpen,
  Grips.kGripCamera,
  Grips.kGripRestOpp,
  Grips.kGripRestNopp,
  Grips.kGripPrecisionClosed,
  Grips.kGripTripodOpen,
  Grips.kGripFingerPointOpen,
  Grips.kGripCounting
];

const getGripsLimits = async () => {
  const gripsPositionsObject = {};
  let iterator = 0;
  for (const grip of gripsToDownload) {
    const gripPositions = await getGripPositions(grip);
    const gripLimitPositions = await getGripLimitPositions(grip);

    if (gripPositions && gripLimitPositions) {
      gripsPositionsObject[gripPositions[0]] = {
        initial: [
          gripPositions[1],
          gripPositions[2],
          gripPositions[3],
          gripPositions[4],
          gripPositions[5]
        ],
        limit: [
          gripLimitPositions[1],
          gripLimitPositions[2],
          gripLimitPositions[3],
          gripLimitPositions[4],
          gripLimitPositions[5]
        ]
      };
    }
    iterator += 1;
  }
  return gripsPositionsObject;
};

const ConfigToReceiveFunctionMapping = {
  gripSwitchingMode: async (...args: [...any]) => {
    const controlConfig = await getControlConfig();
    return [controlConfig[4]];
  },
  speedControlStrategy: async (...args: [...any]) => {
    const controlConfig = await getControlConfig();
    return [controlConfig[3]];
  },
  controlMode: async (...args: [...any]) => {
    const controlConfig = await getControlConfig();
    return [controlConfig[2]];
  },
  inputSite: async (...args: [...any]) => {
    const controlConfig = await getControlConfig();
    return [controlConfig[1]];
  },
  inputDevice: async (...args: [...any]) => {
    const controlConfig = await getControlConfig();
    return [controlConfig[0]];
  },
  gripsPositions: (...args: [...any]) => getGripsLimits(),
  fingerStrength: (...args: [...any]) => getFingerStrength(),
  generalHandSettings: (...args: [...any]) => getGeneralHandSettings(),
  batteryBeep: (...args: [...any]) => getBatteryBeep(),
  gripPairsConfig: (...args: [...any]) => getGripsPairsConfig(),
  gripSequentialConfig: async (...args: [...any]) => {
    const gripSequentialConfig = await getGripsSequentialConfig();
    let gripSequentialConfigSanitized;
    if (gripSequentialConfig) {
      gripSequentialConfigSanitized = [
        ...gripSequentialConfig.slice(0, 5),
        255,
        ...gripSequentialConfig.slice(6, 11),
        255
      ];
    }
    return gripSequentialConfigSanitized;
  },
  emgThresholds: (...args: [...any]) => getEmgThresholds(),
  autoGrasp: async (...args: [...any]) => {
    const autoGrasp = await getAutoGrasp();
    if (autoGrasp?.[1] > 100) autoGrasp[1] = 100;
    return autoGrasp;
  },
  emgSpike: (...args: [...any]) => getEmgSpike(),
  holdOpen: (...args: [...any]) => getHoldOpen(),
  softGrip: (...args: [...any]) => getSoftGrip(),
  emgGains: (...args: [...any]) => getEmgGains(),
  pulseTimings: (...args: [...any]) => getPulseTimings(),
  singleElectrodeMode: (...args: [...any]) => getSingleElectrodeMode(),
  singleElectrodeModeSettings: (...args: [...any]) => getSingleElectrodeModeSettings(),
  coContractionTimings: (...args: [...any]) => getCoContractionTimings(),
  freezeModeEmg: (...args: [...any]) => getFreezeModeEmg(),
  buzzingVolumeSettings: (...args: [...any]) => getBuzzingVolumeSettings(),
  userFeedbackType: (...args: [...any]) => getUserFeedbackType(),
  freezeModeEmgSettings: (...args: [...any]) => getFreezeModeEmgSettings(),
  followingGrip: (...args: [...any]) => getFollowingGrip(),
  emergencyBatterySettings: (...args: [...any]) => getEmergencyBatterySettings(),
  oneSpeed: (...args: [...any]) => getOneSpeed(),
  singleElectrodeSettingsAlternating: (...args: [...any]) => getSingleElectrodeSettingsAlternating()
};

interface configurationType {
  name: keyof DeviceConfigTemplate;
  arguments: [...any];
}

export const getDeviceConfigurations = async (
  configurations: Array<configurationType>
): Promise<{ [key in keyof DeviceConfigTemplate]: any }> => {
  const config: any = {};
  for (let index = 0; index < configurations.length; index += 1) {
    const propertyName = configurations[index].name;
    const propertyFunctionArguments = configurations[index].arguments;
    const configPropertyFunction = ConfigToReceiveFunctionMapping[propertyName];
    config[propertyName] = await configPropertyFunction(...propertyFunctionArguments);
  }
  return config;
};

export const ConfigToSendFunctionMapping = {
  gripPairsConfig: (gripPairsConfig: gripPairsConfigEntry) => postGripPairs(gripPairsConfig),
  inputSite: (inputSite) => postInputSite(inputSite),
  inputDevice: (inputDevice: inputDevicesEntry) => postInputOption(inputDevice),
  controlMode: (controlMode) => postControlMode(controlMode),
  speedControlStrategy: (speedControlStrategy) => postSpeedControlStrategy(speedControlStrategy),
  gripSwitchingMode: (gripSwitchingMode) => postGripSwitchingModes(gripSwitchingMode),
  gripSequentialConfig: (gripSequentialConfig: gripSequentialConfigEntry) =>
    postGripSequentialConfig(
      // @ts-ignore
      sanitizeGripsSequential(gripSequentialConfig)
    ),
  emgThresholds: (emgThresholds: emgThresholdsEntry) => postEmgThresholds(emgThresholds),
  gripsPositions: (gripsPositions: gripsPositionsEntry) => sendAllFingersHelper(gripsPositions),
  interval: (interval: intervalEntry) => postInterval(interval),
  fingerStrength: (fingerStrength: fingerStrengthEntry) => postFingerStrength(fingerStrength),
  autoGrasp: (autoGrasp: autoGraspEntry) => postAutoGrasp(autoGrasp),
  batteryBeep: (batteryBeep: batteryBeepEntry) => postBatteryBeep(batteryBeep),
  emgSpike: (emgSpike: emgSpikeEntry) => postEmgSpikeCancelling(emgSpike),
  holdOpen: (holdOpen: holdOpenEntry) => postHoldOpen(holdOpen),
  softGrip: (softGrip: softGripEntry) => postSoftGrip(softGrip),
  emgGains: (emgGains: emgGainsEntry) => postEmgGains(emgGains),
  pulseTimings: (pulseTimings: pulseTimingsEntry) => postPulseTimings(pulseTimings),
  singleElectrodeMode: (electrodeMode: singleElectrodeModeEntry) =>
    postSingleElectrodeMode(electrodeMode),
  singleElectrodeModeSettings: (singleElectrodeSettings: singleElectrodeModeSettingsEntry) =>
    postSingleElectrodeSettings(singleElectrodeSettings),
  coContractionTimings: (coContractionTimings: coContractionTimingsEntry) =>
    postCoContractionTimings(coContractionTimings),
  generalHandSettings: (generalHandSettings: generalHandSettingsEntry) =>
    postGeneralHandSettings(generalHandSettings),
  freezeModeEmg: (
    freezeModeEmg: freezeModeEmgEntry,
    fwVersion: number,
    inputSite: [InputSites],
    inputDevice: [InputDevices]
  ) => postFreezeModeEmg(freezeModeEmg, fwVersion, inputSite, inputDevice),
  buzzingVolumeSettings: (settings: buzzingVolumeSettingsEntry) =>
    postBuzzingVolumeSettings(settings),
  userFeedbackType: (type: userFeedbackType) => postUserFeedbackType(type),
  freezeModeEmgSettings: (type: freezeModeEmgSettingsEntry) => postFreezeModeEmgSettings(type),
  followingGrip: (type: followingGripEntry) => postGripFollowing(type),
  emergencyBatterySettings: (emergencyBatterySettings: emergencyBatterySettingsEntry) =>
    postEmergencyBatterySettings(emergencyBatterySettings),
  oneSpeed: (oneSpeed: oneSpeedEntry) => postOneSpeed(oneSpeed),
  singleElectrodeSettingsAlternating: (
    singleElectrodeModeSettingsAlternating: singleElectrodeSettingsAlternatingEntry
  ) => postSingleElectrodeSettingsAlternating(singleElectrodeModeSettingsAlternating)
};
