/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Types } from 'ably';
import TelemetryController from 'bluetooth-handler/telemetryController';
import { EMERGENCY_MODE_NOTIFICATION } from 'consts/notifications';
import { useConfigStore } from 'reducers/configStore';
import useModes from 'hooks/useModes';
import useRemoteSession from 'hooks/useRemoteSession';
import useMeeting from 'hooks/useMeeting';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { ablyClient } from 'utils/LiveConfigurator/AblyClient';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useUiStore } from 'reducers/uiStore';

const LiveConfigurator = ({ children, remoteSessionState }) => {
  const [mainChannel, setMainChannel] = useState<Types.RealtimeChannelPromise | null>();
  const [mainChannelSubscriptionsReady, setMainChannelSubscriptionsReady] = useState(false);
  const [additionalSubscriptionsReady, setAdditionalSubscriptionsReady] = useState(false);
  const [sessionStartCalled, setSessionStartCalled] = useState(false);
  const { enabled, channel: connection, sessionApi, isUnsaved } = remoteSessionState;
  const { handleChangeMode } = useModes();
  const { config, slotSelected, getInitialConfigAPI } = useConfigStore((state) => ({
    config: state.config,
    slotSelected: state.slotSelected,
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const { mainViewLoaded } = useUiStore((state) => ({ mainViewLoaded: state.mainViewLoaded }));
  const { handleCloseMeeting, handleOpenMeeting } = useMeeting();
  const { setItemLiveConfigurator } = useLiveConfiguratorStore((state) => ({
    setItemLiveConfigurator: state.setItemLiveConfigurator
  }));
  const { amputeeId } = useDeviceInfoStore((state) => ({
    amputeeId: state.amputeeId
  }));
  const { sendReady, sessionReady } = useRemoteSession();
  const readyToNotifyMobile =
    sessionReady &&
    amputeeId &&
    mainChannel &&
    mainViewLoaded &&
    mainChannelSubscriptionsReady &&
    additionalSubscriptionsReady;

  useEffect(() => {
    const prepareMainSubscribers = async () => {
      const main = ablyClient(sessionApi.clinician_uuid).channels.get(connection.name);

      setMainChannel(main);

      await main.attach();

      await main?.presence.subscribe('enter', (member) => {
        setItemLiveConfigurator('patientConnected', true);
      });

      await main?.presence.subscribe('leave', (member) => {
        setItemLiveConfigurator('patientConnected', false);
      });

      await main?.subscribe('emg', (message) => {
        if (message.data) {
          TelemetryController.parseTelemetry({ detail: [{ payload: message.data }] });
        }
      });

      await main?.subscribe('restart_web', (message) => {
        getInitialConfigAPI();
      });

      await main?.subscribe('close_meeting_web', (message) => {
        handleCloseMeeting(false);
      });

      await main.presence.enter();
      console.log('ENTER');
      setMainChannelSubscriptionsReady(true);
    };
    if (enabled && sessionApi?.clinician_uuid && !mainChannel) {
      prepareMainSubscribers();
    }
    return () => {
      if (mainChannel) {
        mainChannel.unsubscribe();
      }
    };
  }, [enabled, connection, sessionApi, mainChannel]);

  useEffect(() => {
    const prepareAdditionalSubscribers = async () => {
      await mainChannel?.subscribe('close_session_patient', () => {
        toast('Patient has closed the session', { icon: '⚠️' });
      });

      await mainChannel?.subscribe('query_mode_web', () => {
        handleChangeMode(slotSelected ?? config.modes[0].slot);
      });

      await mainChannel?.subscribe('emergency_mode', () => {
        toast(EMERGENCY_MODE_NOTIFICATION.message, EMERGENCY_MODE_NOTIFICATION.options);
      });

      setAdditionalSubscriptionsReady(true);
    };
    if (mainChannel) {
      prepareAdditionalSubscribers();
    }
    return () => {
      if (mainChannel) {
        mainChannel.unsubscribe('close_session_patient');
        mainChannel.unsubscribe('query_mode_web');
        mainChannel.unsubscribe('emergency_mode');
      }
    };
  }, [isUnsaved, mainChannel, slotSelected, config.modes]);

  useEffect(() => {
    const handleStartSession = async () => {
      if (sessionStartCalled) {
        toast.error('Session already started');
        return;
      }
      await sendReady();
      handleOpenMeeting(Number(amputeeId));
      setSessionStartCalled(true);
    };

    if (readyToNotifyMobile) handleStartSession();
  }, [readyToNotifyMobile]);

  return <>{children}</>;
};

export default LiveConfigurator;
