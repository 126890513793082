// Delay between signals is currently not used

export const singleElectrodeSettingsAlternatingPositions = {
  stateSwitchDelay: 0,
  maximumTimeBetweenPulses: 1,
  minimumTimeBetweenPulses: 2,
  minimumPulseTime: 3,
  maximumPulseTime: 4,
  delayBetweenSignals: 5
};

export const freezeModeEmgPositions = {
  closing: 0,
  opening: 1,
  detectorActivatingType: 2,
  detectorDeactivatingType: 3
};
