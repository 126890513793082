import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  controlModeMap,
  gripSwitchingMap,
  gripsGroupsOptionsMap,
  handSideMap,
  inputSiteMap,
  singleElectrodeModeMap,
  speedControlMap,
  thumbLengthMap,
  inputDeviceMap,
  allUserFeedbackTypeMap,
  aetherBatteryVoltageFunctions
} from 'utils/definesLocal';
import { StartOnStartup } from 'bluetooth/Bluetooth/Control';
import { StatusIndicator } from 'components/atoms/ConfigItem/ConfigItem';
import { singleElectrodeSettingsAlternatingPositions } from 'consts/deviceConfig/configPropertiesPositions';
import { singleElectrodeSettingsAlternatingNamingsMap } from 'consts/deviceConfig/configPropertiesNamings';

const parseNumber = (number: number) => Number(number / 1000).toFixed(2);

export const ConfigToValueFunctionMapping = new Proxy(
  {
    gripsPositions: (value) => <div>{value}</div>,
    gripPairsConfig: (gripPairsConfig, index) => (
      <ul style={{ listStyle: 'none' }}>
        {index === 0 ? (
          <>
            <li>
              <strong>Primary</strong>
              <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
                {gripPairsConfig.slice(0, 2).map((grip) => (
                  <li>{gripsGroupsOptionsMap.get(grip)}</li>
                ))}
              </ul>
            </li>
            <li>
              <strong>Secondary</strong>
              <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
                {gripPairsConfig.slice(2, 4).map((grip) => (
                  <li>{gripsGroupsOptionsMap.get(grip)}</li>
                ))}
              </ul>
            </li>
          </>
        ) : (
          <>
            <li>
              <strong>Primary</strong>
              <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
                {gripPairsConfig.slice(4, 6).map((grip) => (
                  <li>{gripsGroupsOptionsMap.get(grip)}</li>
                ))}
              </ul>
            </li>
            <li>
              <strong>Secondary</strong>
              <ul style={{ marginLeft: '6px', listStyle: 'none' }}>
                {gripPairsConfig.slice(6, 8).map((grip) => (
                  <li>{gripsGroupsOptionsMap.get(grip)}</li>
                ))}
              </ul>
            </li>
          </>
        )}
      </ul>
    ),
    inputSite: (inputSite) => inputSiteMap.get(inputSite),
    inputDevice: (inputDevice) => inputDeviceMap.get(inputDevice),
    controlMode: (controlMode) => controlModeMap.get(controlMode),
    speedControlStrategy: (speedControlStrategy) => speedControlMap.get(speedControlStrategy),
    gripSwitchingMode: (gripSwitchingMode) => gripSwitchingMap.get(gripSwitchingMode),
    gripSequentialConfig: (gripSequentialConfig, index) =>
      index === 0 ? (
        <ul style={{ listStyle: 'none' }}>
          {gripSequentialConfig.slice(0, 5).map((grip) => (
            <li>{gripsGroupsOptionsMap.get(grip)}</li>
          ))}
        </ul>
      ) : (
        <ul style={{ listStyle: 'none' }}>
          {gripSequentialConfig.slice(6, 11).map((grip) => (
            <li>{gripsGroupsOptionsMap.get(grip)}</li>
          ))}
        </ul>
      ),
    emgThresholds: (value) => <div>{value}%</div>,
    fingerStrength: (value) => <div>{value}</div>,
    autoGrasp: (value, index) =>
      index === 0 ? (
        <div>
          <StatusIndicator status={value} />
          {value === 1 ? 'On' : 'Off'}
        </div>
      ) : (
        <div>{value}</div>
      ),
    emgSpike: (value, index) =>
      index === 0 ? (
        <div>
          <StatusIndicator status={value} />
          {value === 1 ? 'On' : 'Off'}
        </div>
      ) : (
        <div>
          <ReactTooltip id={`emgSpike-${value}`} place='top' type='info' effect='solid'>
            <span>{value} ms</span>
          </ReactTooltip>
          <span data-for={`emgSpike-${value}`} data-tip>
            {parseNumber(value)} s
          </span>
        </div>
      ),
    holdOpen: (value) => (
      <div>
        <ReactTooltip id={`holdOpen-${value}`} place='top' type='info' effect='solid'>
          <span>{value} ms</span>
        </ReactTooltip>
        <span data-for={`holdOpen-${value}`} data-tip>
          {parseNumber(value)} s
        </span>
      </div>
    ),
    softGrip: (value) => (
      <div>
        <StatusIndicator status={value} />
        {value === 1 ? 'On' : 'Off'}
      </div>
    ),
    singleElectrodeMode: (value) => singleElectrodeModeMap.get(value),
    freezeModeEmg: (value, index) => {
      switch (index) {
        case 0:
          return <div>{value}</div>;
        case 1:
          return <div>{value}</div>;
        case 2:
          return (
            <div>
              <StatusIndicator status={value} />
              {value !== 0 ? 'Enabled' : 'Disabled'}
            </div>
          );
        default:
          return null;
      }
    },
    emgGains: (value) => <div>{value}%</div>,
    pulseTimings: (value) => (
      <div>
        <ReactTooltip id={`pulseTimings-${value}`} place='top' type='info' effect='solid'>
          <span>{value} ms</span>
        </ReactTooltip>
        <span data-for={`pulseTimings-${value}`} data-tip>
          {parseNumber(value)} s
        </span>
      </div>
    ),
    singleElectrodeModeSettings: (value) => (
      <div>
        <ReactTooltip
          id={`singleElectrodeModeSettings-${value}`}
          place='top'
          type='info'
          effect='solid'>
          <span>{value} ms</span>
        </ReactTooltip>
        <span data-for={`singleElectrodeModeSettings-${value}`} data-tip>
          {parseNumber(value)} s
        </span>
      </div>
    ),
    coContractionTimings: (value) => (
      <div>
        <ReactTooltip id={`coContractionTimings-${value}`} place='top' type='info' effect='solid'>
          <span>{value} ms</span>
        </ReactTooltip>
        <span data-for={`coContractionTimings-${value}`} data-tip>
          {parseNumber(value)} s
        </span>
      </div>
    ),
    batteryBeep: (value, index) => {
      switch (index) {
        case 0:
          return <div>{aetherBatteryVoltageFunctions.voltageToPercent(value)} %</div>;
        case 1:
          return (
            <div>
              <StatusIndicator status={value} />
              {value === 1 ? 'On' : 'Off'}
            </div>
          );
        default:
          return null;
      }
    },
    generalHandSettings: (value, index) => {
      switch (index) {
        case 0:
          return <div>{handSideMap.get(value)}</div>;
        case 1:
          return <div>{thumbLengthMap.get(value)}</div>;
        case 2:
          return (
            <div>
              <StatusIndicator status={value === StartOnStartup.kWaitForFirstCoContraction} />
              {value === StartOnStartup.kWaitForFirstCoContraction ? 'On' : 'Off'}
            </div>
          );
        default:
          return null;
      }
    },
    buzzingVolumeSettings: (value) => <div>{Math.round((value / 255) * 100)} %</div>,
    followingGrip: (value) => (
      <div>
        <StatusIndicator status={value} />
        {value === 1 ? 'On' : 'Off'}
      </div>
    ),
    freezeModeEmgSettings: (value, index) => {
      switch (index) {
        case 0:
          return <div>{value}</div>;
        case 1:
          return <div>{value}</div>;
        case 2:
          return <div>{value} ms</div>;
        case 3:
          return <div>{value} ms</div>;
        default:
          return null;
      }
    },
    userFeedbackType: (value) => <div>{allUserFeedbackTypeMap.get(value)}</div>,
    emergencyBatterySettings: (value, index) => {
      switch (index) {
        case 0:
          return (
            <div>
              <StatusIndicator status={value} />
              {value !== 0 ? 'On' : 'Off'}
            </div>
          );
        case 2:
          return <div>{aetherBatteryVoltageFunctions.voltageToPercent(value)} %</div>;
        default:
          return null;
      }
    },
    oneSpeed: (value) => <div>{`${value}%`}</div>,
    singleElectrodeSettingsAlternating: (value, index) => {
      switch (index) {
        case singleElectrodeSettingsAlternatingPositions.stateSwitchDelay:
          return <div>{value} ms</div>;
        case singleElectrodeSettingsAlternatingPositions.minimumPulseTime:
          return <div>{value} ms</div>;
        case singleElectrodeSettingsAlternatingPositions.maximumPulseTime:
          return <div>{value} ms</div>;
        case singleElectrodeSettingsAlternatingPositions.minimumTimeBetweenPulses:
          return <div>{value} ms</div>;
        case singleElectrodeSettingsAlternatingPositions.maximumTimeBetweenPulses:
          return <div>{value} ms</div>;
        default:
          return null;
      }
    }
  },
  {
    get(obj, prop) {
      if (prop in obj) {
        return obj[prop];
      }
      return () => `Default ${String(prop)}`;
    }
  }
);

export const ConfigToNameFunctionMapping = new Proxy(
  {
    gripsPositions: (index) => {
      const gripsPositionsNamings = ['Thumb', 'Index', 'Middle', 'Ring', 'Pinky'];
      return gripsPositionsNamings[index];
    },
    gripPairsConfig: (index) => {
      const gripPairsConfigNaming = ['Opposed ', 'Non-Opposed'];
      return gripPairsConfigNaming[index];
    },
    inputSite: () => 'Value',
    inputDevice: () => 'Value',
    controlMode: () => 'Value',
    speedControlStrategy: () => 'Value',
    gripSwitchingMode: () => 'Value',
    batteryBeep: (index) => {
      const batteryBeepNaming = ['Alarm trigger level', 'Status'];
      return batteryBeepNaming[index];
    },
    gripSequentialConfig: (index) => {
      const gripSequentialConfigNaming = ['Opposed ', 'Non-Opposed'];
      return gripSequentialConfigNaming[index];
    },
    emgThresholds: (index) => {
      const thresholdsNamings = [
        'CS extension threshold',
        'CS flexion threshold',
        'Threshold control open value',
        'Proportional control open second value',
        'Proportional control open third value',
        'Threshold control close value',
        'Proportional control close second value',
        'Proportional control close third value',
        'Soft-grip open threshold second value',
        'Soft-grip close threshold second value'
      ];
      return thresholdsNamings[index];
    },
    fingerStrength: () => 'Value',
    autoGrasp: (index) => {
      const autoGraspNamings = ['Status', 'Value'];
      return autoGraspNamings[index];
    },
    emgSpike: (index) => {
      const emgSpikeNamings = ['Status', 'Value'];
      return emgSpikeNamings[index];
    },
    holdOpen: (index) => {
      const holdOpenNamings = ['Primary hold open time', 'Secondary hold open time'];
      return holdOpenNamings[index];
    },
    softGrip: () => 'Status',
    emgGains: (index) => {
      const emgGainsNamings = ['Open signal gain', 'Close signal gain'];
      return emgGainsNamings[index];
    },
    pulseTimings: (index) => {
      const pulseTimingsNamings = [
        'Min. pulse time',
        'Max. pulse time',
        'Min. time between pulses',
        'Max. time between pulses'
      ];
      return pulseTimingsNamings[index];
    },
    generalHandSettings: (index) => {
      const generalHandSettingsNamings = [
        'Hand side',
        'Thumb length',
        'Wait for CS before startup'
      ];
      return generalHandSettingsNamings[index];
    },
    singleElectrodeMode: () => 'Mode',
    singleElectrodeModeSettings: (index) => {
      const settingsMapping = [
        'Start point signal',
        'Window time',
        'Short hold open',
        'Long hold open'
      ];
      return settingsMapping[index];
    },
    freezeModeEmg: (index) => {
      const freezeModeMapping = ['Relaxation closing', 'Relaxation opening', 'Status'];
      return freezeModeMapping[index];
    },
    coContractionTimings: (index) => {
      const coContractionNamings = ['Long co-contraction time', 'Signal rise offset time'];
      return coContractionNamings[index];
    },
    buzzingVolumeSettings: (index) => {
      const buzzingVolumeNamings = ['Buzzing volume'];
      return buzzingVolumeNamings[index];
    },
    followingGrip: (index) => {
      const followingGripNamings = ['Status'];
      return followingGripNamings[index];
    },
    freezeModeEmgSettings: (index) => {
      const followingGripNamings = [
        'Threshold opening',
        'Threshold closing',
        'Stage 1 time',
        'Stage 2 time'
      ];
      return followingGripNamings[index];
    },
    userFeedbackType: (index) => {
      const userFeedbackTypeNamings = ['Buzzer'];
      return userFeedbackTypeNamings[index];
    },
    emergencyBatterySettings: (index) => {
      const emergencyBatterySettingsNamings = ['Status', 'UNUSED', 'Mode trigger level'];
      return emergencyBatterySettingsNamings[index];
    },
    oneSpeed: (index) => {
      const oneSpeedNamings = ['Fingers speed'];
      return oneSpeedNamings[index];
    },
    singleElectrodeSettingsAlternating: (index) =>
      singleElectrodeSettingsAlternatingNamingsMap.get(index)
  },
  {
    get(obj, prop) {
      if (prop in obj) {
        return obj[prop];
      }
      return () => `Default ${String(prop)}`;
    }
  }
);
