/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { Button, Chip, MenuItem, TextField, Tooltip } from '@mui/material';
import Card from 'components/atoms/Card/Card';
import {
  CONFIG_ENTRY_NOTES_KEY,
  useConfigEntryNotes,
  useCreateConfigNote,
  useDeleteConfigNote,
  useDevice,
  useUpdateDeviceConfigEntry,
  DEVICE_CONFIG_HISTORY_ENTRY_QUERY_KEY
} from 'hooks/api/useDevice';
import { HeaderM, HeaderS, TextS, TextXS } from 'components/atoms/Typography/Typography';
import { SortDirs } from 'types';
import CompanyImg from 'assets/company_img.jpg';
import ButtonToggle from 'components/atoms/ButtonToggle/ButtonToggle';
import { configSettingsNamesMap, gripsGroupsOptionsMap } from 'utils/definesLocal';
import Accordion from 'components/atoms/Accordion/Accordion';
import Loader from 'components/atoms/Loader/Loader';
import NotesList from 'components/organisms/NotesList/NotesList';
import { ConfigNotesSortOptions, ConfigHistoryItemEntry } from 'api/device/device.types';
import { AddTemplateModal } from 'components/molecules/Modals/AddTemplate';
import { DeviceConfigTemplate } from 'consts/deviceConfig/deviceConfig.types';
import Divider from 'components/atoms/Divider/Divider';
import { useConfigStore } from 'reducers/configStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useDeviceManager } from 'hooks/api/useDeviceManager';
import { useModal } from 'hooks/useModal';
import useImportButton from 'hooks/useImportButton';
import ConfigComponent from 'components/organisms/ConfigComponent/ConfigComponent';
import { freezeModeEmgPositions } from 'consts/deviceConfig/configPropertiesPositions';
import { FREEZE_MODE_ON_DUAL, FREEZE_MODE_ON_SINGLE } from 'consts/deviceConfig/freezeMode';
import {
  findDifferencesImport,
  transformAndApply,
  transformConfigAPI
} from 'utils/Config/transformConfig';
import RestoreConfigHistoryModal from 'components/molecules/Modals/RestoreConfigHistoryModal';
import { Header1, HeaderWrapper } from '../styled';
import { HeaderMode } from '../SessionHistory/SessionHistory';
import { ConfigToNameFunctionMapping, ConfigToValueFunctionMapping } from '../ValueMappings';

const Header4 = styled.h4`
  ${HeaderS};
`;

const Header3 = styled.h3`
  ${HeaderM};
  margin-bottom: 16px;
`;

const TextMedium = styled.p`
  ${TextS}
`;

const TextSub = styled.p`
  ${TextXS};
  color: ${({ theme }) => theme.typography.colors.subtitle};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: grid;
  gap: 32px;
  grid-template-areas:
    'config'
    'version'
    'notes';

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      'version config'
      'notes config';
    grid-template-rows: auto 1fr;
  }
`;

const ConfigDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    align-items: stretch;
  }
`;

const ValueWrapper = styled.span`
  background-color: ${({ theme }) => theme.colorFill};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-block;
`;

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PatientImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
`;

const InnerWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  gap: 16px;
`;

const ConfigurationHeaderWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: baseline;
`;

const TableStyle = css`
  display: grid;
  grid-template-columns: 40% repeat(auto-fit, minmax(100px, 1fr));
  justify-items: start;
  align-items: center;
`;

export const TableBody = styled.div`
  ${TableStyle};

  ${({ sticky }) =>
    sticky &&
    css`
      background-color: white;
      position: sticky;
      top: 0px;
    `};
`;

const TableBodyConfig = styled.div`
  ${TableStyle};

  &:first-child {
    padding: 0 0 20px 0;
  }

  padding: 20px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colorFill};
`;

export const TableHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

enum ToggleTypesChangesHistory {
  all = 0,
  emg = 1,
  grips = 2,
  prosthesis = 3
}

enum CONFIG_PROPERTY_SUPPORT {
  active,
  inactive,
  movedToCommon,
  movedToMode
}

const actionMapping = {
  [CONFIG_PROPERTY_SUPPORT.inactive]: (
    <Tooltip title='Setting is no longer supported by your firmware.'>
      <Chip label='Unsupported' />
    </Tooltip>
  ),
  [CONFIG_PROPERTY_SUPPORT.movedToMode]: (
    <Tooltip title='Setting was set as common at the time of change, but was moved to mode in current firmware.'>
      <Chip label='Changed' />
    </Tooltip>
  ),
  [CONFIG_PROPERTY_SUPPORT.movedToCommon]: (
    <Tooltip title='Setting was set as mode at the time of change, but was moved to common in current firmware.'>
      <Chip label='Changed' />
    </Tooltip>
  ),
  [CONFIG_PROPERTY_SUPPORT.active]: null
};

const ConfigHistoryItem = ({
  name,
  configName,
  after,
  before,
  now,
  showLoadChanges = false,
  supported = CONFIG_PROPERTY_SUPPORT.active
}) => {
  const HistoricItemWrapper = (type: string, value: any, index, currentConfig = false) => {
    if (type.includes('gripsPositions')) {
      const names = name.split('.');

      if (currentConfig) value = now.gripsPositions[`${names[1]}`][`${names[2]}`][index];

      return value || value === 0 ? (
        <ValueWrapper>{ConfigToValueFunctionMapping[`${names[0]}`](value, index)}</ValueWrapper>
      ) : (
        <ValueWrapper />
      );
    }

    if (type.includes('fingerStrength') && currentConfig) {
      value = now.fingerStrength?.[1];
    }

    return value || value === 0 ? (
      <ValueWrapper>{ConfigToValueFunctionMapping[`${name}`](value, index)}</ValueWrapper>
    ) : (
      <ValueWrapper />
    );
  };

  const HeaderItemWrapper = (type: string, value: any) => {
    if (type.includes('gripsPositions')) {
      const names = name.split('.');
      return (value || value === 0) && names[0] !== undefined ? (
        <div>{ConfigToNameFunctionMapping[`${names[0]}`](value)}</div>
      ) : null;
    }

    return value || value === 0 ? <div>{ConfigToNameFunctionMapping[`${name}`](value)}</div> : null;
  };

  const NameWrapper = (configName: keyof DeviceConfigTemplate) => {
    if (name.includes('gripsPositions')) {
      const names = configName.split('.');
      return `${configSettingsNamesMap.get(
        names[0] as keyof DeviceConfigTemplate
      )} (${gripsGroupsOptionsMap.get(Number(names[1]))}, ${names[2]})`;
    }
    return configSettingsNamesMap.get(configName) ?? 'Setting name';
  };

  if (name === 'fingerStrength') {
    after = [after?.[1]];
    before = [before?.[1]];
  }

  if (name === 'gripPairsConfig' || name === 'gripSequentialConfig') {
    return (
      <Accordion header={NameWrapper(configName)} style={{ marginTop: '24px' }}>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 0)}
          {!showLoadChanges && HistoricItemWrapper(name, before, 0)}
          {HistoricItemWrapper(name, after, 0)}
          {HistoricItemWrapper(name, now[`${name}`], 0, true)}
        </TableBodyConfig>
        <TableBodyConfig>
          {HeaderItemWrapper(name, 1)}
          {!showLoadChanges && HistoricItemWrapper(name, before, 1)}
          {HistoricItemWrapper(name, after, 1)}
          {HistoricItemWrapper(name, now[`${name}`], 1, true)}
        </TableBodyConfig>
      </Accordion>
    );
  }

  if (name === 'freezeModeEmg') {
    const freezeModeDeactivatorBefore = before?.[freezeModeEmgPositions.detectorActivatingType];
    const freezeModeDeactivatorAfter = after?.[freezeModeEmgPositions.detectorActivatingType];

    if (
      (freezeModeDeactivatorBefore === FREEZE_MODE_ON_DUAL[0] ||
        freezeModeDeactivatorBefore === FREEZE_MODE_ON_SINGLE[0]) &&
      (freezeModeDeactivatorAfter === FREEZE_MODE_ON_DUAL[0] ||
        freezeModeDeactivatorAfter === FREEZE_MODE_ON_SINGLE[0])
    )
      return null;
  }

  if (!after) return null;

  return (
    <Accordion
      header={NameWrapper(configName)}
      actions={actionMapping[supported]}
      style={{ marginTop: '24px' }}>
      {after.map((value, index) => {
        const mappingExists =
          value || value === 0 ? ConfigToValueFunctionMapping[`${name}`](value, index) : null;

        if (!mappingExists) return;

        return (
          <TableBodyConfig>
            {HeaderItemWrapper(name, index)}
            {!showLoadChanges && HistoricItemWrapper(name, before ? before[index] : null, index)}
            {HistoricItemWrapper(name, value, index)}
            {HistoricItemWrapper(name, now[`${name}`] ? now[`${name}`][index] : null, index, true)}
          </TableBodyConfig>
        );
      })}
    </Accordion>
  );
};

const PatientDetails = ({ deviceInfo }) => (
  <div>
    <Header3>Patient details</Header3>
    <DetailsWrapper>
      <PatientImage src={deviceInfo?.amputee?.image || CompanyImg} alt='Patient' />
      <div>
        <Header4>{deviceInfo?.amputee?.name}</Header4>
        <p>{deviceInfo?.amputee?.email}</p>
      </div>
    </DetailsWrapper>
    <InnerWrapper>
      <div>
        <TextMedium>Company</TextMedium>
        <TextSub>{deviceInfo?.company?.name}</TextSub>
      </div>
      <div>
        <TextMedium>Clinician</TextMedium>
        <TextSub>{deviceInfo?.clinician?.name}</TextSub>
      </div>
    </InnerWrapper>
  </div>
);

const DeviceConfigComponent = () => {
  const deviceId = useDeviceInfoStore((state) => state.deviceId);
  // @ts-ignore
  const { configId } = useParams();
  const { result: device } = useDevice(Number(deviceId), { extend: 'amputee,clinician,company' });
  const { result: notes } = useConfigEntryNotes({
    deviceId: Number(deviceId),
    // @ts-ignore
    configId,
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const { mutateAsync: updateConfig, isLoading: isLoadingUpdateConfigEntry } =
    useUpdateDeviceConfigEntry();
  const [currentToggle, setCurrentToggle] = useState(ToggleTypesChangesHistory.all);
  const {
    state: { detail: changeEntry }
  }: { state: { detail: ConfigHistoryItemEntry } } = useLocation();
  const {
    handleClose: handleCloseTemplateModal,
    handleOpen: handleOpenTemplateModal,
    isOpen
  } = useModal();
  const {
    handleClose: handleCloseRestoreConfigHistoryModal,
    handleOpen: handleOpenRestoreConfigHistoryModal,
    isOpen: isRestoreConfigHistoryModalOpen
  } = useModal();
  const { mutateAsync: createConfigNote, isLoading: isLoadingCreateNote } = useCreateConfigNote();
  const { mutateAsync: deleteConfigNote } = useDeleteConfigNote();
  const { restoreConfigHistory, isLoadingDeviceManager } = useDeviceManager();
  const { config, commonKeys, modeKeys, slotSelected } = useConfigStore((state) => ({
    config: state.config,
    commonKeys: state.commonPropertiesAPI,
    modeKeys: state.modePropertiesAPI,
    slotSelected: state.slotSelected
  }));
  const { common, modes } = config;
  const [selectedModeSlot, setSelectedModeSlot] = useState(0);
  const queryClient = useQueryClient();
  const { parsedConfig } = transformConfigAPI(changeEntry, config.modes);
  const importDifferences = findDifferencesImport(common, modes, parsedConfig);
  const { importTooltip, disableImportButton } = useImportButton(importDifferences);

  const toggleRestorePoint = async () => {
    const data = {
      restore_point: 0
    };
    if (changeEntry.restore_point === 0) {
      data.restore_point = 1;
    }
    try {
      await updateConfig({ deviceId, configId: changeEntry.id, data });
      queryClient.invalidateQueries(DEVICE_CONFIG_HISTORY_ENTRY_QUERY_KEY);
      toast.success('Restore point updated');
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddNote = async ({ note, type }) => {
    await createConfigNote({
      deviceId: Number(deviceId),
      configId: Number(configId),
      data: { note, type }
    });
    queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  const handleDeleteNote = async ({ noteId }) => {
    await deleteConfigNote({ deviceId: Number(deviceId), configId: Number(configId), noteId });
    queryClient.invalidateQueries(CONFIG_ENTRY_NOTES_KEY);
  };

  const handleRestore = async () => {
    await restoreConfigHistory(changeEntry.id);
    handleCloseRestoreConfigHistoryModal();
  };

  if (!config.common.configAPI) {
    return null;
  }

  const transformHistory = (entries) => {
    const common = entries
      .filter((entry) => !entry.config_entry.mode_id)
      .map((entry) => ({
        after: JSON.parse(entry.new_value),
        before: JSON.parse(entry.old_value),
        key: entry.config_entry.key
      }));

    const modes: any = [];
    for (let index = 0; index < config.modes.length; index += 1) {
      const modeInfo = config.modes[index];

      const modeChanges = entries
        .filter((entry) => entry.config_entry.mode_id === modeInfo.id)
        .map((entry) => ({
          after: JSON.parse(entry.new_value),
          before: JSON.parse(entry.old_value),
          key: entry.config_entry.key
        }));

      modes.push({ changes: modeChanges, slot: modeInfo.slot, name: modeInfo.name });
    }

    return { common, modes };
  };

  const completeConfig = {
    ...parsedConfig.common,
    ...parsedConfig.modes.find((mode) => mode.slot === selectedModeSlot).config
  };

  const transformedHistory = transformHistory(changeEntry.entries);

  const keysChecking = (
    key: keyof DeviceConfigTemplate,
    keyInModeAtTimeOfChange: boolean = false
  ) => {
    const isGripsPositions = key.split('.')[0] === 'gripsPositions';
    const includedInKeys = commonKeys?.includes(key) || modeKeys?.includes(key);

    const isSupported: boolean = Boolean(isGripsPositions ? true : includedInKeys);
    const isKeyPresentInOther = Boolean(
      keyInModeAtTimeOfChange ? commonKeys?.includes(key) : modeKeys?.includes(key)
    );

    const isMoved: boolean = isSupported && !isGripsPositions && isKeyPresentInOther;

    return { isSupported, isMoved };
  };

  return (
    <>
      {isOpen && (
        <AddTemplateModal handleClose={handleCloseTemplateModal} configModes={parsedConfig.modes} />
      )}
      {isRestoreConfigHistoryModalOpen && (
        <RestoreConfigHistoryModal
          handleClose={handleCloseRestoreConfigHistoryModal}
          handleAccept={handleRestore}
          entry={parsedConfig}
          isLoading={isLoadingDeviceManager}
        />
      )}
      <HeaderWrapper>
        <Header1>Applied changes</Header1>
      </HeaderWrapper>
      <Card>
        <TableBody>
          <TableHeader>Configuration</TableHeader>
          <TableHeader>Value before</TableHeader>
          <TableHeader>Value after</TableHeader>
          <TableHeader>Value now</TableHeader>
        </TableBody>
        {transformedHistory.common.length > 0 && (
          <>
            <HeaderMode>Common</HeaderMode>
            {transformedHistory.common.map((change) => (
              <ConfigHistoryItem
                key={changeEntry.id + Math.random()}
                name={change.key}
                configName={change.key}
                before={change.before}
                after={change.after}
                now={{
                  ...config.common.configAPI,
                  ...config.modes.find((_mode) => _mode.slot === slotSelected)?.configAPI
                }}
                supported={(() => {
                  if (!keysChecking(change.key).isSupported)
                    return CONFIG_PROPERTY_SUPPORT.inactive;
                  if (keysChecking(change.key).isMoved) return CONFIG_PROPERTY_SUPPORT.movedToMode;
                  return CONFIG_PROPERTY_SUPPORT.active;
                })()}
              />
            ))}
          </>
        )}
        {transformedHistory.modes.map(
          (mode) =>
            mode.changes.length > 0 && (
              <>
                <HeaderMode>Mode: {mode.name}</HeaderMode>
                {mode.changes.map((change) => (
                  <ConfigHistoryItem
                    key={changeEntry.id + Math.random()}
                    name={change.key}
                    configName={change.key}
                    before={change.before}
                    after={change.after}
                    now={{
                      ...config.common.configAPI,
                      ...config.modes.find((_mode) => _mode.slot === mode.slot)?.configAPI
                    }}
                    supported={(() => {
                      if (!keysChecking(change.key, true).isSupported)
                        return CONFIG_PROPERTY_SUPPORT.inactive;
                      if (keysChecking(change.key, true).isMoved)
                        return CONFIG_PROPERTY_SUPPORT.movedToCommon;
                      return CONFIG_PROPERTY_SUPPORT.active;
                    })()}
                  />
                ))}
              </>
            )
        )}
      </Card>
      <Divider margin='40px' />
      <HeaderWrapper>
        <ConfigurationHeaderWrapper>
          <Header1>Configuration</Header1>
          {config.modes && (
            <TextField
              fullWidth
              id='selected-mode'
              label='Mode shown'
              select
              sx={{ width: '150px' }}
              SelectProps={{
                value: selectedModeSlot,
                onChange: (e: any) => setSelectedModeSlot(e.target.value)
              }}>
              {config.modes.map((mode) => (
                <MenuItem key={`selected-mode_${mode.name}`} value={mode.slot}>
                  {mode.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </ConfigurationHeaderWrapper>
        <ButtonWrapper>
          <Tooltip title={importTooltip()}>
            <span>
              <Button
                onClick={handleOpenRestoreConfigHistoryModal}
                color='secondary'
                disabled={disableImportButton()}>
                Load
              </Button>
            </span>
          </Tooltip>
          <ButtonToggle
            // @ts-ignore
            toggle={setCurrentToggle}
            modes={[
              { id: ToggleTypesChangesHistory.all, name: 'All' },
              { id: ToggleTypesChangesHistory.emg, name: 'EMG' },
              { id: ToggleTypesChangesHistory.grips, name: 'Grips' },
              { id: ToggleTypesChangesHistory.prosthesis, name: 'Prosthesis' }
            ]}
            activeId={currentToggle}
          />
        </ButtonWrapper>
      </HeaderWrapper>
      {changeEntry ? (
        <Wrapper>
          <ConfigDetailsWrapper style={{ gridArea: 'notes' }}>
            {device?.amputee && (
              <Card>
                <PatientDetails deviceInfo={device} />
              </Card>
            )}
            <Card>
              <Accordion header='Notes'>
                <NotesList
                  notes={notes ?? []}
                  handleAdd={handleAddNote}
                  handleDelete={handleDeleteNote}
                  isLoadingAddNote={isLoadingCreateNote}
                />
              </Accordion>
            </Card>
          </ConfigDetailsWrapper>
          <div style={{ gridArea: 'config' }}>
            <Card>
              <ConfigComponent
                config={completeConfig || null}
                transformedConfig={transformAndApply(completeConfig)}
                currentToggle={currentToggle}
              />
            </Card>
          </div>
          <ConfigDetailsWrapper style={{ gridArea: 'version' }}>
            <Card>
              <Header3>Options</Header3>
              <Button fullWidth onClick={handleOpenTemplateModal}>
                Save as template
              </Button>
              <Button
                fullWidth
                color='secondary'
                onClick={toggleRestorePoint}
                sx={{ marginTop: '16px' }}>
                {changeEntry.restore_point === 0
                  ? 'Create restore point'
                  : 'Unset as restore point'}
              </Button>
            </Card>
          </ConfigDetailsWrapper>
        </Wrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default DeviceConfigComponent;
