import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Alert, Button } from '@mui/material';
import styled from 'styled-components';
/* import Button from '../Button/Button'; */

export const UploadArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 8px 1px 20px;
  padding: 8px 16px;
  border-radius: 5px;
  box-shadow: 0 4px 20px 0 rgba(61, 72, 68, 0.05);
  background-color: #e9ecef;
  position: relative;
  border: ${({ dragActive }) =>
    dragActive ? '1px solid hsl(201, 100%, 65%)' : '1px solid transparent'};
`;

export const TipText = styled.span`
  position: absolute;
  right: 0;
  bottom: -20px;
  text-align: right;
  font-size: 12px;
  color: #808080;
`;

const FilesList = styled.ul`
  margin-left: 25px;
`;
const FileListItem = styled.li``;
const FileRemove = styled.span`
  color: ${({ theme }) => theme.colorDanger};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colorError};
  }
`;

const FileInput = (props) => {
  const { name, mode = 'update', accept, register, unregister, setValue, onChange, limit } = props;
  const { watch } = useForm();
  const [filesState, setFiles] = useState<object[]>([]);
  const files = watch(name);

  const onDrop = useCallback(
    (droppedFiles) => {
      setValue(name, droppedFiles, { shouldValidate: true });
      setFiles((prev: any) => [...prev, ...droppedFiles]);
    },
    [setValue, name, mode, files]
  );

  useEffect(() => {
    onChange(filesState);
  }, [filesState, onChange]);

  const onDelete = (name) => {
    const newList = filesState.filter((item: any) => item.name !== name);
    setValue(name, newList, { shouldValidate: true });
    setFiles(newList);
    onChange(newList);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  const limitAchieved = filesState?.length >= limit;

  return (
    <>
      {!limitAchieved && (
        <UploadArea {...getRootProps()} dragActive={isDragActive}>
          <input {...props} id={name} {...getInputProps()} />
          <Button>Select file</Button>
          <span>or drop files here to upload</span>
        </UploadArea>
      )}
      <Alert severity='info'>
        {`Allowed formats: png / jpg / gif / mp4 / mov / avi. Max 100 MB of all files. Max ${limit} files.`}
      </Alert>
      {filesState && filesState.length > 0 && (
        <FilesList>
          {filesState.map((item: any) => (
            <FileListItem key={item.name}>
              {item?.name} <FileRemove onClick={() => onDelete(item.name)}>(Remove)</FileRemove>
            </FileListItem>
          ))}
        </FilesList>
      )}
    </>
  );
};

export default FileInput;
