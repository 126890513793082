/* eslint-disable dot-notation */
import { AxiosResponse } from 'axios';
import api from '../utils/apiClient';
import {
  UserDetails,
  UserLoginPayload,
  LoginParams,
  SendMfaCodePayload,
  VerifyMfaCodePayload,
  CheckMfaPayload,
  RegisterPayload,
  RegisterEntry
} from './authentication.types';

export const REFRESH_TOKEN = 'api/token/refresh';
export const ABOUT_ME = 'api/me';
export const AUTH_ABLY_USER = 'api/chat/authorize-user';
export const LOGOUT = 'api/logout';

export const CSRF_COOKIE = 'sanctum/csrf-cookie';
export const LOGIN_USER = 'api/login';
export const REGISTER_USER = 'api/register';
export const SEND_MFA_CODE = 'api/mfa/send';
export const VERIFY_MFA_CODE = 'api/mfa/verify';
export const SET_MFA_STATUS = 'api/mfa/status';

const withCredentials = {
  withCredentials: true
};

export const refreshTokenApi = (): Promise<any> =>
  api
    .post<UserLoginPayload>(REFRESH_TOKEN, {}, withCredentials)
    .then((res: AxiosResponse) => res.data);

export const fetchUserData = (): Promise<any> =>
  api.get<UserDetails>(ABOUT_ME, withCredentials).then((res: any) => res.data);

export const authAblyClient = (): Promise<any> =>
  api.get(AUTH_ABLY_USER, withCredentials).then((res: any) => res.data);

export const logoutAPI = (): Promise<any> =>
  api.post(LOGOUT, {}, withCredentials).then((res: any) => res.data);

export const loginUserSpa = (data: LoginParams): Promise<any> =>
  api.get(CSRF_COOKIE, withCredentials).then(() => loginUser(data));

export const loginUser = (data: LoginParams): Promise<any> =>
  api
    .post<UserLoginPayload>(LOGIN_USER, data, withCredentials)
    .then((res: AxiosResponse) => res.data);

export const registerUser = (data: RegisterPayload): Promise<any> =>
  api
    .get(CSRF_COOKIE, withCredentials)
    .then(() =>
      api
        .post<RegisterEntry>(REGISTER_USER, data, withCredentials)
        .then((res: AxiosResponse) => res.data)
    );

export const sendMfaCode = (data: SendMfaCodePayload): Promise<any> =>
  api.post<SendMfaCodePayload>(SEND_MFA_CODE, data, withCredentials).then((res: any) => res.data);

export const verifyMfaCode = (data: VerifyMfaCodePayload): Promise<any> =>
  api
    .post<VerifyMfaCodePayload>(VERIFY_MFA_CODE, data, withCredentials)
    .then((res: any) => res.data);

export const checkMfaStatus = (): Promise<any> =>
  api.get<CheckMfaPayload>(SET_MFA_STATUS, withCredentials).then((res: any) => res.data);
