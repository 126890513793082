/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, DialogContent, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import { useConfigStore } from 'reducers/configStore';
import ModalPortal from 'utils/Modal/ModalPortal';
import {
  useCreateConfigTemplate,
  useDeviceConfigTemplate,
  useUpdateConfigTemplate
} from 'hooks/api/useDevice';
import Dropdown from 'components/atoms/Dropdown/Dropdown';
import { TemplateTypes } from 'types';
import ModalBase from './ModalBase';

const StyledForm = styled.form`
  display: grid;
  gap: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

type Inputs = {
  name: string;
  description: string;
};

type AddTemplateModalProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  configModes: any;
};

export const AddTemplateModal = ({ handleClose, configModes }: AddTemplateModalProps) => {
  const {
    mutateAsync: createConfigTemplate,
    isLoading,
    isError: isErrorCreate
  } = useCreateConfigTemplate();

  const onSubmit = async (data, owner, modeSlot) => {
    await createConfigTemplate({
      data: {
        name: data.name,
        description: data.description,
        config: JSON.stringify(configModes.find((mode) => mode.slot === modeSlot).config),
        owner: owner.toLowerCase()
      }
    });

    if (!isErrorCreate) {
      handleClose();
    }
  };

  return <AddTemplateForm handleClose={handleClose} onSubmit={onSubmit} isLoading={isLoading} />;
};

type EditTemplateModalProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  handleAfter: Function;
  templateId: number;
};

export const EditTemplateModal = ({
  handleClose,
  handleAfter,
  templateId
}: EditTemplateModalProps) => {
  const { result: templateEntry } = useDeviceConfigTemplate({ templateId });
  const {
    mutateAsync: updateConfigTemplate,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate
  } = useUpdateConfigTemplate();

  const onSubmit = async (data) => {
    await updateConfigTemplate({
      templateId,
      data: { name: data.name, description: data.description }
    });
    if (!isErrorUpdate) {
      handleAfter();
    }
  };

  if (!templateEntry) return null;

  return (
    <AddTemplateForm
      handleClose={handleClose}
      onSubmit={onSubmit}
      isLoading={isLoadingUpdate}
      templateEntry={templateEntry}
    />
  );
};

type AddTemplateFormProps = {
  handleClose: (event?: React.MouseEvent<HTMLElement>) => void;
  onSubmit: any;
  isLoading: boolean;
  templateEntry?: any;
};

const AddTemplateForm = ({
  handleClose,
  onSubmit,
  isLoading,
  templateEntry = undefined
}: AddTemplateFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>();
  const [selectedType, setSelectedType] = useState(TemplateTypes.me);
  const [selectedModeSlot, setSelectedModeSlot] = useState(0);
  const modes = useConfigStore((state) => state.config.modes);

  return (
    <ModalPortal>
      <ModalBase
        handleClick={handleClose}
        header={templateEntry ? 'Edit template name' : 'Add template'}
        fullWidth>
        <DialogContent>
          <StyledForm
            onSubmit={handleSubmit((data) => onSubmit(data, selectedType, selectedModeSlot))}>
            <TextField
              id='name'
              label='Name'
              required
              {...register('name', { required: 'This field is required' })}
              error={Boolean(errors?.name)}
              helperText={errors?.name?.message}
              defaultValue={templateEntry?.name || ''}
            />
            <TextField
              id='description'
              label='Describe the change'
              multiline
              required
              defaultValue={templateEntry?.description || ''}
              {...register('description')}
              error={Boolean(errors?.description)}
              helperText={errors?.description?.message}
              rows={6}
            />
            {modes && (
              <TextField
                fullWidth
                id='selected-mode'
                label='Selected mode'
                select
                SelectProps={{
                  value: selectedModeSlot,
                  onChange: (e: any) => setSelectedModeSlot(e.target.value)
                }}>
                {modes.map((mode) => (
                  <MenuItem key={`selected-mode_${mode.name}`} value={mode.slot}>
                    {mode.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {!templateEntry && (
              <TextField
                label='Owner:'
                id='selected-type'
                select
                SelectProps={{
                  value: selectedType,
                  onChange: (e: any) => setSelectedType(e.target.value)
                }}>
                {[TemplateTypes.me, TemplateTypes.company].map((templateType) => (
                  <MenuItem key={`selected-type_${templateType}`} value={templateType}>
                    {templateType}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <ButtonsWrapper>
              <Button onClick={handleClose} variant='outlined'>
                Cancel
              </Button>
              <LoadingButton type='submit' loading={isLoading}>
                <span>{templateEntry ? 'Save' : 'Add template'}</span>
              </LoadingButton>
            </ButtonsWrapper>
          </StyledForm>
        </DialogContent>
      </ModalBase>
    </ModalPortal>
  );
};
