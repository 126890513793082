/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Resizable } from 're-resizable';
import { useLocation } from 'react-router-dom';
import { JaaSMeeting } from '@jitsi/react-sdk';
import { ReactComponent as ArrowDouble } from 'assets/arrow-double.svg';
import StandaloneChart from 'views/Graph/StandaloneChart';
import { useMeetingStore } from 'reducers/meetingStore';
import useMeeting from 'hooks/useMeeting';
import { ViewModes } from 'utils/ViewModes/viewModes';
import { GRAPH } from 'consts/routes';

const ResizeElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 37px;
  background-color: ${({ theme }) => theme.colorFill};
  border: 1px solid #dee2e6;
  border-radius: 4px;
`;

const ChartWrapper = styled.div`
  flex: 1;
  margin: 10px;
`;

const StyledArrowDouble = styled(ArrowDouble)`
  width: 16px;
`;

const OuterChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
`;

const Meeting = () => {
  const location = useLocation();
  const { meetingStatus, viewMode, meetingJwt, roomId } = useMeetingStore();
  const { handleCloseMeeting } = useMeeting();

  const isInForbiddenChartLocation = location.pathname.includes(GRAPH);

  return meetingStatus ? (
    <Resizable
      defaultSize={{ width: 400, height: '100vh' }}
      maxWidth='50vw'
      minWidth='300px'
      handleStyles={{
        left: {
          position: 'absolute',
          top:
            viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG ? '80%' : '48%',
          left: '-23px'
        }
      }}
      handleComponent={{
        left: (
          <ResizeElement>
            <StyledArrowDouble />
          </ResizeElement>
        )
      }}>
      <OuterChartWrapper>
        {roomId && (
          <div style={{ flex: '1' }}>
            <JaaSMeeting
              appId={process.env.REACT_APP_JITSI_APP_ID!}
              roomName={roomId}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.width = '100%';
                iframeRef.style.height = '100%';
              }}
              jwt={meetingJwt!}
              onReadyToClose={handleCloseMeeting}
            />
          </div>
        )}
        {(viewMode === ViewModes.standardEMG || viewMode === ViewModes.minimalEMG) &&
          !isInForbiddenChartLocation && (
            <ChartWrapper>
              <StandaloneChart style={{ height: '100%' }} />
            </ChartWrapper>
          )}
      </OuterChartWrapper>
      {!meetingStatus &&
        (viewMode === ViewModes.standardEMG || viewMode === ViewModes.minimalEMG) && (
          <OuterChartWrapper>
            {(viewMode === ViewModes.standardEMG || viewMode === ViewModes.minimalEMG) && (
              <ChartWrapper>
                <StandaloneChart style={{ height: '100%' }} />
              </ChartWrapper>
            )}
          </OuterChartWrapper>
        )}
    </Resizable>
  ) : (
    !isInForbiddenChartLocation && (
      <Resizable
        defaultSize={{ width: 400, height: '100vh' }}
        maxWidth='50vw'
        minWidth='300px'
        handleStyles={{
          left: {
            position: 'absolute',
            top:
              viewMode === ViewModes.standard || viewMode === ViewModes.standardEMG ? '80%' : '48%',
            left: '-23px'
          }
        }}
        handleComponent={{
          left: (
            <ResizeElement>
              <StyledArrowDouble />
            </ResizeElement>
          )
        }}>
        {(viewMode === ViewModes.standardEMG || viewMode === ViewModes.minimalEMG) && (
          <OuterChartWrapper>
            <ChartWrapper>
              <StandaloneChart style={{ height: '100%' }} />
            </ChartWrapper>
          </OuterChartWrapper>
        )}
      </Resizable>
    )
  );
};

export default Meeting;
