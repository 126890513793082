/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Toaster } from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';
import Sidebar from 'components/organisms/Sidebars/Sidebar';
import UtilityBar from 'components/molecules/UtilityBar/UtilityBar';
import RemoteSessionBar from 'components/molecules/RemoteSessionBar/RemoteSessionBar';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import EmgPlaybackBar from 'components/molecules/EmgPlaybackBar/EmgPlaybackBar';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  background-color: #fff;
`;

const OuterWrapper = styled.div`
  flex: 1;
  transform: translateZ(0);
`;

const InnerWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px 25px;
  height: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.xsmall}) {
    padding: 50px 65px;
  }
`;

const Main = styled.div`
  max-width: ${({ maxWidth }) => maxWidth};
  margin: 10px auto 0;
  padding: ${({ padding }) => padding || '0px'};
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 50px;
  }
`;

const BarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth }) => maxWidth};
  gap: 16px;
  margin: 0 auto;
`;

const DefaultLayout = ({
  children,
  padding = '0',
  maxWidth = '1300px'
}: {
  children: React.ReactNode;
  padding?: string;
  maxWidth?: string;
}) => {
  const deviceConnected = useDeviceInfoStore((state) => state.connected);

  return (
    <Wrapper>
      <ReactTooltip place='top' type='info' effect='solid' />
      <Sidebar />
      <OuterWrapper id='layout'>
        <InnerWrapper>
          <BarsWrapper maxWidth={maxWidth}>
            <UtilityBar device={deviceConnected} />
            <RemoteSessionBar />
            <EmgPlaybackBar />
          </BarsWrapper>
          <Main id='main' padding={padding} maxWidth={maxWidth}>
            {children}
          </Main>
        </InnerWrapper>
        <Toaster
          toastOptions={{
            className: '',
            style: {
              marginTop: '30px'
            }
          }}
        />
      </OuterWrapper>
    </Wrapper>
  );
};

export default DefaultLayout;
