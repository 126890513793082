import styled, { keyframes, css } from 'styled-components';
import { ReactComponent as Save } from 'assets/sign-in-alt-solid.svg';
import { IconSidebarModes } from 'components/atoms/IconDescribed/IconDescribed';

const pulseAnimation = keyframes`
  to {
      color: #4DC1FF;
    }
    `;

type StyledSaveProps = {
  unsaved: boolean;
};

export const StyledSaved = styled(Save)<StyledSaveProps>`
  ${({ unsaved }) =>
    unsaved === true &&
    css`
      animation-name: ${pulseAnimation};
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
    `}
`;

export const IconsContainer = styled.div`
  ${({ mode }) =>
    mode === IconSidebarModes.standard &&
    css`
      position: fixed;
      right: 0;
      background-color: white;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
      padding: 1.3%;
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px;
      z-index: 99;
      display: grid;
      grid-template-rows: repeat(auto-fill, 20px);
      grid-gap: 10px;

      @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
        grid-template-rows: repeat(auto-fill, 30px);
        grid-gap: 15px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
        grid-template-rows: repeat(auto-fill, 40px);
        grid-gap: 20px;
      }
    `};

  ${({ mode }) =>
    mode === IconSidebarModes.minimal &&
    css`
      width: 50px;
      padding: 10px 0;
      background-color: white;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      z-index: 99;
      display: grid;
      grid-gap: 20px;
    `};
`;

export const IconsContainerMinimal = styled.div`
  width: 50px;
  padding: 10px 0;
  background-color: white;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  z-index: 99;
  display: grid;
  grid-gap: 20px;
`;

export const SavedContainer = styled.div`
  position: relative;
`;
