export const HOME = '/';
export const DEVICE = '/device';
export const SUPPORT_TICKET = '/support-ticket';
export const REGISTER = '/register';
export const CONFIGURATION = '/configuration';
export const CHOOSE_GRIPS = '/choose-grips';
export const PROSTHESIS_SETTINGS = '/prosthesis-settings';
export const APPLICATION_SETTINGS = '/application-settings';
export const GRIPS_CONFIGURATION = '/grips-configuration';
export const GRAPH = '/graph';
export const SERVICE_MENU = '/service-menu';
export const SESSION_HISTORY = '/session-history';
export const DEVICE_HISTORY = '/device-history';
export const DEVICE_HISTORY_DETAIL = `${DEVICE_HISTORY}/:configId`;
export const SERVICING = '/servicing';
export const CONFIG_TEMPLATES = '/config-templates';
export const CONFIG_TEMPLATES_DETAIL = `${CONFIG_TEMPLATES}/:templateId`;
