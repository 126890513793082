import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import Ably, { Types } from 'ably';
import { authAblyClient } from '../api/authentication/authentication';

type EventEmitter = Types.RealtimeCallbacks | undefined;

const autoLogoutChannel = (userId: string) => `auto-logout-${userId}`;

const useSynchronizeStateInApp = (user: any, onResetTimeAction: any) => {
  const eventEmitter = useRef<EventEmitter>(undefined);
  const [initialized, setInitialized] = useState(false);

  const assertEventEmitterIsInitialized = (
    eventEmitterToAssert: MutableRefObject<EventEmitter>
  ): Types.RealtimeCallbacks => {
    if (eventEmitterToAssert.current === undefined) {
      throw Error(`EventEmitter is not initialized`);
    }
    return eventEmitterToAssert.current;
  };

  const connectToClient = async () => {
    const ably = new Ably.Realtime.Callbacks({
      authCallback: async (tokenParams, callback) => {
        try {
          const tokenRequest = await authAblyClient();
          callback(null, tokenRequest);
        } catch (error: any) {
          callback(error, null);
        }
      }
    });
    await ably.connection.once('connected');

    return ably;
  };

  const emitActivityEvent = () => {
    const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
    const channel = eventEmitterIsDefined.channels.get(autoLogoutChannel(String(user.id)));

    sessionStorage.setItem('lastActivity', new Date().toISOString());
    channel.publish('reset', { application: 'CONFIGURATOR' });
  };

  const emitRemainingTimeEvent = useCallback(
    (remainingSeconds: number) => {
      const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
      eventEmitterIsDefined.channels
        .get(autoLogoutChannel(String(user.id)))
        .publish('remaining', { remainingTime: remainingSeconds, application: 'CONFIGURATOR' });
    },
    [eventEmitter, user.id]
  );

  const emitEvent = useCallback(
    (eventName: string) => {
      const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
      eventEmitterIsDefined.channels
        .get(autoLogoutChannel(String(user.id)))
        .publish(eventName, {});
    },
    [eventEmitter, user.id]
  );

  const on = (event: any, callback: any) => {
    if (!initialized) return;
    const channelName = autoLogoutChannel(String(user.id));
    const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
    const channel = eventEmitterIsDefined.channels.get(channelName);
    channel.subscribe(event, callback);
  };

  const off = (event: any, callback: any) => {
    if (!initialized) return;
    const channelName = autoLogoutChannel(String(user.id));
    const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
    const channel = eventEmitterIsDefined.channels.get(channelName);
    channel.unsubscribe(event, callback);
  };

  useEffect(() => {
    if (!initialized) return;
    try {
      const channelName = autoLogoutChannel(String(user.id));
      const eventEmitterIsDefined = assertEventEmitterIsInitialized(eventEmitter);
      const channel = eventEmitterIsDefined.channels.get(channelName);

      const onEvent = (message: any) => {
        sessionStorage.setItem('lastActivity', new Date().toISOString());
        onResetTimeAction(message);
      };

      const onMount = async () => {
        await channel.subscribe('reset', onEvent);
      };
      const onUnmount = async () => {
        await channel.unsubscribe('reset', onEvent);

        setTimeout(async () => {
          if (channel.listeners.length === 0) {
            await channel.detach();
          }
        }, 2500);
      };

      onMount();

      return () => {
        onUnmount();
      };
    } catch (error) {
      console.log(error);
    }

  }, [initialized]);

  useEffect(() => {
    let didUserConnectInterrupt = false;
    const connectionPromise = connectToClient().then((client) => {
      if (!didUserConnectInterrupt) {
        eventEmitter.current = client;
        setInitialized(true);
      }
    });

    return () => {
      didUserConnectInterrupt = true;
      const emitter = assertEventEmitterIsInitialized(eventEmitter);
      connectionPromise
        .then(() => emitter.connection.close())
        .then(() => {
          console.log('connection closed');
        });
      eventEmitter.current = undefined;
      setInitialized(false);
    };
  }, []);

  return {
    eventEmitter: () => assertEventEmitterIsInitialized(eventEmitter),
    initialized,
    emitRemainingTimeEvent,
    emitActivityEvent,
    emitEvent,
    on,
    off
  };
};

export default useSynchronizeStateInApp;
