/* eslint-disable no-restricted-globals */
const workerCode = () => {
  let timerInterval;
  let time = 0;
  let activityInterval;

  const stopTimerIfRequired = (action) => {
    if (action === 'off' || action === 'reset') {
      clearInterval(timerInterval);
      time = 0;
    }
  };

  const simulateActivity = (action) => {
    if (action === 'fakeActivity') {
      activityInterval = setInterval(() => {
        self.postMessage({ time, action: 'fakeActivity' });
      }, 5000);
    }

    if (action === 'fakeActivityReset') {
      clearInterval(activityInterval);
    }
  };

  const startTimer = (action) => {
    if (action === 'reset' || action === 'on') {
      timerInterval = setInterval(() => {
        time += 1;
        self.postMessage({ time, action: 'timer' });
      }, 1000);
    }
  };

  self.onmessage = function({ data: { action } }) {
    stopTimerIfRequired(action);
    startTimer(action);
    simulateActivity(action);
  };
};

let code = workerCode.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));
const blob = new Blob([code], { type: 'application/javascript' });

if (typeof URL.createObjectURL === 'function') {
  const worker_script = URL.createObjectURL(blob);
  module.exports = worker_script;
}

