import { logoutAPI } from 'api/authentication/authentication';
import { setApiAuthToken } from 'api/utils/apiClient';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export default function useLogout() {
  const queryClient = useQueryClient();
  const broadcast = new BroadcastChannel('logouts');

  const { isLoading, mutate: logout } = useMutation(logoutAPI, {
    onSuccess() {
      sessionStorage.removeItem('lastActivity');
      setApiAuthToken(null);
      queryClient.clear();
      broadcast.postMessage({ typ: 'LOG_OUT' });
    },
    onSettled() {
      window.location.reload();
    }
  });

  const beforeLogout = () => {
    setApiAuthToken(null);
    queryClient.cancelQueries();
    logout();
  };

  const offlineLogout = () => {
    setApiAuthToken(null);
    setTimeout(() => {
      queryClient.resetQueries();
      window.location.reload();
    });
  };

  useEffect(() => {
    broadcast.onmessage = () => {
      offlineLogout();
    };
  }, []);

  return {
    logout: () => beforeLogout(),
    isLoading,
    offlineLogout
  };
}
