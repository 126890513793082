/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { Button } from '@mui/material';
import useUnsaved from 'hooks/useUnsaved';
import { useUiStore } from 'reducers/uiStore';
import useRemoteSession from 'hooks/useRemoteSession';
import { useConfigStore } from 'reducers/configStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { MODALS } from 'views/Modals';
import { SESSION_HISTORY } from 'consts/routes';
import {
  ButtonsWrapper,
  SessionText,
  SessionWrapper,
  StyledStopIcon,
  StyledWarningIcon
} from './RemoteSessionBar.styled';

const RemoteSessionBar = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { config, slotSelected } = useConfigStore((state) => ({
    config: state.config,
    slotSelected: state.slotSelected
  }));
  const openModal = useUiStore((state) => state.openModal);
  const { remoteSessionEnabled, addSessionRestorePoint } = useLiveConfiguratorStore((state) => ({
    remoteSessionEnabled: state.enabled,
    addSessionRestorePoint: state.addSessionRestorePoint
  }));
  const { isUnsaved } = useUnsaved();
  const { disconnectRemoteSession } = useRemoteSession();

  const handleSessionHistory = () => {
    history.push(SESSION_HISTORY);
  };

  const handleCloseSession = () => {
    if (!isUnsaved) {
      toast(`No changes to the config has been made, ticket was not sent`, { icon: '⚠️' });
      disconnectRemoteSession(true);
      return;
    }
    openModal(MODALS.closeSession);
  };

  const handleCreateRestorePoint = () => {
    const modes = config.modes.map((mode) => ({ slot: mode.slot, config: mode.config }));
    addSessionRestorePoint({
      common: config.common.config,
      modes
    });
    toast.success('Restore point added');
  };

  if (!remoteSessionEnabled) {
    return <></>;
  }

  return (
    <SessionWrapper {...props}>
      <SessionText>
        <StyledWarningIcon />
        <span>{t('session.in_progress')}</span>
      </SessionText>
      <ButtonsWrapper>
        <Button onClick={handleCreateRestorePoint}>{t('session.restore_point')}</Button>
        <Button onClick={handleSessionHistory} color='secondary'>
          {t('session.history')}
        </Button>
        <Button onClick={handleCloseSession} color='error'>
          <StyledStopIcon />
          {t('session.stop')}
        </Button>
      </ButtonsWrapper>
    </SessionWrapper>
  );
};

export default RemoteSessionBar;
