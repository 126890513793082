/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import theme from './theme';
import { adpTheme } from './adpTheme';

export const themeMui = createTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif'
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: theme.colorFill
        },
        icon: {
          fill: theme.colorPrimary
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        colorPrimary: {
          color: theme.colorPrimary
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'large',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontSize: 16
        },
        containedPrimary: ({ ownerState, theme: muiTheme }) => ({
          backgroundColor: theme.colorSecondary,
          ':hover': {
            transform: 'scale(1.02, 1.02)',
            transition: 'transform 0.2s',
            backgroundColor: theme.colorSecondary
          },
          textTransform: 'none',
          filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25))',
          ...(ownerState.className === 'adpBlue' && {
            backgroundColor: adpTheme.palette.primary,
            ':hover': {
              transform: 'scale(1.02, 1.02)',
              transition: 'transform 0.2s',
              backgroundColor: adpTheme.palette.primary
            }
          })
        }),
        containedSecondary: {
          backgroundColor: theme.colorPrimary,
          ':hover': {
            transform: 'scale(1.02, 1.02)',
            transition: 'transform 0.2s',
            backgroundColor: theme.colorPrimary
          },
          textTransform: 'none',
          filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25))'
        },
        containedInfo: {
          backgroundColor: '#E4E7EB',
          ':hover': {
            transform: 'scale(1.02, 1.02)',
            transition: 'transform 0.2s',
            backgroundColor: '#E4E7EB'
          },
          textTransform: 'none',
          color: '#1D2B4F'
        },
        outlinedPrimary: {
          borderColor: theme.colorSecondary,
          ':hover': {
            transform: 'scale(1.02, 1.02)',
            transition: 'transform 0.2s'
          },
          textTransform: 'none'
        },
        containedError: {
          filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25))',
          ':hover': {
            transform: 'scale(1.02, 1.02)',
            transition: 'transform 0.2s'
          },
          textTransform: 'none'
        }
      }
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
        size: 'large',
        disableElevation: true,
        color: 'primary'
      }
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top'
      },
      styleOverrides: {
        tooltip: ({ ownerState, theme: muiTheme }) => ({
          backgroundColor: theme.colorPrimary,
          fontSize: 12,
          padding: '0.5rem 1rem',
          ...(ownerState.className === 'dark-tooltip' && {
            backgroundColor: '#626C84'
          })
        }),
        arrow: ({ ownerState, theme: muiTheme }) => ({
          color: theme.colorPrimary,
          ...(ownerState.className === 'dark-tooltip' && {
            color: '#626C84'
          })
        }),
      }
    }
  }
});
