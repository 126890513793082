/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Button, Tooltip } from '@mui/material';
import Card from 'components/atoms/Card/Card';
import {
  DEVICE_CONFIG_TEMPLATE_QUERY_KEY,
  TEMPLATE_NOTES_KEY,
  useCreateTemplateNote,
  useDeleteConfigTemplate,
  useDeleteTemplateNote,
  useDeviceConfigTemplate,
  useTemplateNotes
} from 'hooks/api/useDevice';
import { HeaderM, TextS, TextXS } from 'components/atoms/Typography/Typography';
import ButtonToggle from 'components/atoms/ButtonToggle/ButtonToggle';
import Loader from 'components/atoms/Loader/Loader';
import ConfigComponent, {
  ToggleTypesChangesHistory
} from 'components/organisms/ConfigComponent/ConfigComponent';
import { useModal } from 'hooks/useModal';
import { EditTemplateModal } from 'components/molecules/Modals/AddTemplate';
import DeleteModal from 'components/molecules/Modals/DeleteModal';
import { useConfigStore } from 'reducers/configStore';
import { ConfigNotesSortOptions, ConfigTemplatesExtendOptions } from 'api/device/device.types';
import { findDifferencesImport, transformConfig } from 'utils/Config/transformConfig';
import Accordion from 'components/atoms/Accordion/Accordion';
import { useDeviceManager } from 'hooks/api/useDeviceManager';
import NotesList from 'components/organisms/NotesList/NotesList';
import useImportButton from 'hooks/useImportButton';
import { SortDirs } from 'types';
import ImportTemplateModal from 'components/molecules/Modals/ImportTemplateModal';
import { Header1, HeaderWrapper } from '../../DeviceConfig/styled';

const Header3 = styled.h3`
  ${HeaderM};
  margin-bottom: 16px;
`;

const TextMedium = styled.p`
  ${TextS}
`;

const TextSub = styled.p`
  ${TextXS};
  color: ${({ theme }) => theme.typography.colors.subtitle};
`;

const Wrapper = styled.div`
  display: grid;
  gap: 32px;
  grid-template-areas:
    'config'
    'options'
    'notes'
    'details';

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      'details config'
      'options config'
      'notes config';
    grid-template-rows: auto auto 1fr;
  }
`;

const ConfigDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    align-items: stretch;
  }
`;

const InnerWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  gap: 16px;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  align-items: flex-end;
  gap: 16px;
`;

const ConfigTemplateDetailsComponent = () => {
  // @ts-ignore
  const { templateId } = useParams();
  const [currentToggle, setCurrentToggle] = useState(ToggleTypesChangesHistory.all);
  // @ts-ignore
  const { result: templateEntry } = useDeviceConfigTemplate({
    templateId: Number(templateId),
    params: { extend: ConfigTemplatesExtendOptions.author }
  });
  const [transformedConfig, setTransformedConfig] = useState<any>(null);
  const {
    mutateAsync: deleteTemplate,
    isError: isErrorDelete,
    isLoading: isLoadingDeleteTemplate
  } = useDeleteConfigTemplate();
  const {
    handleClose: handleCloseDeleteModal,
    handleOpen: handleOpenDeleteModal,
    isOpen: isOpenDeleteModal
  } = useModal();
  const { common, modes } = useConfigStore((state) => ({
    common: state.config.common,
    modes: state.config.modes
  }));
  const {
    handleClose: handleCloseImportModal,
    handleOpen: handleOpenImportModal,
    isOpen: isOpenImportModal
  } = useModal();
  const {
    handleClose: handleCloseEditModal,
    handleOpen: handleOpenEditModal,
    isOpen: isOpenEditModal
  } = useModal();
  const { push } = useHistory();
  const { mutateAsync: createTemplateNote, isLoading: isLoadingCreateNote } =
    useCreateTemplateNote();
  const { mutateAsync: deleteTemplateNote } = useDeleteTemplateNote();
  const { result: notes } = useTemplateNotes({
    templateId: Number(templateId),
    // @ts-ignore
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const [importDifferences, setImportDifferences] = useState(false);
  const { importTemplateConfig, isLoadingDeviceManager } = useDeviceManager();
  const { disableImportButton, importTooltip } = useImportButton(importDifferences);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (templateEntry) {
      const parsedConfig = JSON.parse(templateEntry.config);
      const { gripsTransformed, gripPairsConfig, gripSequentialConfig } =
        transformConfig(parsedConfig);
      setTransformedConfig({ grips: gripsTransformed, gripPairsConfig, gripSequentialConfig });
      setImportDifferences(findDifferencesImport(common, modes, JSON.parse(templateEntry.config)));
    }
  }, [templateEntry]);

  const handleAfterEditTemplate = () => {
    queryClient.invalidateQueries(DEVICE_CONFIG_TEMPLATE_QUERY_KEY);
    handleCloseEditModal();
  };

  const handleDeleteTemplate = async () => {
    await deleteTemplate({ templateId: Number(templateId) });
    if (!isErrorDelete) {
      push({ pathname: `/config-templates` });
    }
  };

  const handleAddNote = async ({ note }) => {
    await createTemplateNote({ templateId: Number(templateId), data: { note } });
    queryClient.invalidateQueries(TEMPLATE_NOTES_KEY);
  };

  const handleDeleteNote = async ({ noteId }) => {
    await deleteTemplateNote({ templateId: Number(templateId), noteId });
    queryClient.invalidateQueries(TEMPLATE_NOTES_KEY);
  };

  const handleImport = async (modeId: number) => {
    await importTemplateConfig(modeId, Number(templateId));
    handleCloseImportModal();
  };

  return (
    <>
      {isOpenImportModal && (
        <ImportTemplateModal
          header='Import template'
          handleAccept={handleImport}
          handleClose={handleCloseImportModal}
          entry={JSON.parse(templateEntry.config)}
          isLoading={isLoadingDeviceManager}
        />
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          header='Delete template'
          handleClose={handleCloseDeleteModal}
          handleAccept={handleDeleteTemplate}
          text='Are you sure you want to delete template: '
          isLoading={isLoadingDeleteTemplate}
          id={templateEntry.name}
        />
      )}
      {isOpenEditModal && (
        <EditTemplateModal
          handleClose={handleCloseEditModal}
          handleAfter={handleAfterEditTemplate}
          templateId={Number(templateId)}
        />
      )}
      <HeaderWrapper>
        <Header1>Template: {templateEntry?.name}</Header1>
        <ButtonsWrapper style={{ display: 'flex' }}>
          <Tooltip title={importTooltip()}>
            <span>
              <Button
                onClick={handleOpenImportModal}
                color='secondary'
                disabled={disableImportButton()}>
                Import
              </Button>
            </span>
          </Tooltip>
          <ButtonToggle
            // @ts-ignore
            toggle={setCurrentToggle}
            modes={[
              { id: ToggleTypesChangesHistory.all, name: 'All' },
              { id: ToggleTypesChangesHistory.emg, name: 'EMG' },
              { id: ToggleTypesChangesHistory.grips, name: 'Grips' },
              { id: ToggleTypesChangesHistory.prosthesis, name: 'Prosthesis' }
            ]}
            activeId={currentToggle}
          />
        </ButtonsWrapper>
      </HeaderWrapper>
      {templateEntry ? (
        <Wrapper>
          <ConfigDetailsWrapper style={{ gridArea: 'details' }}>
            <Card>
              <div>
                <Header3>Details</Header3>
                <InnerWrapper>
                  <div>
                    <TextMedium>Description</TextMedium>
                    <TextSub>{templateEntry?.description}</TextSub>
                  </div>
                  <div>
                    <TextMedium>Author</TextMedium>
                    <TextSub>{templateEntry?.author?.name}</TextSub>
                  </div>
                </InnerWrapper>
              </div>
            </Card>
          </ConfigDetailsWrapper>
          <div style={{ gridArea: 'config' }}>
            <Card>
              <ConfigComponent
                config={JSON.parse(templateEntry.config) || null}
                transformedConfig={transformedConfig}
                currentToggle={currentToggle}
              />
            </Card>
          </div>
          <ConfigDetailsWrapper style={{ gridArea: 'options' }}>
            <Card>
              <Header3>Options</Header3>
              <ButtonsWrapper style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Button fullWidth onClick={handleOpenEditModal}>
                  Edit name
                </Button>
                <Button fullWidth onClick={handleOpenDeleteModal}>
                  Delete
                </Button>
              </ButtonsWrapper>
            </Card>
          </ConfigDetailsWrapper>
          <ConfigDetailsWrapper style={{ gridArea: 'notes' }}>
            <Card>
              <Accordion header='Notes'>
                <NotesList
                  notes={notes ?? []}
                  handleAdd={handleAddNote}
                  handleDelete={handleDeleteNote}
                  showTypes={false}
                  isLoadingAddNote={isLoadingCreateNote}
                />
              </Accordion>
            </Card>
          </ConfigDetailsWrapper>
        </Wrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ConfigTemplateDetailsComponent;
