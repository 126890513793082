/* eslint-disable no-unused-vars */
import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { ReactComponent as Info } from 'assets/info-circle-solid.svg';
import useModes from 'hooks/useModes';
import { HeaderS, TextS } from 'components/atoms/Typography/Typography';
import { useConfigStore } from 'reducers/configStore';
import { useUiStore } from 'reducers/uiStore';
import { useLiveConfiguratorStore } from 'reducers/liveConfiguratorStore';
import { MODALS } from 'views/Modals';
import HandStatus from '../HandStatus/HandStatus';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DeviceText = styled.div`
  ${HeaderS};
  display: flex;
  align-items: center;
  color: red;
  background-color: rgba(220, 53, 69, 0.08);
  box-shadow: 0 4px 20px 0 rgba(61, 72, 68, 0.05);
  padding: 8px 16px;
  border-radius: 5px;

  ${({ connected }) =>
    connected &&
    css`
      background-color: rgba(62, 147, 193, 0.14);
    `};
`;

const StyledIconInfo = styled(Info)`
  width: 25px;
  margin-left: 15px;
  cursor: pointer;
`;

const Device = styled.div`
  color: ${({ theme }) => theme.colorPrimary};
  border-radius: 5px;
  ${TextS};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  i {
    margin-left: 5px;
    cursor: pointer;
  }
`;

const StatusesWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const UtilityBar = ({ device, ...props }) => {
  const { t } = useTranslation();
  const remoteSessionEnabled = useLiveConfiguratorStore((state) => state.enabled);
  const {
    slotSelected,
    config: { modes }
  } = useConfigStore((state) => state);
  const openModal = useUiStore((state) => state.openModal);
  const { handleChangeMode } = useModes();

  return (
    <Wrapper {...props}>
      <div>
        <StatusesWrapper>
          {!remoteSessionEnabled && (
            <DeviceText connected={device}>
              {device ? (
                <>
                  <Device>
                    <p>{t('utils.connected')}</p>
                    <StyledIconInfo onClick={() => openModal(MODALS.info)} />
                  </Device>
                </>
              ) : (
                t('utils.disconnected')
              )}
            </DeviceText>
          )}
          {Boolean(slotSelected !== null && modes?.length > 0) && (
            <FormControl sx={{ width: 150 }}>
              <InputLabel id='selected-mode-select-label'>Selected mode</InputLabel>
              <Select
                value={slotSelected}
                labelId='selected-mode-select-label'
                id='selected-mode-select'
                label='Selected mode'
                onChange={(e) => handleChangeMode(e.target.value)}>
                {modes.map((mode, index) => (
                  <MenuItem
                    key={`selected-mode-select_${mode.name}`}
                    value={mode.slot}
                    disabled={mode.active === 0}>
                    {mode.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </StatusesWrapper>
      </div>
      {(remoteSessionEnabled || device) && <HandStatus />}
    </Wrapper>
  );
};

UtilityBar.propTypes = {
  device: PropTypes.bool.isRequired
};

export default UtilityBar;
