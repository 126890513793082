import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as ThemeProviderMui } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'utils/i18n/i18n';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole, ReportingObserver } from '@sentry/integrations';
import ToastsRemover from 'views/ToastRemover';
import Root from 'views/Root';
import { themeMui } from 'theme/themeMui';
import theme from 'theme/theme';
import GlobalStyle from 'theme/globalStyles';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
const queryClient = new QueryClient();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    new BrowserTracing(),
    new CaptureConsole({ levels: ['error'] }),
    new ReportingObserver({ types: ['crash'] })
  ],
  beforeSend(event, hint) {
    if (
      hint?.originalException?.isAxiosError &&
      hint?.originalException?.response &&
      hint.originalException.response?.status < 500
    ) {
      return null;
    }
    return event;
  },
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_RATE
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <ThemeProviderMui theme={themeMui}>
          <BrowserRouter>
            <LastLocationProvider>
              <ToastsRemover>
                <Root />
              </ToastsRemover>
            </LastLocationProvider>
          </BrowserRouter>
        </ThemeProviderMui>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
