/* eslint-disable no-bitwise */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import { useEffect } from 'react';
import BluetoothWebController from 'bluetooth-handler/bluetoothWeb';
import { delay } from 'bluetooth/Bluetooth/Utilities';
import { useConfigStore } from 'reducers/configStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useMeetingStore } from 'reducers/meetingStore';
import { ViewModes } from 'utils/ViewModes/viewModes';

const bluetooth = new BluetoothWebController();

const useTelemetry = (delayInit: boolean = false) => {
  const { localConfigFetched } = useConfigStore((state) => ({
    localConfigFetched: state.localConfigFetched
  }));
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const viewMode = useMeetingStore((state) => state.viewMode);

  const telemetryOn = async () => {
    await delay(500);
    if (!bluetooth.telemetryEnabled) {
      bluetooth.telemetryOn();
    }
  };

  // @ts-ignore
  useEffect(() => {
    if (deviceConnected && bluetooth.connected && localConfigFetched) {
      telemetryOn();
    }

    const clean = async () => {
      if (
        deviceConnected &&
        bluetooth.connected &&
        localConfigFetched
      ) {
        await bluetooth.telemetryOff();
      }
    }

    return () => {
      clean()
    }
  }, [localConfigFetched, viewMode]);
};

export default useTelemetry;
