import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Divider from 'components/atoms/Divider/Divider';
import { HeaderM } from 'components/atoms/Typography/Typography';
import InputOptions from 'components/molecules/InputOptions/InputOptions';
import { DisableFunctionality } from 'utils/disableFunctionalityModifiier';
import { useConfigStore } from 'reducers/configStore';
import { useDeviceInfoStore } from 'reducers/deviceInfoStore';
import { useSettingsStore } from 'reducers/settingsStore';
import { useUiStore } from 'reducers/uiStore';
import { MODALS } from 'views/Modals';
import useUnsaved from 'hooks/useUnsaved';

const SettingsContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  max-width: 800px;

  > * {
    padding-top: 20px;
    border-top: 2px solid ${({ theme }) => theme.colorFill};
  }
`;

const ParagraphBold = styled.p`
  ${HeaderM}
`;

const DisableControl = styled.div`
  ${({ disable }) => disable && DisableFunctionality};
`;

const ApplicationSettings = () => {
  const { getInitialConfig } = useConfigStore((state) => ({
    setItemConfigStore: state.setItemConfigStore,
    getInitialConfig: state.getInitialConfig
  }));
  const deviceConnected = useDeviceInfoStore((state) => state.connected);
  const { demoMode, setItemSettingsStore } = useSettingsStore((state) => ({
    demoMode: state.demoMode,
    setItemSettingsStore: state.setItemSettingsStore
  }));
  const { isUnsaved } = useUnsaved();
  const { openModal, setItemUiStore } = useUiStore((state) => ({
    openModal: state.openModal,
    setItemUiStore: state.setItemUiStore
  }));

  const handleDemoModeChange = async (id, value) => {
    setItemSettingsStore('demoMode', value === 'On');
    if (value === 'On' && isUnsaved) openModal(MODALS.immediateSending);

    if (value === 'On' && !isUnsaved) {
      try {
        setItemUiStore('shownGlobalModal', 'prevent');
        setItemSettingsStore('demoMode', true);
        await getInitialConfig();
        setItemUiStore('shownGlobalModal', null);
      } catch (e) {
        console.log(e);
        setItemUiStore('shownGlobalModal', null);
      }
    }
  };

  return (
    <>
      <SettingsContainer>
        <DisableControl disable={!deviceConnected}>
          <InputOptions
            header='Send changes immediately'
            options={['On', 'Off']}
            id='demoMode'
            onChange={handleDemoModeChange}
            value={demoMode ? 'On' : 'Off'}
          />
        </DisableControl>
        <div>
          <div>
            <ParagraphBold>
              Zeus Configurator ADP v.{process.env.REACT_APP_SOFTWARE_VERSION}
            </ParagraphBold>
            <Divider margin='20px' />
            <p>Copyright (C) {dayjs().format('YYYY')} Aether Biomedical Sp. z o.o.</p>
            <Divider margin='20px' />
            <p>
              The program is provided AS IS with NO WARRANTY OF ANY KIND, INCLUDING THE WARRANTY OF
              DESIGN, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
            </p>
          </div>
        </div>
      </SettingsContainer>
    </>
  );
};

export default ApplicationSettings;
